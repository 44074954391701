import React, { useEffect } from 'react';
import loseWeight from '../../assets/img/updated/shake/losing-weight.png';
import healthy from '../../assets/img/updated/shake/healthy.png';
import improveHealth from '../../assets/img/updated/shake/improve-healthy.png';
import guteHealth from '../../assets/img/updated/shake/improve-gut-health.png';


export const Question1 = ({ history, setFieldValue, values, handleChange, setProgress, setShowProgress, setPage }) => {

    useEffect(() => {
        let percent;
        percent = (1 / 18) * 100;
        percent = percent.toFixed(2);
        setPage(1);
        setProgress(percent);
        setShowProgress(true);
        console.log('values', values);
    }, []);

    const handleChangeHandler = (value) => {
        setFieldValue('goal', value);
        setTimeout(() => {
            history(`/shake-new/q2`);
        }, 300);
    };
    return (
        <>
            <section className="text-white goal-content main">
                <div className="body-content">
                    <div className="heading mb-20 title-font h-wrap"><span className="h-before"> What is your goal? </span></div>
                    <div className='single-choose-question max-308 mx-auto question-icons'>
                        <button type='button' className={`button bg-theme text-white w-100 d-flex fw-bold align-center mb-16 ${Boolean(values.goal === 'lose weight') ? 'checked' : ''}`} onClick={() => handleChangeHandler('lose weight')}>
                            <img src={loseWeight} className='question-icon' width='24' height='20' />
                            <span>
                                Losing Weight
                            </span>
                        </button>
                        <button type='button' className={`button bg-theme text-white w-100 d-flex fw-bold align-center mb-16 ${Boolean(values.goal === 'keep healthy') ? 'checked' : ''}`} onClick={() => handleChangeHandler('keep healthy')}>
                            <img src={healthy} className='question-icon' width='24' height='20' />
                            <span>
                                Keeping body healthy
                            </span>
                        </button>
                        <button type='button' className={`button bg-theme text-white w-100 d-flex fw-bold align-center mb-16 ${Boolean(values.goal === 'improve overall health') ? 'checked' : ''}`} onClick={() => handleChangeHandler('improve overall health')}>
                            <img src={improveHealth} className='question-icon' width='24' height='20' />
                            <span>
                                Improve my overall health
                            </span>
                        </button>
                        <button type='button' className={`button bg-theme text-white w-100 d-flex fw-bold align-center mb-16 ${Boolean(values.goal === 'improve gut health') ? 'checked' : ''}`} onClick={() => handleChangeHandler('improve gut health')}>
                            <img src={guteHealth} className='question-icon' width='24' height='20' />
                            <span>
                                Improving my gut health
                            </span>
                        </button>
                    </div>
                </div>
            </section>
        </>
    )
}
