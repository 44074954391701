import React, { useEffect } from 'react';
import q6Bg from '../../assets/img/updated/bg/q6-bg.webp';

export const Question6 = ({ history, handleChange, values, setFieldValue, setProgress, setShowProgress, setPage }) => {

  useEffect(() => {
    let percent;
    percent = (6 / 18) * 100;
    percent = percent.toFixed(2);
    setPage(6);
    setProgress(percent);
    setShowProgress(true);
  }, []);

  const handleChangeHandler = (value) => {
    setFieldValue('dinnerTaken', value);
    setTimeout(() => {
      history('/app/q7');
    }, 300);
  };

  return (
    <>
      <img src={q6Bg} className='img-fluid bg-img' alt=''/>
      <section className="text-primary dinner-content main">
        <div className="body-content" >
          <div className="heading text-primary mb-20 title-font mx-auto"> When do you usually have dinner? </div>
          <div className='single-choose-question max-308 mx-auto'>
            <button type='button' className='button bg-theme text-white w-100 d-flex fw-bold align-center mb-16' onClick={() => handleChangeHandler('5-6')}>
              <span>
                5 PM - 6 PM
              </span>
            </button>
            <button type='button' className='button bg-theme text-white w-100 d-flex fw-bold align-center mb-16' onClick={() => handleChangeHandler('6-7')}>
              <span>
                6 PM - 7 PM
              </span>
            </button>
            <button type='button' className='button bg-theme text-white w-100 d-flex fw-bold align-center mb-16' onClick={() => handleChangeHandler(' 7-8')}>
              <span>
                7 PM -  8 PM
              </span>
            </button>
            <button type='button' className='button bg-theme text-white w-100 d-flex fw-bold align-center mb-16' onClick={() => handleChangeHandler('skip dinner')}>
              <span>
                I skip dinner
              </span>
            </button>
          </div>
        </div>
      </section>
    </>
  )
}
