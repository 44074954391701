import React, { useEffect } from 'react';
import summary2 from '../../assets/img/updated/shake-new/summary/Before After Picture.webp';
import stars from '../../assets/img/updated/shake-new/summary/Trustpilot Stars.webp';
import verified from '../../assets/img/updated/shake-new/summary/Verified User picture.webp';




export const Summary10 = ({ history, values, handleChange, setProgress, setShowProgress, setPage }) => {

    useEffect(() => {
        let percent;
        percent = (18 / 18) * 100;
        percent = percent.toFixed(2);
        setPage(18);
        setProgress(percent);
        setShowProgress(true);
    }, []);

    const clickHandler = () => {
        setTimeout(() => {
            history('/shake-new/summary11');
        }, 300);
    };

    return (
        <section className="text-primary main summary-page-content">
            <div className='shake-summary-page'>
                <div className='shake-summary-page-steps text-white'>
                    <span className='active'></span>
                    <span className='active'></span>
                    <span className='active'></span>
                    <span className='active'></span>
                    <span className='active'></span>
                    <span className='active'></span>
                    <span className='active'></span>
                    <span className='active'></span>
                    <span className='active'></span>
                    <span className='active'></span>
                    <span></span>
                </div>
                <div className='shake-summary-page-content'>
                    <div class="heading">
                        <div class="text-primary title-font mx-auto">
                        See what real users have to say about <span className='theme-color'>Biome Secret's</span> results.
                        </div>
                    </div>
                    <div className='weight-loss-journey-new mt-20'>
                        <img src={summary2} className='img-fluid' style={{maxHeight: '400px', 'border-radius': '4px'}} />
                        <img src={stars} className='text-left mt-20' style={{maxHeight: '25px', 'display': 'flex'}} />
                        <p className='text-left fs-16'>Biome Secret has been a game changer! In just a couple of months, I’ve lost significant weight and look better than ever. My digestion improved, bloating disappeared, and I feel energized throughout the day. Each serving packs more protein than a steak and more fiber than a bowl of salad, taking just minutes to prepare. Best of all, there’s no post-meal crash. Highly recommend it for those serious about transforming their health and metabolism!</p>
                        <p className='text-left fs-18 fw-bolder' style={{'display': 'inline-flex', 'align-items': 'center', 'justify-content': 'space-between', 'width': '100%'}}>Jennifer<img src={verified} className='text-right' style={{maxHeight: '25px', 'display': 'flex'}} /></p>
                        <div className="mt-30">
                            <button
                                type='button'
                                className="button bg-secondary text-white w-100 mx-auto text-center mb-16 w-100"
                                title="Continue"
                                // disabled={values.targetWeightInKg ? errors.targetWeightKg : errors.targetWeightLb}
                                onClick={clickHandler}
                            > Continue
                            </button>
                            <div className='f-italic fs-10 color2'>
                                * Results may vary due personal features
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )  
};