import React, { useEffect } from 'react';
import summary2 from '../../assets/img/updated/shake-new/summary/shake-powder.webp';
import nutrition1 from '../../assets/img/updated/shake-new/summary/Steak.png';
import nutrition2 from '../../assets/img/updated/shake-new/summary/Broccoli.png';
import nutrition3 from '../../assets/img/updated/shake-new/summary/Orange.png';
import nutrition4 from '../../assets/img/updated/shake-new/summary/Banana.png';
import nutrition5 from '../../assets/img/updated/shake-new/summary/27Vitamins&Minerals.png';
import nutrition6 from '../../assets/img/updated/shake-new/summary/Prebiotics&Probiotics.png';
import nutrition7 from '../../assets/img/updated/shake-new/summary/DigestiveEnzymes.png';
import check from '../../assets/img/updated/shake-new/summary/check-circle-broken.svg';
import summary6b from '../../assets/img/updated/shake-new/q6.webp';




export const Summary6 = ({ history, values, handleChange, setProgress, setShowProgress, setPage }) => {

    useEffect(() => {
        let percent;
        percent = (18 / 18) * 100;
        percent = percent.toFixed(2);
        setPage(18);
        setProgress(percent);
        setShowProgress(true);
    }, []);

    const clickHandler = () => {
        setTimeout(() => {
            history('/shake-new/summary7');
        }, 300);
    };

    return (
        <section className="text-primary main summary-page-content">
            <div className='shake-summary-page'>
                <div className='shake-summary-page-steps text-white'>
                    <span className='active'></span>
                    <span className='active'></span>
                    <span className='active'></span>
                    <span className='active'></span>
                    <span className='active'></span>
                    <span className='active'></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div className='shake-summary-page-content pt-0' style={{'background-color': '#F0F0F0'}}>
                    
                    
                    <div className='weight-loss-journey-new'>
                        <div className='summary-5bg'>
                            <img src={summary6b} className='img-fluid br-4t' />                            
                        </div>                        
                        <div className="mt-30">
                            <button
                                type='button'
                                className="button bg-secondary text-white w-100 mx-auto text-center mb-16 w-100"
                                title="Continue"
                                // disabled={values.targetWeightInKg ? errors.targetWeightKg : errors.targetWeightLb}
                                onClick={clickHandler}
                            > Continue
                            </button>
                            <div className='f-italic fs-10 color2'>
                                * Results may vary due personal features
                            </div>
                        </div>
                    </div>
                
                    {/*<div className='weight-loss-journey-new'>
                        <img src={summary2} className='img-fluid' />
                        <div className='biomesecret-nutrition'>
                                <div className='fs-24 text-left mb-10'>
                                    Complete Nutrition Made Simple
                                </div>
                                <div className='fs-18 mb-30 text-left'>
                                    Each Biome Secret Low Calorie Meal Contains...
                                </div>
                                <div className='d-flex biomesecret-nutrition-list align-center'>
                                    <div className='d-flex  align-center'>
                                        <img src={nutrition1} className='img-fluid' />
                                        <span>More Protein than a Steak</span>
                                    </div>
                                    <strong className='ms-auto my-auto d-inline-flex'>
                                        42g
                                    </strong>
                                </div>
                                <div className='d-flex biomesecret-nutrition-list align-center'>
                                    <div className='d-flex  align-center'>
                                        <img src={nutrition2} className='img-fluid' />
                                        <span>More Fiber than 3 Heads of Broccoli</span>
                                    </div>
                                    <strong className='ms-auto my-auto d-inline-flex'>
                                        9g
                                    </strong>
                                </div>
                                <div className='d-flex biomesecret-nutrition-list align-center'>
                                    <div className='d-flex  align-center'>
                                        <img src={nutrition3} className='img-fluid' />
                                        <span>More Vitamin C than an Orange</span>
                                    </div>
                                    <strong className='ms-auto my-auto d-inline-flex'>
                                        <img src={check} className='img-fluid w-16p' />
                                    </strong>
                                </div>
                                <div className='d-flex biomesecret-nutrition-list align-center'>
                                    <div className='d-flex  align-center'>
                                        <img src={nutrition4} className='img-fluid' />
                                        <span>More Pottassium than a Banana</span>
                                    </div>
                                    <strong className='ms-auto my-auto d-inline-flex'>
                                        <img src={check} className='img-fluid w-16p' />
                                    </strong>
                                </div>
                                <div className='d-flex biomesecret-nutrition-list align-center'>
                                    <div className='d-flex  align-center'>
                                        <img src={nutrition5} className='img-fluid' />
                                        <span>27 Vitamins & Minerals</span>
                                    </div>
                                    <strong className='ms-auto my-auto d-inline-flex'>
                                        <img src={check} className='img-fluid w-16p' />
                                    </strong>
                                </div>
                                <div className='d-flex biomesecret-nutrition-list align-center'>
                                    <div className='d-flex  align-center'>
                                        <img src={nutrition6} className='img-fluid' />
                                        <span>Prebiotics & Probiotics</span>
                                    </div>
                                    <strong className='ms-auto my-auto d-inline-flex'>
                                        <img src={check} className='img-fluid w-16p' />
                                    </strong>
                                </div>
                                <div className='d-flex biomesecret-nutrition-list align-center'>
                                    <div className='d-flex  align-center'>
                                        <img src={nutrition7} className='img-fluid' />
                                        <span>Digestive Enzymes</span>
                                    </div>
                                    <strong className='ms-auto my-auto d-inline-flex'>
                                        <img src={check} className='img-fluid w-16p' />
                                    </strong>
                                </div>
                                <div className='text-left'>
                                    <div className='text-uppercase d-flex bg-white fs-9 fw-500' style={{width:'auto', display:'inline-flex', padding:'4px 8px', borderRadius:'10px', marginInlineEnd:'auto'}}>
                                            <img src={check} className='img-fluid me-5' />
                                            <span>
                                                Whole-Food Sourced ingredients
                                            </span>
                                    </div>

                                </div>
                        </div>
                        <div>
                            <button
                                type='button'
                                className="button bg-secondary text-white w-100 mx-auto text-center mb-16 w-100"
                                title="Continue"
                                // disabled={values.targetWeightInKg ? errors.targetWeightKg : errors.targetWeightLb}
                                onClick={clickHandler}
                            > Continue
                            </button>
                            <div className='f-italic fs-10 color2'>
                                * Results may vary due personal features
                            </div>
                        </div>
                    </div>*/}

                </div>
            </div>
        </section>
    )  
};