import React, { useEffect } from 'react';

export const Question3 = ({ history, handleChange, values, setFieldValue, setProgress, setShowProgress, setPage }) => {

  useEffect(() => {
    let percent;
    percent = (3 / 18) * 100;
    percent = percent.toFixed(2);
    setPage(3);
    setProgress(percent);
    setShowProgress(true);
  }, []);

  const handleChangeHandler = (value) => {
    setFieldValue('feelAfterEat', value);
    setTimeout(() => {
      history('/shake/q4');
    }, 300);
  };
  return (
  <>
    <section className="text-primary bloated-content main">
      <div className="body-content" >
        <div className="heading text-primary mb-20 title-font mx-auto"> How often do you feel bloated or uncomfortable after eating? </div>
        <div className='single-choose-question max-308 mx-auto'>
          <button type='button' className='button bg-theme text-white w-100 d-flex fw-bold align-center mb-16' onClick={() => handleChangeHandler('every day')}>
            <span>
              Every day
            </span>
          </button>
          <button type='button' className='button bg-theme text-white w-100 d-flex fw-bold align-center mb-16' onClick={() => handleChangeHandler('few times a week')}>
            <span>
              A few times a week
            </span>
          </button>
          <button type='button' className='button bg-theme text-white w-100 d-flex fw-bold align-center mb-16' onClick={() => handleChangeHandler('sometimes')}>
            <span>
              Sometimes
            </span>
          </button>
          <button type='button' className='button bg-theme text-white w-100 d-flex fw-bold align-center mb-16' onClick={() => handleChangeHandler('rarely or never')}>
            <span>
              Rarely or never
            </span>
          </button>
        </div>
      </div>
    </section>

  </>
  )
}
