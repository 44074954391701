import React from 'react'
import { Accordion, AccordionItem } from '@szhsin/react-accordion';



export const FAQs = () => {
  return (
    <div class="bg1 pt-40 pb-30">
    <div class="faq-section">
    <Accordion transition transitionTimeout={250}>
    <div class="heading-2 text-left fw-bolder pb-10 text-center">Any Questions we Haven't Answered? </div>
      <AccordionItem header="What is Biome Secret powdered food?">
        Biome Secret is nutritionally complete, convenient, affordable food with minimal impact on animals and the environment. It provides you with all the carbohydrates, protein, fats, fibre and all 29 essential micronutrients your body needs to thrive.
      </AccordionItem>

      <AccordionItem header="How do I use Biome Secret powdered food?">
        Biome Secret is super easy to use. Simply add Biome Secret, along with water to your shaker, and shake!
      </AccordionItem>

      <AccordionItem header="Is Biome Secret a meal replacement?">
        While meal replacements may seem similar to Biome Secret Products, they are far from it. They are generally marketed and designed to support either weight loss or a fitness programme, and they're often low in calories. A two-scoop serving of Biome Secret is a nutritionally complete meal, containing 400 calories, a good amount of fibre and fats, and a low GI.
      </AccordionItem>

      <AccordionItem header="What does Biome Secret taste like?">
        It has a subtly sweet taste. Based on feedback from 1000's of Biome Secret users, we have just 1 popular flavor which reminds many people of a delicate vanilla smoothie. It's a crowd pleaser.
      </AccordionItem>

      <AccordionItem header="What are the benefits of the new ingredients?">
        Kombucha is a source of B-vitamins, while green tea extract brings catechins, which act as antioxidants. Probiotics may have many beneficial effects, particularly in relation to gut health.
      </AccordionItem>

      <AccordionItem header="Why have you removed sucralose?">
       There is nothing wrong with sucralose. However, we listen to requests from some of our Biome Secret users who wanted a flavoured Powder option without sucralose, so here you go! Biome Secret is naturally sweetened by stevia and a small amount of organic coconut sugar.
      </AccordionItem>
      <AccordionItem header="Can it help me lose weight fast?">
        Biome Secret is food that helps restrict your calorie intake by offering a meal replacement that is rich in essential vitamins and minerals. Biome Secret can help with making you feel full as it’s high in protein and fibre.
      </AccordionItem>
      <AccordionItem header="What is the shelf life of Biome Secret?">
        The shelf life of Biome Secret is 12 months from production date if your pouch is unopened and stored in a dry, cool environment, away from direct sunlight. Don’t worry about keeping track, there is a best before date printed on the pouch.
        <br/><br/>
        It’s 3 months if your pouch is open. Just reseal and store in dry, cool environment, away from direct sunlight.
        <br/><br/>
        Once you’ve prepared your Biome Secret, keep it refrigerated for 24 hours. You can save more time and make up your breakfast the night before, plus it tastes great cold.
        <br/><br/>
        If you’re on the go and don’t have a fridge handy, providing the temperature is cool, your prepared Biome Secret can stay fresh for a few hours.
        <br/><br/>
        Please note, we try to send out product with as long as possible before the best before date to give you enough time to consume your Biome Secret, however this does depend on levels of stock. There may be some cases where you receive something with 3 months until the date.
        <br/><br/>
        If you receive something with less than 3 months, please reach out to us so we can help.
      </AccordionItem>
    </Accordion>
    </div>
    </div>
  )
}
