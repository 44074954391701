import React from 'react';
import target from '../../assets/img/icons/target.svg';
import graph from '../../assets/img/icons/graph-down.svg';
import range from '../../assets/img/icons/range.svg';
import moreless from '../../assets/img/icons/more-less.svg';
import checkbadge from '../../assets/img/icons/check-badge.svg';
import speedo from '../../assets/img/icons/speedo.svg';
import goalGain from '../../assets/img/graph-down.svg';
import doctor from '../../assets/img/doctor2.jpg';
import signature from '../../assets/img/signature.png';

export const Goals = ({ name, additionalGoals, targetBodyPercent, targetWeightInPounds, r1Calories, r2Calories, caloriesMaintainer, calculateXAxis }) => {
    const goals = additionalGoals && additionalGoals.length && additionalGoals.map(goal => {
        let icon;
        if (goal === "Improve sleep") {
            icon = <div class="circle-check green me-10"></div> //you can add image here.;
        } else if (goal === "Grow muscles") {
            icon = <div class="circle-check green me-10"></div> //you can add image here.;
        } else if (goal === "Feel healthier") {
            icon = <div class="circle-check green me-10"></div> //you can add image here.;
        }
        return (
            <div class="d-inline-flex align-center mt-20 pr-15 fs-16">
                {icon}
                {goal}
            </div>
        );
    });

    return ( <
        >
        <div class="pt-30 ptm-0 pb-30">
            <div class="checkout-container">
            <div class="fw-bolder mxm-24"><div class="align-center my-25 fs-34 mfs-24 text-center"><span>Weight Loss Plan for&nbsp;<span className="theme-color name">{name}</span></span></div></div>                
                <div class="plan-list mxm-24">
                    <ul>
                        {/*<li class="d-flex align-center pxm-0"> <i class="checkout-icon fw-bold theme-color">1</i>
                            <div class="mw-70p w-100">
                                <div class="mb-5">Your Goal</div>
                                <div class="fs-20 fw-bold"> NaN <span class="fs-16">lbs</span></div>
                                <div class="fs-20 fw-bold"> NaN% <span class="fs-16">body fat</span></div>
                            </div>
                        </li>*/}
                        <li class="d-flex align-center border3 br-10 bg1">
                            <i class="checkout-icon fw-bold theme-color"><img class="p-10" src={target} /></i>
                            <div class="w-70">                                
                                <div class="fs-20 fw-bolder mb-5"> {targetWeightInPounds} lbs</div>
                                <div class="fs-20 fw-bolder d-none"> {targetBodyPercent} body fat</div>
                                <div class="">Your Goal</div>
                            </div>
                        </li>
                        <li class="d-flex align-center border3 br-10 bg1"><i class="checkout-icon fw-bold theme-color"><img class="p-10" src={graph} /></i>
                            <div class="w-70">
                                <div class="fs-22 fw-bolder mb-5">1-2 lbs per week</div>
                                <div class="">Sustainable weight change</div>                                
                            </div>
                        </li>
                        <li class="d-flex align-center border3 br-10 bg1"><i class="checkout-icon fw-bold theme-color"><img class="p-10" src={range} /></i>
                            <div class="w-70">
                                <div class="fs-22 fw-bolder mb-5">{r2Calories} calories</div>
                                <div class="">Daily caloric recommendation</div>
                            </div>
                        </li>
                        <li class="d-flex align-center border3 br-10 bg1"><i class="checkout-icon fw-bold theme-color"><img class="p-10" src={moreless} /></i>
                            <div class="w-70">
                                <div class="fs-22 fw-bolder mb-5"> {caloriesMaintainer}</div>
                                <div class="">Per day, to lose weight</div>                                
                            </div>
                        </li>                        
                        {/*<li class="d-flex align-center border5 br-10 bg1"><i class="checkout-icon fw-bold theme-color"><img class="p-10" src={checkbadge} /></i>
                            <div class="w-70">
                                <div class="mb-5">Meal Replacement Frequency </div>
                                <div class="fs-20 fw-bold theme-color"> 1 time per day </div>
                            </div>
                        </li>
                        <li class="align-center text-center my-a">
                            <div class="w-70">
                                <div class="fs-24 fw-bold color3">EASY</div>
                                <img class="pt-10 mw-30p" src={speedo} />
                            </div>
                        </li>*/}
                        
                    </ul>
                    <div class="p-20 bg1 border3 br-10">
                    <div class="text-left fs-34 w-100 fw-bolder">Our Promise</div>
                    <div class="text-primary d-flex">                                                                        
                        <div class="fs-12 ms-auto my-a pr-20 testimonial-l">
                        <p class="text-justify">By reducing your daily calorie intake by just 500 calories, you can achieve sustainable, long-term weight loss, aligning with the CDC's guidelines of 1-2 pounds of weekly weight loss for healthy individuals. Biome Secret unlocks a realistic approach to weight management, making it achievable and effective to restrict caloric intake. It simplifies the journey towards your target weight and general wellbeing.</p>
                        <p class="text-justify">Weight loss is a journey, but it doesn't have to be hard. One day at a time, Biome Secret is there to help.</p>    
                        <img class="mw-50p mob-d-none" src={signature} />


                        <div class="mw-100p desk-d-none">
                            <div class="d-inline-flex">
                                <div class="my-a mw-40p w-100">                                                        
                                    <div class="pr-10"><img class="mw-100p br-10 b2" src={doctor} /></div>                                                            
                                </div>
                                <div class="d-flex flex-col my-a pl-20 mw-60p">
                                    <img class="mw-50p" src={signature} />
                                    <div class="fw-bolder fs-12"><i>- Head of Nutrition, <span class="non-break">Biome Secret</span></i></div>
                                </div>
                            </div>                        
                        </div>  
                        </div>                        

                        <div class="d-flex my-a mw-40p mob-d-none">                            
                            <div class="">
                            <div class="p-10"><img class="mw-100p br-10 b2" src={doctor} /></div>
                                <div class="fw-bolder fs-12"><i>- Head of Nutrition, <span class="non-break">Biome Secret</span></i></div>
                            </div>
                        </div>
                    </div>  
                    <hr class="mt-20"></hr>
                    <div class="py-10">                        
                    <div class="fw-bolder fs-20">Your plan's goals include helping to: </div>     
                        {goals}
                        <div class="d-inline-flex align-center mt-20 pr-15 fs-16">
                            <div class="circle-check green me-10"></div>Increase Nutrition
                        </div>
                        <div class="d-inline-flex align-center mt-20 pr-15 fs-16">
                            <div class="circle-check green me-10"></div>Balance Vitamins
                        </div>
                    </div>    
                    <hr class="mt-10"></hr>
                    
                    <div class="d-flex flex-col">                              
                            <div class="d-flex pt-10">                                            
                                <i class="checkout-icon fw-bold theme-color me-15 my-a mob-d-none"><img class="p-10" src={checkbadge} /></i>
                                <div class="valign-m my-a text-center me-15 desk-d-none">
                                <div class="change-text fw-bold color3">EASY</div>
                                <i class="checkout-icon grey my-a"><img class="p-10" src={speedo} /></i>
                                </div>
                                <div class="valign-m my-a me-10">
                                    <div class="mb-5 fw-bold">Meal Replacement Frequency </div>
                                    <div class="fs-18 fw-bold theme-color"> 1 time per day </div>
                                </div>  
                            <div class="valign-m my-a px-20 a-self-end ms-auto text-center me-20 mob-d-none">
                                <div class="change-text fw-bold color3">EASY</div>
                                <img class="pt-5" src={speedo} />
                            </div>                
                            </div>

                            
                        </div>



                    </div>                                                        
                    <p class="fs-12 color2 text-center">*Based on CDC recommendations for healthy weight change. The CDC recommends a maximum change no greater than 1-2 pounds per week.</p>
                </div>
            </div>
        </div> <
        />
    )
}