import React, { useState } from "react";
import CreditCard from "./CreditCard";
import PayPalOption from "./PayPalOption";
import { dateTimeFormate } from '../../common/utils/utils';

const PaymentOptions = ({paymentOptionsRender, plan, data, age, mAge, history}) => {
    const [activePayment, setActvePayment] = useState('paypal');
    const formattedDateTime = dateTimeFormate();

    const submitData = async () => {
        try {
            const payload = {
                email: data.email,
                paymentOption : activePayment,
                selectedProductOption: plan.months,
                selectedPrice: plan.payment,
                biologicalAge: age,
                metabolicAge: mAge,
                timeStamp: formattedDateTime
            }
            // sheet url /tabs/sheetname
          const res = await fetch('https://sheet.best/api/sheets/308fa9bf-ee08-43c8-9de8-08fa9f6a2a5c/tabs/Checkout', {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload)
          });
        } catch (error) {
          console.log(error)
        }
    };

    return(
        <div id="paypal-content">
                <div class="paypal-wrapper">
                    <button class="paypal-close-icon" onClick={paymentOptionsRender}>
                        <div class="close-icon"></div>
                    </button>
                    <div class="tab">
                        <button class={activePayment == 'paypal' ? 'tablinks active' : 'tablinks'} onClick={() => setActvePayment('paypal')}>
                            <div class="paypal-icon"></div>
                        </button>
                        <button class={activePayment == 'card' ? 'tablinks active' : 'tablinks'}  onClick={() => setActvePayment('card')}> <span class="text-primary fs-18 fw-bold me-5">Card</span>
                            <div class="apple-icon me-5"></div>
                            <div class="google-icon"></div>
                        </button>
                    </div>
                    {/* <!-- Tab content --> */}
                    <div id="paypal" class="tabcontent" style={{display: 'block'}}>
                        <div class="text-center">
                            <h3 id="chosen-plan" class="">{plan.months}</h3>
                            <p id="chosen-price" class="color2">{plan.payment} USD</p>
                        </div>
                        {activePayment === 'paypal' ?
                            <PayPalOption {...{submitData, history}} /> :
                            <CreditCard {...{submitData, history}} />
                        }
                    </div>
                </div>
        </div>
    );
}
export default React.memo(PaymentOptions);