import React, { useEffect } from 'react';
import WeightInPoundField from '../../common/components/WeightInPoundField';
import { poundsToKilograms, kgToLb } from '../../common/utils/formulas';
import q15Bg from '../../assets/img/updated/bg/q14-bg.webp';

export const Question15 = ({ history, values, handleChange, setFieldValue, errors, handleBlur, touched, setProgress, setShowProgress, setPage, weightInKg, setWeightInKg }) => {

    useEffect(() => {
        let percent;
        percent = (15 / 18) * 100;
        percent = percent.toFixed(2);
        setPage(15);
        setProgress(percent);
        setShowProgress(true);
    }, []);

    const poundToKg = () => {
        let currentKg
        currentKg = poundsToKilograms(parseInt(values.currentWeightLb));
        currentKg = Math.round(currentKg);
        // setFieldValue('currentWeight', currentKg);
        setFieldValue('weightInKg', true);
        setFieldValue('currentWeightKg', String(currentKg));
    };

    const kilogramToPound = () => {
        let currentLb;
        currentLb = kgToLb(parseInt(values?.currentWeightKg));
        currentLb = Math.round(currentLb);
        // setFieldValue('currentWeight', currentLb);
        setFieldValue('weightInKg', false);
        setFieldValue('currentWeightLb', String(currentLb));
    };

    const clickHandler = () => {
        const weight = values.weightInKg ? values.currentWeightKg : values.currentWeightLb;
        setFieldValue('currentWeight', weight);
        setTimeout(() => {
            history('/app/q16');
        }, 300);
    };

    const handleFieldChange = (e, length) => {
        if(e.target.value.length > length) {
            return;
        }
        handleChange(e);
    };

    return (
        <>
            <img src={q15Bg} className='img-fluid bg-img' alt='' />
            <section className="text-primary form-main-content main">
                <div className="body-content">
                    <div className="heading text-primary mb-20 title-font mx-auto"> What’s your weight?</div>
                    <div className='form-content mx-auto'>
                        <div className="switch-unit-btn mb-30">
                            <button
                                type='button'
                                className={!values.weightInKg && "active"}
                                disabled={!values.weightInKg && true}
                                onClick={kilogramToPound}
                            > Imperial </button>
                            <button
                                type='button'
                                className={values.weightInKg && "active"}
                                disabled={values.weightInKg && true}
                                onClick={poundToKg}
                            > Metric </button>
                        </div>
                        {values.weightInKg ?
                            <WeightInPoundField
                                {...{
                                    field: 'currentWeightKg',
                                    handleFieldChange,
                                    handleBlur,
                                    values,
                                    errors,
                                    touched,
                                    placeholder: 'Kilograms'
                                }}
                            /> :
                            <WeightInPoundField
                                {...{
                                    field: 'currentWeightLb',
                                    handleFieldChange,
                                    handleBlur,
                                    values,
                                    errors,
                                    touched,
                                    placeholder: 'Pounds'
                                }}
                            />
                        }
                        <div className="mt-30">
                            <button
                                type='button'
                                className="button bg-theme text-white w-100 mx-auto  fw-bold text-center mb-16 w-100"
                                title="Continue"
                                disabled={values.weightInKg ? errors.currentWeightKg : errors.currentWeightLb}
                                onClick={clickHandler}
                            > Continue
                            </button>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}
