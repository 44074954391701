import React, { useEffect, useState } from 'react';
import { ReactComponent as CheckIcon } from '../../assets/img/updated/shake-new/check-icon.svg';

export const Transition = ({ history, values, setProgress, setShowProgress, setPage }) => {
    const [spinner, setSpinner] = useState(0); // Spinner for your existing useEffect
    const [progress, setProgress1] = useState(0); // Progress percentage
    const [step, setStep] = useState(0); // To track which step is active
  
    const steps = [
        "Analysing your quiz answers..",
        "Calculating your quiz results..",
        "Creating your summary.."
    ];

    // Existing useEffect for initial setup
    useEffect(() => {
        let percent;
        percent = (17 / 18) * 100;
        percent = percent.toFixed(2);
        setPage(16);
        setProgress(percent);
        setShowProgress(true);
    }, [setPage, setProgress, setShowProgress]);

    // Existing spinner logic (unchanged)
    useEffect(() => {
        let myInterval = setInterval(() => {
            setSpinner((prevSpinner) => prevSpinner + 2);
        }, 150);

        if (spinner === 100) {
            setTimeout(() => {
                history('/shake-new/email');
            }, 200);
            clearInterval(myInterval);
        }

        return () => clearInterval(myInterval);
    }, [spinner, history]);

    // Smooth progress and step transitions
    useEffect(() => {
        const stepTargets = [33, 66, 100]; // Progress targets for each step
        let currentStep = 0; // Track current step

        const incrementProgress = (target) => {
            const interval = setInterval(() => {
                setProgress1((prevProgress) => {
                    if (prevProgress >= target) {
                        clearInterval(interval);
                        setStep(currentStep); // Update step only when target is reached
                        return target;
                    }
                    return prevProgress + 1; // Smoothly increment progress
                });
            }, 50); // Increment every 50ms for smoothness
        };

        const stepInterval = setInterval(() => {
            if (currentStep < steps.length) {
                incrementProgress(stepTargets[currentStep]); // Increment to target progress
                currentStep++; // Move to the next step when progress target is reached
            } else {
                clearInterval(stepInterval);
            }
        }, 2000); // Change step every 2 seconds

        return () => clearInterval(stepInterval);
    }, []);

    // Animated dots for current step
    const AnimatedDots = () => (
        <span className="dots">
            <span className="dot dot-1">•</span>
            <span className="dot dot-2">•</span>
            <span className="dot dot-3">•</span>
        </span>
    );

    return (
        <section className="choose-body-fat-section text-secondary custom-spacing h-100 d-flex">
            <div className="body-content loading-transition">
                <div className="loader-container">
                    {/* Circular Progress */}
                    <div className="circular-progress" style={{ background: `conic-gradient(#059c6d ${progress * 3.6}deg, #ffffff25 ${progress * 3.6}deg)` }}>
                        <div className="circle">
                            <span>{progress}%</span>
                        </div>
                    </div>
                    <p className='fw-bold text-white'>Analyzing your gut health...</p>

                    {/* Step Progress */}
                    <div className="steps">
                        {steps.map((stepText, index) => (
                            <div key={index} className={`step ${index < step ? "completed" : ""}`}>
                                {index < step ? <CheckIcon /> : index === step ? <AnimatedDots /> : "•••"} {stepText}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};
