import React, { useEffect } from 'react';
import { BodyFat } from '../../components/checkout/BodyFat';
import { FAQs } from '../../components/checkout/FAQs';
import { Goals } from '../../components/checkout/Goals';
import { ServingComparison } from '../../components/checkout/ServingComparison';
import { QuestionsSummary } from '../../components/checkout/QuestionsSummary';
import { FullWorkout } from '../../components/checkout/FullWorkoutSupplement';
import { VideoSection } from '../../components/checkout/VideoSection';
import { PlanSection } from '../../components/checkout/PlanSection';
import { GetPlan } from '../../components/checkout/GetPlan';
import { Guarantee } from '../../components/checkout/Guarantee';
import { Footer } from '../../components/checkout/Footer';
import { Reviews } from '../../components/checkout/Reviews';
import PersonalPlan from '../../components/checkout/PersonalPlan';
import { bodyFatPercent, weightConvert, convertHeightToCm, calculateAge, calculateBMRWithLBM, estimateMetabolicAge, walkLabel, calculateActiveBmr, caloriesRestriction, weightconvertToPounds, caloriesMaintain, calculateXOrder } from '../../common/utils/formulas';
import { renderImage, percentRange } from '../../common/utils/utils';


export const Quiz0Checkout = ({ history, setShowProgress }) => {
  const data = JSON.parse(window.localStorage.getItem('magicPills') || JSON.stringify({}));
  const currentBodyFatPercent = bodyFatPercent(data.currentBodyFat);
  const currentWeightInKg = weightConvert(data.currentWeight, data.weightInKg);
  const heightInCm = convertHeightToCm(data.height);
  const currentFatImage = renderImage(data.currentBodyFat, data.gender);
  const dreamFatImage = renderImage(data.dreamBodyFat, data.gender);
  const age = calculateAge(data.dob);
  const bmr = calculateBMRWithLBM(currentWeightInKg, currentBodyFatPercent);
  const mAge = estimateMetabolicAge(data.gender, heightInCm, currentWeightInKg, bmr);
  const walkInActiveLabel = walkLabel(data?.walk);
  const activeBmr = calculateActiveBmr(data?.walk, bmr);
  const caloriesDetails = caloriesRestriction(Number(data?.currentWeight), Number(data?.targetWeight));
  const targetBodyPercent = percentRange(data?.dreamBodyFat, data?.gender);
  const targetWeightInPounds = weightconvertToPounds(data?.targetWeight, data?.weightInKg);
  const r1Calories = activeBmr - 1000;
  const r2Calories = activeBmr - 500;
  const caloriesMaintainer = caloriesMaintain(Number(data?.currentWeight), Number(data?.targetWeight));
  const calculateXAxis = calculateXOrder(Number(data?.currentWeight), Number(data?.targetWeight), data?.weightInKg);
  console.log('active bmr', calculateXAxis);

  useEffect(() => {
    setShowProgress(false);
  }, []);

  console.log('m-age', typeof mAge)

  return (
    <>
      <div class="checkout-page text-white page-gradient">

        <BodyFat {...{ currentBody: data.currentBodyFat, dreamBody: data.dreamBodyFat, currentFatImage, dreamFatImage }} />
        <QuestionsSummary {...{ age, bmr, mAge, currentWeightInKg, height: data.height, walkInActiveLabel, activeBmr }} />
        <PlanSection {...{ currentFatImage, dreamFatImage, caloriesDetails }} />
        <Goals {...{ name: data.name, additionalGoals: data.additionalGoals, targetBodyPercent, targetWeightInPounds, r1Calories, r2Calories, caloriesMaintainer }} />
        <ServingComparison />
        <FullWorkout />
        <VideoSection {...{ calculateXAxis, current: data?.currentWeight, target: data?.targetWeight, weightInKg: data?.weightInKg, additionalGoals: data?.additionalGoals }} />
        <GetPlan {...{ dreamFatImage, data, age, mAge, history }} />
        {/* <PersonalPlan {...{ dreamFatImage, email: data?.email }} /> */}
      </div>
      <div class="page-gradient">
        <Guarantee />
        <FAQs />
        <Reviews />
        <Footer />
      </div>
    </>
  )
};
export default Quiz0Checkout;
