import React, { useEffect } from 'react';
import q5Bg from '../../assets/img/updated/bg/q5-bg.webp';

export const Question5 = ({ history, handleChange, values, setFieldValue, setProgress, setShowProgress, setPage }) => {

  useEffect(() => {
    let percent;
    percent = (5 / 18) * 100;
    percent = percent.toFixed(2);
    setPage(5);
    setProgress(percent);
    setShowProgress(true);
  }, []);

  const handleChangeHandler = (value) => {
    setFieldValue('lunchTaken', value);
    setTimeout(() => {
      history('/app/q6');
    }, 300);
  };

  return (
    <>
      <img src={q5Bg} className='img-fluid bg-img' alt=''/>
      <section className="text-primary lunch-content main">
        <div className="body-content" >
          <div className="heading text-primary mb-20 title-font mx-auto"> When do you usually have lunch?</div>
          <div className='single-choose-question max-308 mx-auto'>
            <button type='button' className='button bg-theme text-white w-100 d-flex fw-bold align-center mb-16' onClick={() => handleChangeHandler('11-12')}>
              <span>
                11 AM - 12 PM
              </span>
            </button>
            <button type='button' className='button bg-theme text-white w-100 d-flex fw-bold align-center mb-16' onClick={() => handleChangeHandler('12-1')}>
              <span>
                12 PM -  1 PM
              </span>
            </button>
            <button type='button' className='button bg-theme text-white w-100 d-flex fw-bold align-center mb-16' onClick={() => handleChangeHandler('1-2')}>
              <span>
                1 PM - 2 PM
              </span>
            </button>
            <button type='button' className='button bg-theme text-white w-100 d-flex fw-bold align-center mb-16' onClick={() => handleChangeHandler('skip lunch')}>
              <span>
                I skip lunch
              </span>
            </button>
          </div>
        </div>
      </section>
    </>
  )
}