import React from 'react';
import img3 from '../../assets/img/triangle.svg';
import badge from '../../assets/img/purchase-badge.svg';


export const Guarantee = ({Name}) => {
  return (
    <>
      <section class="money-back-guarantee mt-50 page-gradient2">
            <div class="money-back-guarantee-container bg1">
                <div class="d-flex">
                    <div>
                        <div class="fs-28 fw-bold"> Money-Back Guarantee </div>
                        <p class="change-text"> We believe that our plan may work for you and you’ll get visible results in 4 weeks! We are even ready
                            to completely refund you within 30 days after purchase if you don’t get visible results and can demonstrate that you have followed our plan. </p>
                        <p class="change-text"> Find out more about the applicable limitations in our <u><a target="_blank" href="../refund-policy" class="color5 text-decoration-none">money-back policy</a></u>.</p>
                    </div>
                    <img class="my-a" src={badge} />
                </div>
            </div>
        </section>
    </>
  )
}
