import React, { useEffect } from 'react';
import everyDay from '../../assets/img/updated/app-quiz/exercise/every-day.webp';
import inWeek from '../../assets/img/updated/app-quiz/exercise/in-week.webp';
import onceWhile from '../../assets/img/updated/app-quiz/exercise/once-in-while.webp';
import never from '../../assets/img/updated/app-quiz/exercise/never.webp';
import q8Bg from '../../assets/img/updated/bg/q8-bg.webp';


export const Question8 = ({ history, handleChange, setFieldValue, values, weightInKg, setWeightInKg, setProgress, setShowProgress, setPage }) => {

  useEffect(() => {
    let percent;
    percent = (8 / 18) * 100;
    percent = percent.toFixed(2);
    setPage(8);
    setProgress(percent);
    setShowProgress(true);
  }, []);

  const handleChangeHandler = (value) => {
    setFieldValue('exercise', value);
    setTimeout(() => {
      history('/app/q9');
    }, 300);
  };

  return (
    <>
      <img src={q8Bg} className='img-fluid bg-img' alt=''/>
      <section className="text-primary exercise-content main">
        <div className="body-content" >
          <div className="heading text-primary mb-20 title-font mx-auto"> How often do you exercise?</div>
          <div className='single-choose-question max-308 mx-auto'>
            <button type='button' className='button bg-theme text-white w-100 d-flex fw-bold align-center mb-16' onClick={() => handleChangeHandler('everyday')}>
              <img src={everyDay} className='question-icon' width='24' height='20' />
              <span>
                Every Day
              </span>
            </button>
            <button type='button' className='button bg-theme text-white w-100 d-flex fw-bold align-center mb-16' onClick={() => handleChangeHandler('few times a week')}>
              <img src={inWeek} className='question-icon' width='24' height='20' />
              <span>
                A Few Times A Week
              </span>
            </button>
            <button type='button' className='button bg-theme text-white w-100 d-flex fw-bold align-center mb-16' onClick={() => handleChangeHandler('once a while')}>
              <img src={onceWhile} className='question-icon' width='24' height='20' />
              <span>
                Once In A While
              </span>
            </button>
            <button type='button' className='button bg-theme text-white w-100 d-flex fw-bold align-center mb-16' onClick={() => handleChangeHandler('never')}>
              <img src={never} className='question-icon' width='24' height='20' />
              <span>
                Never
              </span>
            </button>
          </div>
        </div>
      </section>
    </>
  )
};
