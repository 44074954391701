import React from "react";

const IngredientsPopup = ({handleModal}) => {
    return (
        <div id="myModal" class="modal">
            <div class="modal-content text-primary"> <span class="close" onClick={handleModal}><span class="close-icon"></span></span>
                <h1 class="fs-20 fw-bold m-0 ">Only 100% Natural Ingredients</h1>
                <p class="mb-10"> Biome Secret is a nutritionally complete powdered food that is high in protein and fiber, reduced carbohydrate, low in sugar and salt, rich in phytonutrients, with an alternative macro split of 17:40:40:3 (net carbohydrate, protein, fat, fiber) and all 29 essential vitamins and minerals. </p>
                <ul class="centered-list pl-0">
                <li class="d-flex align-start mb-10"> <span class="me-5">✔️</span> Made from plants: peas, avocados, coconut, flaxseed, seaweed, essential oils. 100% plant based and suitable for vegans. </li>
                <li class="d-flex align-start mb-10"> <span class="me-5">✔️</span> 100% natural sweetness. Delicious chocolate made from raw cacao, and vanilla-caramel made with natural flavors. </li>
                <li class="d-flex align-start mb-10"> <span class="me-5">✔️</span> 173 health benefits: brain function, muscles, energy, immunity, hair, skin, nails. </li>
                <li class="d-flex align-start mb-10"> <span class="me-5">✔️</span> No nonsense ingredients: no gluten, no palm oil, no sugar, no artificial sweeteners, no lactose, no GMO, no animal products. </li>
                </ul>
                <p class="fw-bold">Complete List Of Ingredients</p>
                <p>Pea Protein Isolate, Potato Starch, Oat Flour, Cocoa Powder, Golden Flaxseed Powder, Avocado Oil Powder, MCT Oil Powder, Sunflower Lecithin, Vitamin A Acetate, Thiamine Mononitrate, Riboflavin, Nicotinamide, Calcium Pantothenate, Pyridoxine Hydrochloride, Vitamin B12, Choline Bitartrate, Inositol, Ascorbic Acid, Cholecalciferol, Ergocalciferol, Dl-Α-Tocopherol Acetate, Phylloquinone, Menoquinone-7, D-Biotin, Folic Acid, Zinc Oxide, Ferrous Fumarate, Magnesium Glycinate, Magnesium Oxide, Calcium Carbonate, Potassium Chloride, Potassium Gluconate, Potassium Iodide, Sodium Selenite, Copper Sulfate, Manganese Sulfate, Chromium Chloride, Sodium Molybdate, Calcium Phosphate Dibasic, Alpha-Amylase, Protease, Lactase, Lipase, Cellulase, Steviol Gylycosides, Xanthan Gum, Bifidobacterium Longum Cultures, Lactobacillus Acidophilus, Red Algae Oleoresin, Spirulina Powder, Kelp, Bladderwrack, Channelled Wrack</p>                                
            </div>
        </div>
    );
}
export default IngredientsPopup;