import React, { useEffect } from 'react';
import landingImage from '../../assets/img/updated/shake/q0-main-image1.webp';

export const Question12 = ({ history, values, setProgress, setShowProgress, setPage }) => {

  useEffect(() => {
    let percent;
    percent = (12 / 18) * 100;
    percent = percent.toFixed(2);
    setPage(12);
    setProgress(percent);
    setShowProgress(true);
  }, []);

  const clickHandler = () => {
    history('/shake/q13');
  };

  return (
    <>
      <section className="text-primary summary-content main">
        <div className="body-content" >
          <div className='two-column'>
            <div className='d-flex align-center'>
              <div className='title-font fs-16'>
                <div className="heading mb-20">
                We’ve Got Just What You Need!
                </div>
                <p className=' mb-30'>No matter where you are on your health journey, we’re here to help you reach your goals.</p>
                <p className='fs-16 mb-30'>With Biome Secret, You Can Achieve So Much:</p>
                <div className='tagline'>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" fill="#5F815C" />
                    </svg>
                    Support Weight Loss
                  </div>
                  <div >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" fill="#5F815C" />
                    </svg>
                    Enhance Gut Health
                  </div>
                  <div >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" fill="#5F815C" />
                    </svg>
                    Boost Digestion
                  </div>
                  <div >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" fill="#5F815C" />
                    </svg>
                    Increase Energy
                  </div>
                </div>
                <p>
                Let’s finish the quiz and discover how Biome Secret can help you on your path to better health.
                </p>
              </div>
              <img src={landingImage} className='img-fluid' />
            </div>
            <button type='button' className='button bg-theme text-white w-30 mx-auto d-flex fw-bold align-center mt-40' onClick={clickHandler}>
              <span>
                Continue
              </span>
            </button>
          </div>
        </div>
      </section>
    </>
  )
}
