let kilogram, meter;
export const bmiConvertor = (weight, height) => {
  kilogram = parseInt(weight);
  if (height.includes('ft')) {
    const heightSplitted = height.split(' ');
    const feet = parseInt(heightSplitted[0]);
    const inches = parseInt(heightSplitted[2]);
    meter = feetAndInchesToMeter(feet, inches);
  } else {
    const heightSplitted = height.split(' ');
    meter = convertCmToMeters(parseInt(heightSplitted[0]));
  }
  let bmi = kilogram / (meter * meter);
  return bmi.toFixed(1);
};

export function heightToInches(height, isCm) {
  if(isCm) {
    const heightSplitted = height.split(' ');
    const inches = parseInt(heightSplitted[0]) / 2.54;
    return inches;
  } else {
    const heightSplitted = height.split(' ');
    const feetInInches = feetToInches(parseInt(heightSplitted[0]));
    const inches = parseInt(heightSplitted[2]);
    return feetInInches + inches;
  }
};

function feetToInches(feet) {
  const inches = feet * 12;
  return inches;
}

export const calculateBmi = (weight, height) => {
  const bmi = (weight / (height * height)) * 703;
  return bmi.toFixed(1);
}

export const bmiPercentCalculate = (bmi) => {
  if(bmi < 18.5) {
    return '15%';
  } else if(bmi >=18.5 && bmi <= 24.9) {
    return '26%';
  } else if(bmi >=25.0 && bmi <= 29.9) {
    return '50%';
  } else {
    return '78%';
  }
};

export const bmiLabelGenerator = (bmi) => {
  let label;
  if (bmi < 18.5) {
    label = 'Underweight';
  } else if (bmi >= 18.5 && bmi <= 24.9) {
    label = 'Normal';
  } else if (bmi >= 25 && bmi <= 29.9) {
    label = 'Overweight';
  } else if (bmi >= 30 && bmi <= 34.9) {
    label = 'Significantly Overweight';
  }
  else if (bmi > 35) {
    label = 'Obese';
  }
  return label
};

export const calculateBMRWithLBM = (weight, bodyFatPercentage) => {
  const leanBodyMass = weight * (1 - (bodyFatPercentage / 100));
  const bmr = 370 + (21.6 * leanBodyMass);
  return bmr.toFixed(0);
};

export function estimateMetabolicAge(gender, height, weight, actualBmr) {
  // Constants for Mifflin-St Jeor equation
  const MALE_CONST = 88.362;
  const FEMALE_CONST = 447.593;
  const WEIGHT_MULTIPLIER_MALE = 13.397;
  const WEIGHT_MULTIPLIER_FEMALE = 9.247;
  const HEIGHT_MULTIPLIER_MALE = 4.799;
  const HEIGHT_MULTIPLIER_FEMALE = 3.098;

  // Calculate metabolic age based on gender and estimated age range
  let metabolicAge;
  if (gender === "male") {
    metabolicAge = (MALE_CONST + (WEIGHT_MULTIPLIER_MALE * weight) + (HEIGHT_MULTIPLIER_MALE * height) - actualBmr) / 5.677;
  } else if (gender === "female") {
    metabolicAge = (FEMALE_CONST + (WEIGHT_MULTIPLIER_FEMALE * weight) + (HEIGHT_MULTIPLIER_FEMALE * height) - actualBmr) / 4.33;
  } else {
    console.log("Invalid gender input. Please enter M or F.");
    return null;
  }

  // Round metabolic age to the nearest integer
  metabolicAge = Math.round(metabolicAge);

  return metabolicAge;
};

export const bodyFatPercent = (bodyFat) => {
  let percent;
  switch (bodyFat) {
    case 0:
      percent = 9;
      break;
    case 1:
      percent = 14;
      break;
    case 2:
      percent = 19;
      break;
    case 3:
      percent = 24;
      break;
    case 4:
      percent = 29;
      break;
    case 5:
      percent = 34;
      break;
    case 6:
      percent = 39;
      break;
    case 7:
      percent = 45;
      break;
    default:
      percent = 9;
  }
  return percent;
};

export function convertHeightToCm(height) {
  // 1 foot is equal to 30.48 centimeters
  // 1 inch is equal to 2.54 centimeters
  let totalCm;
  if (height.includes('ft')) {
    const featSplitted = height.split(' ');
    const feetToCm = parseInt(featSplitted[0]) * 30.48;
    const inchesToCm = parseInt(featSplitted[2]) * 2.54;
    totalCm = feetToCm + inchesToCm;
  } else {
    const splittedCm = height.split(' ');
    totalCm = parseInt(splittedCm[0]);
  }
  return totalCm;
};

export const weightConvert = (weight, weightInKg) => {
  let kilogram;
  if (weightInKg) {
    kilogram = parseInt(weight);
  } else {
    kilogram = poundsToKilograms(parseInt(weight));
  }
  return kilogram;
};

export function poundsToKilograms(pounds) {
  // 1 pound is equal to 0.45359237 kilograms
  const kilograms = pounds * 0.45359237;
  return kilograms;
};

export const weightconvertToPounds = (weight, weightInKg) => {
  let pounds
  if (weightInKg) {
    pounds = kgToLb(Number(weight));
  } else {
    pounds = Number(weight);
  }
  return Math.round(pounds);
}

export function kgToLb(kg) {
  return kg * 2.20462; // 1 kilogram is approximately equal to 2.20462 pounds
};

function feetAndInchesToMeter(feet, inches) {
  // Convert feet to meters (1 foot = 0.3048 meters)
  const feetInMeters = feet * 0.3048;
  // Convert inches to meters (1 inch = 0.0254 meters)
  const inchesInMeters = inches * 0.0254;
  // Add feet and inches in meters to get total meters
  const meters = feetInMeters + inchesInMeters;
  return meters;
};

function convertCmToMeters(heightInCm) {
  return heightInCm * 0.01; // 1 centimeter = 0.01 meters
}

export function calculateAge(birthDate) {
  const today = new Date();
  const birthDateObj = new Date(birthDate);
  let age = today.getFullYear() - birthDateObj.getFullYear();
  const monthDiff = today.getMonth() - birthDateObj.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
    age--;
  }
  return age;
};

export const checkAge = (value) => {
  if (value < 0) {
    return 40;
  } else {
    return value;
  }
};

export const walkLabel = (hour) => {
  let walkLabel;
  switch (hour) {
    case "0":
      walkLabel = 'Sedentary';
      break;
    case "1":
      walkLabel = 'Lightly Active';
      break;
    case "2":
      walkLabel = 'Moderately Active';
      break;
    case "3":
      walkLabel = 'Very Active';
      break;
    case "4+":
      walkLabel = 'Super Active';
      break;
    default:
      walkLabel = 'Sedentary';
  }
  return walkLabel;
};
export const calculateActiveBmr = (hour, bmr) => {
  let activeBmr;
  switch (hour) {
    case "0":
      activeBmr = bmr * 1.2;
      break;
    case "1":
      activeBmr = bmr * 1.375;
      break;
    case "2":
      activeBmr = bmr * 1.55;
      break;
    case "3":
      activeBmr = bmr * 1.725;
      break;
    case "4+":
      activeBmr = bmr * 1.9;
      break;
    default:
      activeBmr = bmr * 1.2;
  }
  return Math.round(activeBmr);
};
export const caloriesRestriction = (current, target) => {
  let payload = {};
  if (target < current) {
    payload = { label: 'restricting 500 daily calories', value: 'Restrict Calories', quantity: '⬇ Decreases ⬇' }
  } else {
    payload = { label: 'eating 500 more calories per day', value: 'Increase Calories', quantity: '⬆ Increases ⬆' }
  }
  return payload;
};
export const caloriesMaintain = (current, target) => {
  let payload;
  if (target < current) {
    payload = "500 less calories";
  } else {
    payload = "500 more calories"
  }
  return payload;
};
export const calculateXOrder = (current, target, weightInKg) => {
  let currentWeight, targetWeight;
  if (weightInKg) {
    currentWeight = kgToLb(current);
    targetWeight = kgToLb(target);
  } else {
    currentWeight = current;
    targetWeight = target;
  }
  const calculate = ((targetWeight - currentWeight) / 1.5 / 2 / 2);
  return Math.abs(Math.round(calculate));
};

export const getSlotValue = (value) => {
  const slot1 = value / 4 * 1;
  const slot2 = value / 4 * 2;
  const slot3 = value / 4 * 3;
  const slot4 = value / 4 * 4;
  return {
    slot1,
    slot2,
    slot3,
    slot4
  }
};