import React from 'react';
import goalDown from '../../assets/img/graph-down.svg';
import goalUp from '../../assets/img/graph-up.svg';

export const VideoSection = ({ Name, calculateXAxis, current, target, weightInKg, }) => {    
    return ( 
        <>
<div class="mbm-85">
    <div class="checkout-container ptm-0">
        <div class="fw-bolder mxm-24">
            <div class="d-flex align-center my-25 fs-34 mfs-24 text-center"><span>Controlling Healthy Calorie Intake Has <span className="color3 non-break">Never Been Easier!</span></span></div>
        </div>
        <p class="change-text text-center mxm-24">Biome Secret makes it easy to manage your weight by helping to restrict your calories, while increasing vital daily nutrition.</p>
        <div class="youtube-video">
            <iframe src="https://www.youtube.com/embed/-Pe3x5x8pis?rel=0" height="300px" width="100%" allowfullscreen=""></iframe>
        </div>
    </div>
</div>
<div class="checkout-container mb-50 pt-50 ptm-0">
    <div class="bg1 d-block align-center br-10 pt-20 pb-10 px-20 goal-graph-frame mxm-24">
        <div class="heading-1 text-center">Your target weight is achievable in <span class="non-break">{calculateXAxis} Recurring Orders</span></div>
        {Number(target) > Number(current) ?
            <img class="mw-100p mt-10" src={goalUp} /> :
            <img class="mw-100p mt-10" src={goalDown} />
        }
        <div class="body-transformation mb-20 px-20 change-text">
            <div class="d-flex">
                <div class="w-50"><span class="fw-bold">Today:</span> <span class="color3">{current} {weightInKg ? 'kg' : 'lbs'}</span></div>
                <div class="w-50 text-right"><span class="fw-bold">Goal:</span> <span class="color3">{target} {weightInKg ? 'kg' : 'lbs'}</span></div>
            </div>
        </div>        
    </div>
</div>

<div class="sticky-plan">
    <div class="get-my-plan mb-0"><a href="#get-plan" class="theme-btn" title="Continue"> Get my weight loss plan<div class="right-arrow"></div></a></div>
</div>        
        </>
    )
}