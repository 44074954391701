import React, { useState } from 'react';
import InfoPopup from './IngredientsPopup';

import chicken from '../../assets/img/food-cutouts/5.png';
import pizza from '../../assets/img/food-cutouts/1.png';
import burrito from '../../assets/img/food-cutouts/2.png';
import nachos from '../../assets/img/food-cutouts/6.png';

import meat from '../../assets/img/food-cutouts/11.png';
import bread from '../../assets/img/food-cutouts/17.png';
import flour from '../../assets/img/food-cutouts/7.png';
import sugar from '../../assets/img/food-cutouts/12.png';

import grains from '../../assets/img/food-cutouts/8.png';
import peas from '../../assets/img/food-cutouts/9.png';
import avo from '../../assets/img/food-cutouts/4.png';
import coconut from '../../assets/img/food-cutouts/14.png';

import chocshake from '../../assets/img/food-cutouts/chocolate.jpeg';

export const ServingComparison = ({ Name }) => {

    const [modal, setModal] = useState(false);

    const handleModal = () => {
        setModal(!modal);
    };


    return ( 
        <>
<div class="checkout-container pt-30 ptm-0 pb-30">
    <div class="fw-bolder mxm-24">
        <div class="align-center my-25 fs-34 mfs-24 text-center">Restricting 500 Calories <span class="non-break">is Easy</span></div>
    </div>
    <p class="change-text mxm-24 pb-20 text-center">Compare these 2 lunch options to see how easy it is to replace bad calories.</p>
    <div class="body-transformation">        
        <div class="d-flex">
            <div class="mw-50p d-flex flex-col bg1">
                <div class="body-transformation">
                    <div class="body-trans-header text-center text-darkbg d-flex">
                        <div class="mw-100p w-50 d-flex justify-content-center"><span class="valign-m my-a">Processed Food Lunch</span></div>
                    </div>
                </div>
                <div class="body-trans-body d-flex bg1 my-a">
                    <div class="my-a">
                        <div class="text-center valign-m">
                            <div class="color2 fw-bold mb-10 valign-t comparison-h">900 Calories Each</div>
                            <img class="mw-50p" src={chicken} />
                            <img class="mw-50p" src={pizza} />
                            <img class="mw-50p" src={burrito} />
                            <img class="mw-50p" src={nachos} />
                        </div>
                    </div>
                </div>
            </div>
            <div class="mw-50p highlighted bg4">
                <div class="body-trans-header text-center text-darkbg d-flex">
                    <div class="w-50 d-flex justify-content-center mw-100p"><span class="valign-m my-a"><span class="fw-bolder">Biome Secret</span><br></br>Nutritionally Complete Lunch</span></div>
                </div>
                <div class="body-trans-body d-flex narrow-p">
                    <div class="my-a">
                        <div class="text-center valign-m">
                            <div class="theme-color fw-bold mb-10 change-text">400 Calories</div>
                            <img class="mw-100p br-10" src={chocshake} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="fw-bolder mxm-24 my-10 py-10">
            <div class="align-center my-25 fs-28 mfs-24 text-center">Both options make you feel full. But...</div>
        </div>
        <div class="body-trans-body d-flex valign-t br-10 mxm-24 comparison">
            <div class="w-50 left bg1 my-a">
                <div class="valign-t fs-13">                                        
                    <div class="d-flex align-center">
                        <div class="close-circle-icon red me-10"></div>Processed Ingredients
                    </div>
                    <div class="d-flex align-center mt-20">
                        <div class="close-circle-icon red me-10"></div>Food Coma After 1-2 Hours
                    </div>
                    <div class="d-flex align-center mt-20">
                        <div class="close-circle-icon red me-10"></div>High in Sodium &amp; Fats
                    </div>
                    <div class="d-flex align-center mt-20">
                        <div class="close-circle-icon red me-10"></div>Added Sugar
                    </div>
                    <div class="d-flex align-center mt-20">
                        <div class="close-circle-icon red me-10"></div>Low Nutritional Value
                    </div>
                    <div class="d-flex align-center mt-20">
                        <div class="close-circle-icon red me-10"></div>Leads to Weight Gain
                    </div>                    
                </div>
            </div>
            <div class="w-50 right bg4">
                <div class="valign-t fs-13">                    
                    <div class="d-flex align-center">
                        <div class="circle-check green me-10"></div>100% Plant Based
                    </div>
                    <div class="d-flex align-center mt-20">
                        <div class="circle-check green me-10"></div>No Crash After Eating
                    </div>
                    <div class="d-flex align-center mt-20">
                        <div class="circle-check green me-10"></div>29 Vitamins & Minerals
                    </div>
                    <div class="d-flex align-center mt-20">
                        <div class="circle-check green me-10"></div>Zero Added Sugar
                    </div>
                    <div class="d-flex align-center mt-20">
                        <div class="circle-check green me-10"></div>High In Protein &amp; Fiber
                    </div>
                    <div class="d-flex align-center mt-20">
                        <div class="circle-check green me-10"></div>Helps Lose Management
                    </div>
                </div>
            </div>
        </div>
        <div class="fw-bolder mxm-24 my-10 py-10">
            <div class="align-center my-25 fs-28 mfs-24 text-center">Made with Superfoods <span class="non-break">that taste great.</span></div>
        </div>
        <div class="body-trans-body d-flex mxm-24 comparison bg1">
            <div class="w-50 left my-a">
                <div class="valign-m">
                    <div class="color2 fw-bold mb-10 valign-t text-center comparison-h">Why eat this</div>
                    <img class="mw-50p" src={meat} />
                    <img class="mw-50p" src={flour} />
                    <img class="mw-50p" src={sugar} />
                    <img class="mw-50p" src={bread} />
                </div>
            </div>
            <div class="w-50 my-a right bg4">
                <div class="valign-m">
                    <div class="color3 change-text fw-bold mb-10 text-center">When you can eat this</div>
                    <img class="mw-50p" src={grains} />
                    <img class="mw-50p" src={avo} />
                    <img class="mw-50p" src={coconut} />
                    <img class="mw-50p" src={peas} />
                    <div class="fw-bold fs-13 color5 text-center" onClick={handleModal}> <u>See all nutritional facts</u></div>
                </div>
            </div>
        </div>
        {/*<div class="mxm-24 fs-20 text-center my-10 py-10">
            <div class="fw-bold fs-18 color2" onClick={handleModal}> <u>See all nutritional facts</u></div>
        </div>
        <div class="body-trans-header text-center text-primary d-flex">
            <div class="w-50 d-flex justify-content-center bg6"><span class="valign-m my-a"><span class="fw-bold text-darkbg fs-28">Vanilla-Caramel</span></span></div>
            <div class="w-50 d-flex justify-content-center bg6"><span class="valign-m my-a"><span class="fw-bold text-darkbg fs-28">Chocolate</span></span></div>
        </div>
        <div class="body-trans-body d-flex">
            <div class="w-50 my-a">
                <div class="text-center valign-m">
                    <img class="mw-100p" src={carmshake} />
                </div>
            </div>
            <div class="w-50 my-a">
                <div class="text-center valign-m">
                    <img class="mw-100p" src={chocshake} />
                </div>
            </div>
        </div>*/}
    </div>
</div>
    
    { modal && <InfoPopup {...{handleModal}} /> } 
        </>
    )
}