import React, { useEffect } from 'react';

export const Question5 = ({ history, handleChange, values, setFieldValue, setProgress, setShowProgress, setPage }) => {

  useEffect(() => {
    let percent;
    percent = (5 / 18) * 100;
    percent = percent.toFixed(2);
    setPage(5);
    setProgress(percent);
    setShowProgress(true);
  }, []);

  const handleChangeHandler = (value) => {
    setFieldValue('lunchTaken', value);
    setTimeout(() => {
      history('/shake-new/q6');
    }, 300);
  };

  return (
    <>
      <section className="text-white lunch-content main">
        <div className="body-content" >
          <div className="heading mb-20 title-font h-wrap"><span className="h-before"> When do you usually have lunch?</span></div>
          <div className='single-choose-question max-308 mx-auto'>
            <button type='button' className={`button bg-theme text-white w-100 d-flex fw-bold align-center mb-16 ${Boolean(values.lunchTaken === '11-12') ? 'checked' : ''}`} onClick={() => handleChangeHandler('11-12')}>
              <span>
                11 AM - 12 PM
              </span>
            </button>
            <button type='button' className={`button bg-theme text-white w-100 d-flex fw-bold align-center mb-16 ${Boolean(values.lunchTaken === '12-1') ? 'checked' : ''}`} onClick={() => handleChangeHandler('12-1')}>
              <span>
                12 PM -  1 PM
              </span>
            </button>
            <button type='button' className={`button bg-theme text-white w-100 d-flex fw-bold align-center mb-16 ${Boolean(values.lunchTaken === '1-2') ? 'checked' : ''}`} onClick={() => handleChangeHandler('1-2')}>
              <span>
                1 PM - 2 PM
              </span>
            </button>
            <button type='button' className={`button bg-theme text-white w-100 d-flex fw-bold align-center mb-16 ${Boolean(values.lunchTaken === 'skip lunch') ? 'checked' : ''}`} onClick={() => handleChangeHandler('skip lunch')}>
              <span>
                I skip lunch
              </span>
            </button>
          </div>
        </div>
      </section>
    </>
  )
}