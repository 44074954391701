import React, { useEffect } from 'react';

export const Question4 = ({ history, values, handleChange, setFieldValue, setProgress, setShowProgress, setPage }) => {

  useEffect(() => {
    let percent;
    percent = (4 / 18) * 100;
    percent = percent.toFixed(2);
    setPage(4);
    setProgress(percent);
    setShowProgress(true);
  }, []);

  const handleChangeHandler = (value) => {
    setFieldValue('breakfastTaken', value);
    setTimeout(() => {
      history('/shake/q5');
    }, 300);
  };

  return (
    <>
      <section className="text-primary breakfast-content main">
        <div className="body-content" >
          <div className="heading text-primary mb-20 title-font mx-auto"> When do you usually have breakfast? </div>
          <div className='single-choose-question max-308 mx-auto'>
            <button type='button' className='button bg-theme text-white w-100 d-flex fw-bold align-center mb-16' onClick={() => handleChangeHandler('6-7')}>
              <span>
                6 AM - 7 AM
              </span>
            </button>
            <button type='button' className='button bg-theme text-white w-100 d-flex fw-bold align-center mb-16' onClick={() => handleChangeHandler('7-8')}>
              <span>
                7 AM -  8 AM
              </span>
            </button>
            <button type='button' className='button bg-theme text-white w-100 d-flex fw-bold align-center mb-16' onClick={() => handleChangeHandler('9-10')}>
              <span>
                9 AM - 10 AM
              </span>
            </button>
            <button type='button' className='button bg-theme text-white w-100 d-flex fw-bold align-center mb-16' onClick={() => handleChangeHandler('skip breakfast')}>
              <span>
                I skip breakfast
              </span>
            </button>
          </div>
        </div>
      </section>
    </>
  )
}
