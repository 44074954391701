import React from "react";
import { Footer } from '../common/components/Footer';

const Privacy = () => (
    <div>
    <div class="cms container terms-page text-primary pb-50 body-padding">
    <div class="fs-28 fw-bold mb-50 mt-50"> Privacy Policy</div>
    <div>
        <h2 class="fs-18 fw-bold">1. Introduction</h2>
        <p>Biome Secret is committed to protecting the privacy of our users. This Privacy Policy explains how we collect, use, and disclose information about you when you use our website.</p>
        <h3 class="fs-14">1.1 Scope of the Privacy Policy</h3>
        <p>This Privacy Policy applies only to information that we collect through our website. It does not apply to any other information collected by us through any other means.</p>
        <h3 class="fs-14">1.1 Updates to the Privacy Policy</h3>
        <p>We may update this Privacy Policy from time to time. If we make any material changes, we will notify you by email (if you have provided us with your email address) or by posting a notice on our website prior to the change becoming effective. Your continued use of our website after the effective date of any changes to this Privacy Policy constitutes your agreement to the changes.</p>
    </div>
    <div>
        <h2 class="fs-18 fw-bold pt-20">2. Information We Collect</h2>
        <p>We collect the following types of information:</p>
        <h3 class="fs-14">2.1 Information You Provide Us</h3>
        <p>We collect information you provide directly to us, such as your name, email address, weight, height, gender, and address when you fill out forms or quizzes on our website.</p>
        <h3 class="fs-14">2.2 Automatically Collected Information</h3>
        <p>We automatically collect certain information about you when you use our website, such as your IP address, browser type, and operating system.</p>
        <h3 class="fs-14">2.3 Purpose of Information Collection</h3>
        <p>We collect your information for the following purposes:</p>
        <ul>
            <li>To recommend the correct product (supplement) to you based on your inputs, which is essential for our website to function correctly</li>
            <li>For marketing purposes, including remarketing and ad personalization, in order to provide you with relevant offers and promotions</li>
        </ul>
        <h3 class="fs-14">2.4 Legal Basis for Processing Information</h3>
        <p>We process your personal information on the following legal bases:</p>
        <ul>
            <li>Consent: When you provide your explicit consent to the processing of your personal information for specific purposes.</li>
            <li>Contract: When we need to process your personal information to fulfill a contract with you, such as processing your payment information to complete a purchase.</li>
            <li>Legitimate interests: When we have a legitimate interest in processing your personal information, such as improving our products and services, protecting our legal rights, and providing you with a personalized experience on our website.</li>
            <li>Legal obligation: When we are legally required to process your personal information, such as for tax and accounting purposes.</li>
        </ul>
        <h3 class="fs-14">2.5 Third-Party Information Sharing</h3>
        <p>We may share your personal information with third-party service providers who perform services on our behalf, such as payment processors, shipping companies, and marketing partners. We only share the information necessary to perform the service, and we require these providers to use your personal information only as necessary to provide the services to us.</p>
        <p>We may also share your personal information with third-party marketing partners, such as Facebook and Google, to show you personalized ads based on your interests and to analyze the effectiveness of our marketing campaigns. We only share the information necessary to show you relevant ads and to measure the performance of our marketing efforts.</p>
        <p>We may disclose your personal information to third parties if we believe that disclosure is necessary to comply with the law, protect our legal rights, or prevent fraud or other illegal activity.</p>
    </div>
    <div>
        <h2 class="fs-18 fw-bold pt-20">3. Cookies and Other Tracking Technologies</h2>
        <p>We use cookies and other tracking technologies to collect information about your use of our website, to keep you logged in during quizzes, to provide you with a better browsing experience, and to understand how you interact with our website. Cookies are small data files stored on your device that allow us to recognize your device when you return to our website. The types of cookies and other tracking technologies we use include:</p>
        <ul>
            <li>Session cookies</li>
            <li>Persistent cookies</li>
            <li>Analytics cookies</li>
            <li>Advertising cookies</li>
        </ul>
        <p>The purpose of using these cookies and other tracking technologies is to personalize your experience on our website, analyze your usage, and deliver relevant advertising to you. These technologies also help us understand how our website is being used and to improve our website's performance.</p>
        <p>If you do not want us to collect this information through the use of cookies, you can disable cookies in your browser's settings. However, please note that disabling cookies may limit your ability to use certain features of our website.</p>
    </div>
    <div>
        <h2 class="fs-18 fw-bold pt-20">4. Data Security and Retention</h2>
        <p>We take the security of our users' information seriously and have implemented appropriate measures to protect it from unauthorized access, disclosure, alteration, or destruction. This includes using encryption technology to safeguard sensitive data during transmission and secure storage systems to protect it while at rest.</p>
        <p>We will retain user data only for as long as necessary to fulfill the purposes outlined in Section 2, unless a longer retention period is required or permitted by law. Once the data is no longer needed, we will securely dispose of it in a manner that complies with applicable laws and regulations.</p>
    </div>
    <div>
        <h2 class="fs-18 fw-bold pt-20">5. User Rights</h2>
        <p>As a user, you have the following rights regarding your personal data:</p>
        <ul>
            <li>Right to access – You have the right to request access to the personal data we have collected about you.</li>
            <li>Right to rectification – You have the right to request that we correct any inaccuracies in the personal data we have collected about you.</li>
            <li>Right to erasure – You have the right to request that we delete the personal data we have collected about you.</li>
            <li>Right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</li>
            <li>Right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</li>
            <li>Right to data portability – You have the right to request that we transfer the data we have collected about you to another organization, or directly to you, under certain conditions.</li>
        </ul>
        <p>If you would like to exercise any of these rights, please contact us using the contact information provided in this privacy policy. We will respond to your request within a reasonable timeframe.</p>
    </div>
    <div>
        <h2 class="fs-18 fw-bold pt-20">6. Changes to this Privacy Policy</h2>
        <p>We reserve the right to make changes to this Privacy Policy at any time and for any reason. We will notify you about any changes by updating the "Last Updated" date of this Privacy Policy. You are encouraged to periodically review this Privacy Policy to stay informed of updates. Your continued use of our website after the posting of changes to this Privacy Policy constitutes your acceptance of such changes.</p>
    </div>
    <div>
        <h2 class="fs-18 fw-bold pt-20">7. Governing Law</h2>
        <p>This privacy policy and the collection, use, and disclosure of your personal data are governed by the laws of the United Arab Emirates (UAE). Any legal disputes or claims arising out of or relating to this privacy policy shall be subject to the exclusive jurisdiction of the courts in the UAE.</p>
    </div>
    </div>
    <Footer />
</div>

);
export default React.memo(Privacy);