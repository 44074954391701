import React, { useState, useEffect } from 'react';
import landingBgDesktop from '../../assets/img/updated/shake-new/Landing-page-main-picture.webp';
import logo from '../../assets/img/updated/shake-new/biome-white-logo.svg';
import logoh from '../../assets/img/updated/shake-new/logo-health.svg';
import logob from '../../assets/img/updated/shake-new/logo-bloomberg.svg';
import logot from '../../assets/img/updated/shake-new/logo-today.svg';
import logov from '../../assets/img/updated/shake-new/logo-vogue.svg';

export const Question0 = ({ history, handleChange, values, setFieldValue, setShowProgress }) => {
  const [alert, setAlert] = useState(false);
  useEffect(() => {
    setShowProgress(false);
  }, []);

  const handleChangeHandler = (e) => {
    if (values.termsAndCondition) {
      setTimeout(() => {
        history('/shake-new/q1');
      }, 300);
    } else {
      setAlert(true);
    }
    handleChange(e);
  };
  const checkboxHandler = (e) => {
    setAlert(false);
    handleChange(e);
    if (values.gender && e.target.checked) {
      setTimeout(() => {
        history('/shake-new/q1');
      }, 300);
    }
  };

  return (
    <>
      <section className="step-goal full-container">
        <div className="mx-auto d-flex full-container-content">
          <div className="left-content bg-light-grey">
            <div class="nav-logo"> <a href="/" aria-label="" ><img src={logo} alt="logo" width={192} /></a></div>
            <div className="gender-content c1">
              <div className="gender-content c2">
                <h1 className="heading-1 secondary-font s-025"><span className="fw-bolder">Diets not working?</span> Try a new approach.</h1>
                {/* <p>Biome Secret is a personalized weight management solution, delivered right to your door.</p> */}
                <div className='tagline t-centered'>
                  <div className="my-auto">
                    <svg className="my-auto me-5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" fill="#059c6d" />
                    </svg>
                    Discover why diets fail
                  </div>
                  <div className="my-auto">
                    <svg className="my-auto me-5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" fill="#059c6d" />
                    </svg>
                    Get results without extremes
                  </div>
                  <div className="my-auto">
                    <svg className="my-auto me-5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" fill="#059c6d" />
                    </svg>
                    Boost metabolism
                  </div>
                  <div className="my-auto">
                    <svg className="my-auto me-5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" fill="#059c6d" />
                    </svg>
                    Manage nutrition & cravings
                  </div>
                </div>
                <div className='d-inline-flex mb-20 stars-new'>
                  <span className="star-box my-auto">
                    <svg viewBox="0 0 799.89 761" xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path d="M799.89 290.83H494.44L400.09 0l-94.64 290.83L0 290.54l247.37 179.92L152.72 761l247.37-179.63L647.16 761l-94.35-290.54z" fill="#059c6d" /><path d="M574.04 536.24l-21.23-65.78-152.72 110.91z" fill="#133f26" /></svg>
                    <svg viewBox="0 0 799.89 761" xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path d="M799.89 290.83H494.44L400.09 0l-94.64 290.83L0 290.54l247.37 179.92L152.72 761l247.37-179.63L647.16 761l-94.35-290.54z" fill="#059c6d" /><path d="M574.04 536.24l-21.23-65.78-152.72 110.91z" fill="#133f26" /></svg>
                    <svg viewBox="0 0 799.89 761" xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path d="M799.89 290.83H494.44L400.09 0l-94.64 290.83L0 290.54l247.37 179.92L152.72 761l247.37-179.63L647.16 761l-94.35-290.54z" fill="#059c6d" /><path d="M574.04 536.24l-21.23-65.78-152.72 110.91z" fill="#133f26" /></svg>
                    <svg viewBox="0 0 799.89 761" xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path d="M799.89 290.83H494.44L400.09 0l-94.64 290.83L0 290.54l247.37 179.92L152.72 761l247.37-179.63L647.16 761l-94.35-290.54z" fill="#059c6d" /><path d="M574.04 536.24l-21.23-65.78-152.72 110.91z" fill="#133f26" /></svg>
                    <svg viewBox="0 0 799.89 761" xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path d="M799.89 290.83H494.44L400.09 0l-94.64 290.83L0 290.54l247.37 179.92L152.72 761l247.37-179.63L647.16 761l-94.35-290.54z" fill="#059c6d" /><path d="M574.04 536.24l-21.23-65.78-152.72 110.91z" fill="#133f26" /></svg>
                  </span>
                  <span className="star-u my-auto">1,659</span>
                  <span className="star-text"> Verified 5-Star Reviews</span>
                </div>
                <div className="heading-2 secondary-font pb-gender">Select Your Gender</div>
                <div className="single-select-items d-flex pb-gender">
                  <div className="item male">
                    <input type="radio" value="male" name="gender" id="gender_1" className={values.gender === 'male' && 'checked'} onChange={(e) => handleChangeHandler(e)} />
                    <label className="d-flex align-center" for="gender_1">
                      <div > <span className="fw-bolder"> Male </span> </div>
                    </label>
                  </div>
                  <div className="item female">
                    <input type="radio" value="female" name="gender" id="gender_2" className={values.gender === 'female' && 'checked'} onChange={(e) => handleChangeHandler(e)} />
                    <label className="d-flex align-center" for="gender_2">
                      <div > <span className="fw-bolder"> Female </span> </div>
                    </label>
                  </div>
                </div>
                <div className="terms-condition">
                  <div className="checkbox-content fs-12 color2 f-italic mx-auto">
                    <input type="checkbox" value={values.termsAndCondition} checked={values.termsAndCondition} name="termsAndCondition" id="control_04" onChange={(e) => checkboxHandler(e)} />
                    <label for="control_04"><span>By continuing, I agree with <a target="_blank" href="" className="color2">Terms of service</a>, <a target="_blank" href="" className="color2">Privacy policy</a>, <a target="_blank" href="" className="color2">Refund policy</a>, <a target="_blank" href="" className="color2">Subscription Terms</a>, <a target="_blank" href="" className="color2">Cookie policy</a>.</span></label>
                  </div>
                  {alert &&
                    <div className="alert-box mb-20"> <div className="info-icon white me-10"></div> To continue, please accept our terms and policies.</div>
                  }
                </div>
              </div>
              <div className='text-center home-box'>
                <div className='fs-20 mb-20 fw-bolder f-italic text-green'>The Only Weight Loss Shake Using Clinically Backed Studies</div>

                {/*<img src={starReviews} className='img-fluid mb-10' alt='Biomesecret' style={{maxWidth:'80%'}} />*/}
                <div className='d-flex mx-auto jusitify-content-evenly bottom-logos'>
                  <img src={logoh} className='img-fluid home-logo' />
                  <img src={logob} className='img-fluid home-logo' />
                  <img src={logot} className='img-fluid home-logo' />
                  <img src={logov} className='img-fluid home-logo' />
                </div>
              </div>
            </div>
          </div>

          <div className="right-content text-center">
            <div class="nav-logo"> <a href="/" aria-label="" ><img src={logo} alt="logo" width={192} /></a></div>
            <div className='ticker-content'>
              <div className=''>
                <img src={landingBgDesktop} className='img-fluid' alt='Biomesecret' />
                {/* <img src={landingBgDesktop} className='img-fluid desktop' alt='Biomesecret' /> */}
              </div>
              {/* <div className=''>
                <img src={landingBgMobile} className='img-fluid mobile' alt='Biomesecret' />
                <img src={landingBgMobile} className='img-fluid mobile' alt='Biomesecret' />
              </div> */}
              {/* <img src={landingAppImg} className='img-fluid appImg' alt='Biomesecret' /> */}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}