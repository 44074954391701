import React, { useEffect } from 'react';
import summary2 from '../../assets/img/updated/shake-new/Half-graph-without-BG.svg';
import redStop from '../../assets/img/updated/shake-new/red-cross.png';
import greenTick from '../../assets/img/updated/shake-new/green-check.png';




export const Summary2 = ({ history, values, handleChange, setProgress, setShowProgress, setPage }) => {

    useEffect(() => {
        let percent;
        percent = (18 / 18) * 100;
        percent = percent.toFixed(2);
        setPage(18);
        setProgress(percent);
        setShowProgress(true);
    }, []);

    const clickHandler = () => {
        setTimeout(() => {
            history('/shake-new/summary3');
        }, 300);
    };

    return (
        <section className="text-primary main summary-page-content">
    <div className='shake-summary-page'>
        <div className='shake-summary-page-steps text-white'>
            <span className='active'></span>
            <span className='active'></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        <div className='shake-summary-page-content'>
            <div class="heading">
                <div class="text-primary title-font mx-auto">
                    Your gut would benefit <span className='theme-color'><br />
                        from increased nutrition
                    </span>
                </div>
            </div>
            <div className='weight-loss-journey-new mt-20'>
                <div className='weight-zone-graphics'>
                    <img src={summary2} className='img-fluid' style={{maxHeight: '450px'}} />
                </div>
                <div className='weight-zone d-flex'>
                    <div className=''>
                        <div className='fs-20 text-center fw-bolder'>
                            Weight Gain Zone
                        </div>
                    </div>
                    <div className=''>
                        <div className='fs-20  text-center fw-bolder'>
                            Weight Loss Zone
                        </div>
                    </div>
                </div>
                <div className='weight-zone d-flex'>
                    <div className=''>
                        <div className='d-flex fs-16  align-center h-100'>
                            <img src={redStop} className='img-fluid icon me-10' />
                            Low Protein intake
                        </div>
                    </div>
                    <div className=''>
                        <div className='d-flex fs-16  align-center h-100'>
                            <img src={greenTick} className='img-fluid icon me-10' />
                            50-70% more protein to lost fat not muscle
                        </div>
                    </div>
                </div>
                <div className='weight-zone d-flex'>
                    <div className=''>
                        <div className='d-flex fs-16  align-center h-100'>
                            <img src={redStop} className='img-fluid icon me-10' />
                            Low fiber intake
                        </div>
                    </div>
                    <div className=''>
                        <div className='d-flex fs-16  align-center h-100'>
                            <img src={greenTick} className='img-fluid icon me-10' />
                            30-50% more fiber to remove bloating toxins
                        </div>
                    </div>
                </div>
                <div className='weight-zone d-flex'>
                    <div className=''>
                        <div className='d-flex fs-16  align-center h-100'>
                            <img src={redStop} className='img-fluid icon me-10' />
                            Empty calories
                        </div>
                    </div>
                    <div className=''>
                        <div className='d-flex fs-16  align-center h-100'>
                            <img src={greenTick} className='img-fluid icon me-10' />
                            Minmum 25 daily Vitamins & Minerals
                        </div>
                    </div>
                </div>
                <div className='weight-zone d-flex'>
                    <div className=''>
                        <div className='d-flex fs-16  align-center h-100'>
                            <img src={redStop} className='img-fluid icon me-10' />
                            Vitamins & Minerals deficiency
                        </div>
                    </div>
                    <div className=''>
                        <div className='d-flex fs-16  align-center h-100'>
                            <img src={greenTick} className='img-fluid icon me-10' />
                            Prebiotic & Probiotics to rebalance gut
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-30 d-flex flex-col">
                            <button
                                type='button'
                                className="button bg-secondary text-white w-100 mx-auto text-center mb-16 w-100 align-center"
                                title="Continue"
                                // disabled={values.targetWeightInKg ? errors.targetWeightKg : errors.targetWeightLb}
                                onClick={clickHandler}
                            > Continue
                            </button>
                            <div className='f-italic fs-10 color2 mx-auto'>
                                * Results may vary due personal features
                            </div>
                        </div>
        </div>
    </div>
</section>
    )  
};