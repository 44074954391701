import React from "react";

const InfoPopup = ({handleModal}) => {
    return (
        <div id="myModal" class="modal">
            <div class="modal-content text-primary"> <span class="close" onClick={handleModal}><span class="close-icon"></span></span>
                <h1 class="fs-20 fw-bold m-0 ">Current Body Mass Index (BMI)</h1>
                <p class="mb-10"> Body mass index (BMI) is a value derived from the mass and height of a person. </p>
                <p> The BMI is a convenient rule of thumb used to broadly categorize a person as underweight, normal, slightly overweight, or obese. </p>
                <div class="vertical-progress-bar">
                    <ul>
                        <li> <span class="mark-icon"></span> <b>&#60; 18.5</b><span class="fs-16"> Underweight </span></li>
                        <li> <span class="mark-icon"></span> <b>18.5 - 24.9</b><span class="fs-16"> Normal </span></li>
                        <li> <span class="mark-icon"></span> <b>25 - 29.9</b><span class="fs-16"> Overweight </span></li>
                        <li> <span class="mark-icon"></span> <b>30 - 34.9</b><span class="fs-16"> Significantly Overweight </span></li>
                        <li> <span class="mark-icon"></span> <b>&#62; 35 </b><span class="fs-16"> Obese </span></li>
                    </ul>
                </div>
            </div>
        </div>
    );
}
export default InfoPopup;