import React from "react";

const FeetAndInchesField = ({
    handleBlur,
    values,
    errors,
    touched,
    handleFieldChange
}) => (
    <div className='d-flex two-fields'>
        <div className=''>
            <input type="number" className="form-field" name='feet' onChange={(e) => handleFieldChange(e, 1)} value={values.feet} onBlur={handleBlur} placeholder='ft.' />
            {errors?.feet && touched?.feet ? <div className='error mt-10 fs-13'>{errors?.feet}</div> : <></>}
        </div>
        <div>
            <input type="number" className="form-field" name='inches' onChange={(e) => handleFieldChange(e, 2)} value={values.inches} onBlur={handleBlur} placeholder='in.' />
            {errors?.inches && touched?.inches ? <div className='error mt-10 fs-13'>{errors?.inches}</div> : <></>}
        </div>

    </div>
);

export default FeetAndInchesField;