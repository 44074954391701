import goal1 from '../../assets/img/male/M_5_To_9.png';
import goal2 from '../../assets/img/male/M_10_To_14.png';
import goal3 from '../../assets/img/male/M_15_To_19.png';
import goal4 from '../../assets/img/male/M_20_To_24.png';
import goal5 from '../../assets/img/male/M_25_To_29.png';
import goal6 from '../../assets/img/male/M_30_To_34.png';
import goal7 from '../../assets/img/male/M_35_To_39.png';
import goal8 from '../../assets/img/male/M_40_Plus.png';
import female1 from '../../assets/img/female/W_5_To_9.png';
import female2 from '../../assets/img/female/W_10_To_14.png';
import female3 from '../../assets/img/female/W_15_To_19.png';
import female4 from '../../assets/img/female/W_20_To_24.png';
import female5 from '../../assets/img/female/W_25_To_29.png';
import female6 from '../../assets/img/female/W_30_To_34.png';
import female7 from '../../assets/img/female/W_35_To_39.png';
import female8 from '../../assets/img/female/W_40_Plus.png';


export const renderImage = (fat, gender) => {
  let image;
  switch(fat) {
    case 0:
        image = gender === 'female' ? female1 :  goal1;
        break;
    case 1:
        image = gender === 'female' ? female2 :  goal2;
        break;
    case 2:
        image = gender === 'female' ? female3 :  goal3;
        break;
    case 3:
        image = gender === 'female' ? female4 :  goal4;
        break;
    case 4:
        image = gender === 'female' ? female5 :  goal5;
        break;
    case 5:
        image = gender === 'female' ? female6 : goal6;
        break;
    case 6:
        image = gender === 'female' ? female7 : goal7;
        break;
    case 7:
        image = gender === 'female' ? female8 : goal8;
        break;
    default: 
        image = gender === 'female' ? female1 : goal1
  }
  return image;
};

export const validateHeight = (height, history, setFieldError) => {
  // Extract feet and inches values
  // const regex = /^(\d+)'(\d{1,2})"$/;
  const regex = /(\d+)\s*ft\s*(\d+)\s*/i;
  const match = height.match(regex);
  if (match) {
    const feet = parseInt(match[1]);
    const inches = parseInt(match[2]);

    // Convert height to inches
    const totalInches = feet * 12 + inches;

    // Check if height is greater than 8 feet and 0 inches
    if (totalInches > 8 * 12) {
      setFieldError('height', 'Height must be maximum of 8ft 0in');
    } else if (feet < 3 || inches > 11) {
      setFieldError('height', 'Height must be minimum of 3ft and 11in');
    } else {
      history('/quiz/q8');
    }
      
  } else {
    // Invalid height format
    setFieldError('height', 'invalid format');
  }

  // Height is valid
  return true;
};

export const percentRange = (bodyFat, gender) => {
  let percent;
  switch(bodyFat) {
    case 0:
      percent = gender === 'female' ? '15-18%' : '5-9%';
      break;
    case 1:
      percent = gender === 'female' ? '19-22%' : '10-14%';
      break;
    case 2:
      percent = gender === 'female' ? '23-25%' : '15-19%';
      break;
    case 3:
      percent = gender === 'female' ? '26-29%' : '20-24%';
      break;
    case 4:
      percent = gender === 'female' ? '30-34%' : '25-29%';
      break;
    case 5:
      percent = gender === 'female' ? '35-39%' : '30-34%';
      break;
    case 6:
      percent = gender === 'female' ? '40-44%' : '35-39%';
      break;
    case 7:
      percent = gender === 'female' ? '>45%' : '>40%';
      break;
    default:
      percent = gender === 'female' ? '15-18%' : '5-9%';
  }
  return percent;
};

export const monthlyPlan = (option) => {
  let payload = {
    months: '1-MONTH PLAN',
    payment: 100
  }
  switch(option) {
    case '1month':
      payload = payload;
      break;
    case '3month':
      payload.months = '2-MONTH PLAN';
      payload.payment = 150;
      break;
    case '6month':
      payload.months = '3-MONTH PLAN';
      payload.payment = 200;
      break;
    default:
      payload = payload;
  }
  return payload;
};

export const dateTimeFormate = () => {
  const currentDate = new Date();
  const options = { 
    year: 'numeric', 
    month: 'long', 
    day: 'numeric', 
    hour: 'numeric', 
    minute: 'numeric', 
    second: 'numeric',
    timeZone: 'Europe/Berlin'
  };
  const formattedDateTime = new Intl.DateTimeFormat('en-US', options).format(currentDate);
  return formattedDateTime;
};

export const handleInputChange = (event, handleChange) => {
  let { value } = event.target;
  event.target.value = value.replace(/\D/g, ''); // Remove non-numeric characters 
  handleChange(event);
};
export const handleKeyDown = (event) => {
  const { value } = event.target;
  if (value.length >= 3 && event.key !== 'Backspace') {
    event.preventDefault();
  }
};