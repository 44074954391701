import React from "react";
import { Formik, Form, Field } from 'formik';
import InputMask from "react-input-mask";
import lgPay from '../../assets/img/light_gpay.svg';
import visa from '../../assets/img/visa.svg';
import amex from '../../assets/img/amex.svg';
import discover from '../../assets/img/discover.svg';
import master from '../../assets/img/master.svg';
import defaultCard from '../../assets/img/default_card.svg';

const CreditCard = ({submitData, history}) => {
    return (
        <>
            <div className="g-pay d-none">
                <button> <img src={lgPay} /> <img src={visa} className="card-logo" /> <span>.... 1420</span> </button>
            </div>
            <p className="text-center d-none">-OR-</p>
            <Formik
                validateOnMount = {true}
                initialValues={{ cardnumber: '', expmonth: '', cvv: '' }}
                validate={values => {
                    const errors = {};
                    if (!values.cardnumber) {
                    errors.cardnumber = 'Required';
                    }
                    if (!values.expmonth) {
                    errors.expmonth = 'Required';
                    }
                    if (!values.cvv) {
                    errors.cvv = 'Required';
                    }
                    return errors;
                }}
                onSubmit={values => {
                    console.log(values);
                    submitData();
                    history('/error');
                }}
            >
            {({ isValid, handleChange }) => (
                <Form>
                    <div className="payment-form">
                        <div className="credit-card position-relative">
                            <label for="ccnum">Credit or Debit Card Number</label>
                            <i><img src={defaultCard}/></i>                            
                            <InputMask mask='9999-9999-9999-9999' maskChar='' id="ccnum" name="cardnumber" placeholder="XXXX-XXXX-XXXX-XXXX" onChange={handleChange}/>
                        </div>
                        <div className="row">
                            <div className="col-50">
                                <label for="expmonth">Expiry Date</label>
                                <InputMask mask='99/99' maskChar="" placeholder='MM/YY' id="expmonth" name="expmonth" onChange={handleChange} />
                            </div>
                            <div className="col-50">
                                <label for="cvv">CVV</label>
                                <Field id="cvv" name="cvv" placeholder="123" maxlength="4"/>
                            </div>
                        </div>
                        <button id="purchase-card" type="submit" className="theme-btn" disabled={!isValid}>
                            <div className="lock-iconn me-5 d-none"></div>
                            Pay
                        </button>
                    </div>
                </Form>
            )}
            </Formik>
            <div className="d-flex payment-logos"> 
                <img src={amex} /> 
                <img src={discover} /> 
                <img src={master} /> 
                <img src={visa} /> 
            </div>
        </>
    );
}
export default React.memo(CreditCard);