import React from 'react';
import img1 from '../../assets/img/FROM_25_TO_29.webp';
import img2 from '../../assets/img/SLIM_BODY.webp';
import {renderImage, percentRange} from '../../common/utils/utils';


export const BodyFat = ({currentBody, dreamBody, currentFatImage, dreamFatImage}) => {
    const currentFatPercent = percentRange(currentBody);
    const dreamFatPercent = percentRange(dreamBody);
    
  return (
    <div class="checkout-container pb-30">
    <div class="fw-bolder mxm-24"><div class="align-center mb-10 fs-34 mfs-24 text-center pb-20 ptm-20">How to <span class="non-break">Lose Weight</span></div></div>
    <div class="body-transformation">
        <div class="body-transformation-header">
            <div class="w-50">
                <div class="body-trans-frame">
                    <img src={currentFatImage} alt="" />
                </div>
            </div>
            <div class="w-50">
                <div class="body-trans-frame">
                    <img src={dreamFatImage} alt="" />
                </div>
            </div>
        </div>
        <div class="d-flex">
            <div class="mw-50p w-100">
                <div class="body-transformation">
                    <div class="body-trans-header text-center text-darkbg d-flex">
                        <div class="mw-100p w-50 d-flex justify-content-center intro"><span class="valign-m my-a">Now</span></div>
                    </div>
                </div>
                <div class="body-trans-body d-flex bg1">
                    <div class="w-100">
                        <div class="fw-bold mb-5">Body fat</div>
                        <div class="color4 fs-18 fw-bold mb-20">{currentFatPercent}</div>
                        <div class="fw-bold mb-5">Body muscles</div>
                        <div class="progress-bar-trans">
                            <span class="active"></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mw-50p highlighted bg4 w-100">
                <div class="body-trans-header text-center text-darkbg d-flex">
                    <div class="w-50 d-flex justify-content-center mw-100p intro"><span class="valign-m my-a"><span class="fw-bolder">Goal</span></span></div>
                </div>
                <div class="body-trans-body d-flex">
                    <div class="w-100">
                        <div class="fw-bold mb-5">
                            Body fat
                        </div>
                        <div class="theme-color fs-18 fw-bold mb-20">{dreamFatPercent}</div>
                        <div class="fw-bold mb-5">
                            Body muscles
                        </div>
                        <div class="progress-bar-trans">
                            <span class="active"></span>
                            <span class="active"></span>
                            <span class="active"></span>
                            <span class="active"></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/*<div class="body-trans-header text-center text-darkbg d-flex">
            <div class="w-50">
                Now
            </div>
            <div class="w-50">
                Goal
            </div>
        </div>
        <div class="body-trans-body text-white d-flex">
            <div class="w-50">
                <div class="fw-bold mb-5">
                    Body fat
                </div>
                <div class="color4 fs-18 fw-bold mb-20">{currentFatPercent}</div>
                <div class="fw-bold mb-5">
                    Body muscles
                </div>
                <div class="progress-bar-trans">
                    <span class="active"></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
            <div class="w-50">
                <div class="fw-bold mb-5">
                    Body fat
                </div>
                <div class="color4 fs-18 fw-bold mb-20">{dreamFatPercent}</div>
                <div class="fw-bold mb-5">
                    Body muscles
                </div>
                <div class="progress-bar-trans">
                    <span class="active"></span>
                    <span class="active"></span>
                    <span class="active"></span>
                    <span class="active"></span>
                    <span></span>
                </div>
            </div>
        </div>*/}
        <p class="fs-12 color2 text-center">*The image is not intended to represent the user. Results vary per person and are not guaranteed.</p>
    </div>
</div>
  )
}
