import React, { useEffect } from 'react';


export const Question8 = ({ history, handleChange, setFieldValue, values, weightInKg, setWeightInKg, setProgress, setShowProgress, setPage }) => {

  useEffect(() => {
    let percent;
    percent = (8 / 18) * 100;
    percent = percent.toFixed(2);
    setPage(8);
    setProgress(percent);
    setShowProgress(true);
  }, []);

  const handleChangeHandler = (value) => {
    setFieldValue('exercise', value);
    setTimeout(() => {
      history('/shake-new/q9');
    }, 300);
  };

  return (
    <>
      <section className="text-white exercise-content main">
        <div className="body-content" >
          <div className="heading mb-20 title-font h-wrap"><span className="h-before"> How often do you exercise?</span></div>
          <div className='single-choose-question max-308 mx-auto'>
            <button type='button' className={`button bg-theme text-white w-100 d-flex fw-bold align-center mb-16 ${Boolean(values.exercise === 'everyday') ? 'checked' : ''}`} onClick={() => handleChangeHandler('everyday')}>
              {/* <img src={everyDay} className='question-icon' width='24' height='20' /> */}
              <span>
                Every Day
              </span>
            </button>
            <button type='button' className={`button bg-theme text-white w-100 d-flex fw-bold align-center mb-16 ${Boolean(values.exercise === 'few times a week') ? 'checked' : ''}`} onClick={() => handleChangeHandler('few times a week')}>
              {/* <img src={inWeek} className='question-icon' width='24' height='20' /> */}
              <span>
                A Few Times A Week
              </span>
            </button>
            <button type='button' className={`button bg-theme text-white w-100 d-flex fw-bold align-center mb-16 ${Boolean(values.exercise === 'once a while') ? 'checked' : ''}`} onClick={() => handleChangeHandler('once a while')}>
              {/* <img src={onceWhile} className='question-icon' width='24' height='20' /> */}
              <span>
                Once In A While
              </span>
            </button>
            <button type='button' className={`button bg-theme text-white w-100 d-flex fw-bold align-center mb-16 ${Boolean(values.exercise === 'never') ? 'checked' : ''}`} onClick={() => handleChangeHandler('never')}>
              {/* <img src={never} className='question-icon' width='24' height='20' /> */}
              <span>
                Never
              </span>
            </button>
          </div>
        </div>
      </section>
    </>
  )
};
