import React, { useState } from 'react';
import img3 from '../../assets/img/triangle.svg';
import InfoPopup from './InfoPopup';
import handicap from '../../assets/img/icons/reverse.svg';
import heart from '../../assets/img/icons/heart.svg'
import clipboardplus from '../../assets/img/icons/clipboard-plus.svg';
import clipboardheart from '../../assets/img/icons/clipboard-heart.svg';
import fire from '../../assets/img/icons/lightning-narrow.svg';
import { bmiConvertor, bmiLabelGenerator, checkAge } from '../../common/utils/formulas';

export const QuestionsSummary = ({age, bmr, mAge, currentWeightInKg, height, walkInActiveLabel, activeBmr}) => {
    const [modal, setModal] = useState(false);

    const handleModal = () => {
        setModal(!modal);
    };

    const bmi = bmiConvertor(currentWeightInKg, height);
    const bmiLabel = bmiLabelGenerator(parseInt(bmi));
    const delta = checkAge(mAge) - age;
    
  return (
    <>
<div class="pt-30 ptm-0 pb-30">
    <div class="checkout-container">
      <div class="fw-bolder mxm-24"><div class="align-center my-25 fs-34 mfs-24 text-center">Personal Summary <span class="non-break">Based on Your Answers</span></div></div>
        <div class="top-three-boxes">
            <div class="bg1 current-mbi text-primary" data-aos="fade-up">
                <div class="d-flex align-center mb-10">
                    <span class="fw-bold">Your Current BMI</span>
                    <span class="ms-auto">
                        <button id="myBtn" onClick={handleModal}>
                            <div class="info-icon"></div>
                        </button>
                    </span>
                </div>
                <h2><span class="count">{bmi}</span> BMI </h2>
                <div class="progress-bar2">
                    <div class="mark"><img src={img3} alt="" /></div>
                </div>
                <div class="d-flex mt-10"> <span>Underweight</span> <span class="ms-auto">Obese</span> </div>
                <div class="fw-bold fs-18 mt-20 mb-5 theme-color">{bmiLabel}</div>
                <div class="fs-12">Your BMI came calculated in the bottom 20% of comparable individuals.</div>
            </div>
            <div class="d-flex recommended-items mt-20" data-aos="fade-up">
                <div class="recommended-item bg1 current-mbi text-primary"> <span class="recommended-label d-none"> AI Confidence: High </span>
                    <div class="d-flex mb-20"> <i class="checkout-icon">
                        <img class="mh-36 mw-36" src={fire} />
                        </i>
                        <div class="mb-auto">
                            <div class="fw-bold mb-5">Your calories burned at rest</div>
                            <h3><span class="count">{bmr}</span><span class="fs-20"> calories</span> </h3>
                        </div>
                    </div>
                    <div class="water-level">
                        <div class="d-flex water-glass full"> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> </div>
                        <div class="d-flex mt-5 water-glass-emty"> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span> </div>
                    </div>
                    <div class="fs-12 mt-10"> Your calories burned at rest are slightly lower than comparable individuals.</div>
                </div>
                <div class="recommended-item bg1 current-mbi text-primary d-flex flex-col">
                    <div class="d-flex mb-20 mb-auto"> <i class="checkout-icon">
                            <img class="p-10" src={clipboardheart} />
                        </i>
                        <div class="mb-auto">
                            <div class="fw-bold mb-5">Your lifestyle type</div>
                            <h3><span class="fs-28">{walkInActiveLabel}</span></h3>
                        </div>
                    </div>
                    <div class="fs-12 mt-auto"> Based on the physical activities you suggested, your estimated caloric burn will be a bit higher than your calories burned at rest.</div>
                </div>
                <div class="recommended-item bg4 current-mbi text-primary highlighted d-flex">
                    <div class="valign-m my-a me-10 pr-10 mw-50p">
                    <div class="mb-auto theme-color mb-5"><span><span class="count fs-44">{activeBmr}</span><span class="change-text"> cal<span class="mob-d-none">ories</span></span></span></div>
                    <div class="fw-bold"> Estimates caloried used per day</div>
                    </div>

                    <div class="fs-12 mw-50p ms-auto my-a">In order to reach your target weight, you can either significantly increase your daily exercise to burn more calories, or consider restricting your calorie intake (how much you eat).</div>
                </div>
            </div>     
        </div>
        {modal &&
        <InfoPopup {...{handleModal}} />}
    </div>
</div>

            
    </>
  )
}