import React, { useEffect } from 'react';


export const Question10 = ({ history, handleChange, setFieldValue, values, setProgress, setShowProgress, setPage }) => {

  useEffect(() => {
    let percent;
    percent = (10 / 18) * 100;
    percent = percent.toFixed(2);
    setPage(10);
    setProgress(percent);
    setShowProgress(true);
  }, []);

  const clickHandler = () => {
    history('/shake/q11');
  };

  const checkedValue = (val) => {
    if (values.experienceAny.includes(val)) {
      return true;
    } else {
      return false;
    }
  };

  const handleChangeHandler = (e) => {
    const { value } = e.target;
    const isChecked = values.experienceAny.includes(value);
    let newData = []
    if (values.experienceAny.includes('none')) {
      const filtered = values.experienceAny.filter(val => val !== 'none');
      newData = [...filtered, e.target.value]
      setFieldValue('experienceAny', newData)
    } else {
      if (isChecked) {
        newData = values.experienceAny.filter((val) => val !== value);
      } else {
        newData = [...values.experienceAny, value];
      }
      setFieldValue('experienceAny', newData)
    }
    if(newData.length > 2) {
      history('/shake/q11');
    }
  };

  return (
    <>
      <section className="text-primary symptoms-content main original">
        <div className="body-content" >
          <div className='heading'>
            <div className="text-primary title-font mx-auto"> Do you experience any of the following? </div>
            <div className='text-center title-font fs-20 text-lgt-grey mt-20'>Pick from 1 to 3</div>
          </div>
          <div className="multi-select-items d-flex flex-wrap mx-auto">
            <div className="item">
              <input type="checkbox" value="afternoon crashes" name="experienceAny" id="control_01" checked={checkedValue('afternoon crashes')} onChange={(e) => handleChangeHandler(e)} />
              <label className="d-flex align-center p-5" for="control_01">
                <div > <span> Afternoon crashes</span> </div>
              </label>
            </div>
            <div className="item">
              <input type="checkbox" value="bloating" name="experienceAny" id="control_02" checked={checkedValue('bloating')} onChange={(e) => handleChangeHandler(e)} />
              <label className="d-flex align-center p-5" for="control_02">
                <div > <span> Bloating </span> </div>
              </label>
            </div>
            <div className="item">
              <input type="checkbox" value="fatigue" name="experienceAny" id="control_03" checked={checkedValue('fatigue')} onChange={(e) => handleChangeHandler(e)} />
              <label className="d-flex align-center p-5" for="control_03">
                <div > <span> Fatigue </span> </div>
              </label>
            </div>
            <div className="item">
              <input type="checkbox" value="puffiness" name="experienceAny" id="control_04" checked={checkedValue('puffiness')} onChange={(e) => handleChangeHandler(e)} />
              <label className="d-flex align-center p-5" for="control_04">
                <div > <span> Puffiness</span> </div>
              </label>
            </div>
            <div className="item">
              <input type="checkbox" value="headaches" name="experienceAny" id="control_05" checked={checkedValue('headaches')} onChange={(e) => handleChangeHandler(e)} />
              <label className="d-flex align-center p-5" for="control_05">
                <div > <span> Headaches</span> </div>
              </label>
            </div>
            <div className="item">
              <input type="checkbox" value="poor sleep" name="experienceAny" id="control_06" checked={checkedValue('poor sleep')} onChange={(e) => handleChangeHandler(e)} />
              <label className="d-flex align-center p-5" for="control_06">
                <div > <span> Poor sleep </span> </div>
              </label>
            </div>
            <div className="item">
              <input type="checkbox" value="stiffness" name="experienceAny" id="control_07" checked={checkedValue('stiffness')} onChange={(e) => handleChangeHandler(e)} />
              <label className="d-flex align-center p-5" for="control_07">
                <div > <span> Stiffness </span> </div>
              </label>
            </div>
            <div className="item fallback">
              <input type="checkbox" value="none" name="experienceAny" id="control_09" className="d-none" checked={checkedValue('none')} onChange={() => { setFieldValue('experienceAny', ['none']); history('/app/q11') }} />
              <label className="d-flex align-center" for="control_09">
                <div > <span>None of the above </span> </div>
              </label>
            </div>
          </div>
          <div className="text-center continue-btn mt-20">
            <button
              type='button'
              style={{ height: 'auto' }}
              className="bg-secondary button text-white fw-bold fs-16 w-30"
              title="Continue"
              onClick={clickHandler}
            > Continue
            </button>
          </div>
        </div>
      </section>
    </>
  )
}
