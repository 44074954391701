import React from "react";

const CentimeterField = ({
    handleBlur,
    values,
    errors,
    touched,
    handleFieldChange
}) => (
    <div className='d-flex'>
        <input type="number" className="form-field" name='cm' onChange={(e) => handleFieldChange(e, 3)} value={values.cm} onBlur={handleBlur} placeholder='cm.' />
        {errors?.cm && touched?.cm ? <div className='error mt-10 fs-13'>{errors?.cm}</div> : <></>}
    </div>
);

export default CentimeterField;