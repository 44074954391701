import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import * as Yup from 'yup';
import { Formik } from "formik";
import { Question0 } from '../../components/quiz0/Question0';
import { Question1 } from '../../components/quiz0/Question1';
import { Question2 } from '../../components/quiz0/Question2';
import { Question3 } from '../../components/quiz0/Question3';
import { Question4 } from '../../components/quiz0/Question4';
import { Question5 } from '../../components/quiz0/Question5';
import { Question6 } from '../../components/quiz0/Question6';
import { Question7 } from '../../components/quiz0/Question7';
import { Question8 } from '../../components/quiz0/Question8';
import { Question9 } from '../../components/quiz0/Question9';
import { Question10 } from '../../components/quiz0/Question10';
import { Question11 } from '../../components/quiz0/Question11';
import { Question12 } from '../../components/quiz0/Question12';
import { Question13 } from '../../components/quiz0/Question13';
import { Question14 } from '../../components/quiz0/Question14';
import { Question15 } from '../../components/quiz0/Question15';
import { Question16 } from '../../components/quiz0/Question16';
import { Question17 } from '../../components/quiz0/Question17';
import { Question18 } from '../../components/quiz0/Question18';


const Quiz0 = ({ history, setShowProgress, setProgress, setPage }) => {
    const [heightIncm, setHeightIncm] = useState(false);
    // const [weightInKg, setWeightInKg] = useState(false);
    // const [targetWeightInKg, setTargetWeightInKg] = useState(false);
    // let weightInKg = false;

    // const dateregex = /(0\d{1}|1[0-2])\s\/\s([0-2]\d{1}|3[0-1])\s\/\s(19|20)\d{2}/;g;


    const formikProps = {
        validateOnMount: true,
        validateOnChange: true,
        // validateOnBlur: true,
        enableReinitialize: true,
        initialValues: {
            gender: '',
            termsAndCondition: false,
            goal: '',
            physicalHealth: '',
            feelAfterEat: '',
            breakfastTaken: '',
            lunchTaken: '',
            dinnerTaken: '',
            foodLike: '',
            exercise: '',
            jobLike: '',
            experienceAny: [],
            dailyWater: '',
            age: '',
            feet: '',
            inches: '',
            cm: '',
            height: '',
            currentWeight: '',
            currentWeightKg: '',
            currentWeightLb: '',
            targetWeight: '',
            targetWeightKg: '',
            targetWeightLb: '',
            name: '',
            dob: '',
            email: '',
            weightInKg: false,
            targetWeightInKg: false
        },
        onSubmit: (values) => {
            console.log('values', values);
        }
    };

    const validate = (values) => {
        const errors = {};

        // Define schema rules
        const schemaRules = {
            email: Yup.string()
                .required('Email is required!')
                .email('Invalid email address')
                .matches(/^(?!.*[.]con$).*$/, {
                    excludeEmptyString: true,
                    message: 'Must be a valid email address.',
                }),
            age: Yup.string()
                .matches(/^\d+$/, 'Age must be a valid number')
                .test('is-valid-age', 'Age must be greater than or equal to 18', value => {
                    const ageNumber = parseInt(value, 10);
                    return !isNaN(ageNumber) && ageNumber >= 18;
                })
                .required('Age is required'),
            feet: Yup.string()
                .required('Add feet')
                .matches(/^\d+$/, 'Feet must be a valid number')
                .test('is-valid-feet', 'Feet should be 8 max', value => {
                    const feetNumber = parseInt(value, 10);
                    return !isNaN(feetNumber) && feetNumber <= 8;
                }),
            inches: Yup.string()
                .test('is-valid-number', 'Inches should be 11 max', value => {
                    if (!value) return true; // Allow empty field
                    const numberValue = parseFloat(value);
                    return !isNaN(numberValue) && numberValue <= 11;
                }),
            currentWeightKg: Yup.string()
                .test('current-weight-kg', 'Weight must be between 40 and 300 kg', (value) => {
                    const numberValue = parseInt(value, 10);
                    return (numberValue >= 40 && numberValue <= 300);
                })
                .required('Weight input is required'),
            currentWeightLb: Yup.string()
                .test('current-weight-lb', 'Weight must be between 88 and 660 lbs', (value) => {
                    const numberValue = parseInt(value, 10);
                    return (numberValue >= 88 && numberValue <= 660);
                })
                .required('Weight input is required'),
            targetWeightKg: Yup.string()
                .test('target-weight-kg', 'Weight must be between 40 and 300 kg', (value) => {
                    const numberValue = parseInt(value, 10);
                    return (numberValue >= 40 && numberValue <= 300);
                })
                .test('target-weight-kg-comparison', 'Goal weight must be less than current weight', function (value) {
                    const numberValue = parseInt(value, 10);
                    const currentWeightKg = parseInt(this.parent.currentWeightKg, 10);
                    return !isNaN(numberValue) && numberValue < currentWeightKg;
                })
                .required('Weight input is required'),
            targetWeightLb: Yup.string()
                .test('target-weight-lb', 'Weight must be between 88 and 660 lbs', (value) => {
                    const numberValue = parseInt(value, 10);
                    return (numberValue >= 88 && numberValue <= 660);
                })
                .test('target-weight-lb-comparison', 'Goal weight must be less than current weight', function (value) {
                    const numberValue = parseInt(value, 10);
                    const currentWeightLb = parseInt(this.parent.currentWeightLb, 10);
                    return !isNaN(numberValue) && numberValue < currentWeightLb;
                })
                .required('Weight input is required'),
            // targetWeight: Yup.string()
            //     .transform(value => parseFloat(value)) // Convert string to number
            //     .when(['targetWeightInKg'], {
            //         is: true,
            //         then: Yup.string()
            //             .required('Weight input is required')
            //             .test('target-weight-kg', 'Weight must be between 40 and 300 kg', (value) => {
            //                 console.log('value type then', typeof value);
            //                 const numberValue = parseInt(value, 10);
            //                 return (numberValue >= 40 && numberValue <= 300);
            //             }),
            //         otherwise: Yup.string()
            //             .required('Weight input is required')
            //             .test('target-weight-lb', 'Weight must be between 88 and 660 lbs', (value) => {
            //                 console.log('value type other', typeof value);
            //                 const numberValue = parseInt(value, 10);
            //                 return (numberValue >= 88 && numberValue <= 660);
            //             }),
            //     }),
            weightInKg: Yup.boolean(),
            targetWeightInKg: Yup.boolean(),
        };

        const schema = Yup.object().shape(schemaRules);

        try {
            // Validate the values against the schema
            schema.validateSync(values, { abortEarly: false });
        } catch (err) {
            if (err.inner && Array.isArray(err.inner)) {
                // Map validation errors to an object
                err.inner.forEach(error => {
                    if (error.path) {
                        errors[error.path] = error.message;
                    }
                });
            } else {
                // Log unexpected error formats
                console.error('Unexpected error format:', err);
            }
        }

        return errors;
    };

    return (
        <Formik {...formikProps} validate={validate}>
            {({ values, handleChange, handleSubmit, errors, setFieldValue, setFieldError, touched, handleBlur }) => (
                <form onSubmit={handleSubmit} className="magicPill">
                    <Routes>
                        <Route path='/q0' element={<Question0 {...{ history, handleChange, values, setFieldValue, setShowProgress }} />} />
                        <Route path='/q1' element={<Question1 {...{ history, handleChange, values, setFieldValue, setShowProgress, setProgress, setPage }} />} />
                        <Route path='/q2' element={<Question2 {...{ history, handleChange, values, setFieldValue, errors, setShowProgress, setProgress, setPage }} />} />
                        <Route path='/q3' element={<Question3 {...{ history, handleChange, values, setFieldValue, setShowProgress, setProgress, setPage }} />} />
                        <Route path='/q4' element={<Question4 {...{ history, handleChange, values, setFieldValue, setShowProgress, setProgress, setPage }} />} />
                        <Route path='/q5' element={<Question5 {...{ history, handleChange, values, setFieldValue, setShowProgress, setProgress, setPage }} />} />
                        <Route path='/q6' element={<Question6 {...{ history, handleChange, values, setFieldValue, setShowProgress, setProgress, setPage }} />} />
                        <Route path='/q7' element={<Question7 {...{ history, handleChange, values, setFieldValue, errors, setFieldError, heightIncm, setHeightIncm, setShowProgress, setProgress, setPage }} />} />
                        <Route path='/q8' element={<Question8 {...{ history, handleChange, values, setFieldValue, setShowProgress, setProgress, setPage }} />} />
                        <Route path='/q9' element={<Question9 {...{ history, handleChange, values, setFieldValue, setShowProgress, setProgress, setPage }} />} />
                        <Route path='/q10' element={<Question10 {...{ history, handleChange, values, setFieldValue, setShowProgress, setProgress, setPage }} />} />
                        <Route path='/q11' element={<Question11 {...{ history, handleChange, values, setFieldValue, setShowProgress, setProgress, setPage }} />} />
                        <Route path='/q12' element={<Question12 {...{ history, setShowProgress, values, setProgress, setPage }} />} />
                        <Route path='/q13' element={<Question13 {...{ history, handleChange, values, errors, handleBlur, touched, setShowProgress, setProgress, setPage }} />} />
                        <Route path='/q14' element={<Question14 {...{ history, handleChange, values, setFieldValue, errors, handleBlur, touched, setShowProgress, setProgress, setPage, heightIncm, setHeightIncm }} />} />
                        <Route path='/q15' element={<Question15 {...{ history, handleChange, values, setFieldValue, errors, handleBlur, touched, setShowProgress, setProgress, setPage }} />} />
                        <Route path='/q16' element={<Question16 {...{ history, handleChange, values, setFieldValue, errors, handleBlur, touched, setShowProgress, setProgress, setPage, setFieldError }} />} />
                        <Route path='/q17' element={<Question17 {...{ history, handleChange, values, errors, handleBlur, touched, setShowProgress, setProgress, setPage, heightIncm, }} />} />
                        <Route path='/q18' element={<Question18 {...{ history, handleChange, values, setShowProgress, setProgress, setPage }} />} />
                    </Routes>
                </form>
            )}
        </Formik>
    );
};
export default React.memo(Quiz0);