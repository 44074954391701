import React from 'react';
// import logo from './assets/img/logo.svg';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Recipe from '../../assets/img/updated/image75.webp';
import beforeAfter1 from '../../assets/img/updated/Q18-Before:After-dekstop.webp';
import beforeAfter2 from '../../assets/img/updated/Q18-Before:After.dekstop-man.webp';



export const ResultSummarySlick = () => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    };
    return (
      <Slider {...settings}>
          <div>
            <div class="reivews-item">
                <img src={Recipe} className='img-fluid' alt='Recipe' />
            </div>
          </div>
          <div>
            <div class="reivews-item">
                <img src={beforeAfter2} className='img-fluid' alt='BMRecipeI' />
            </div>
          </div>
          <div>
            <div class="reivews-item">
                <img src={beforeAfter1} className='img-fluid' alt='Recipe' />
            </div>
          </div>
      </Slider>
    )
}