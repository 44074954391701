import React from "react";

const ErrorPage = () => (
  <div>
    <div className="cms container terms-page text-primary pb-50 body-padding">
      <div className="fs-28 fw-bold mb-50 mt-50"> Payment Server Upgrading</div>
      <div>
        <h2 className="fs-18 fw-bold">We are experiencing a temporary payment server outage.</h2>
        <p>We are currently experiencing a temporary server outage that is preventing payments from being processed. Our technical team is working diligently to resolve this issue and restore full functionality to our website as soon as possible.</p>
        <p>No payment details have been stored, and please rest assured that no charges have been made at this time. We apologize for any inconvenience this may have caused and appreciate your patience.</p>
        <p>We will reach out once the issue is resolved and payments can be processed once again.</p>
        <p>Thank you for your understanding.</p>
        <p className="mt-20"><i>- The Biome Secret Team</i></p>
      </div>
    </div>
  </div>
);
export default React.memo(ErrorPage);