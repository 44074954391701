import React, { useEffect, useRef } from 'react';
import img1 from '../../assets/img/updated/shake/Rectangle-314.webp';
import img2 from '../../assets/img/updated/shake/Rectangle-315.webp';
import { dateTimeFormate } from '../../common/utils/utils';


export const EmailField = ({ history, handleChange, errors, handleBlur, touched, setProgress, setShowProgress, setPage, values, heightIncm, weightInKg, targetWeightInKg }) => {

    const emailInputRef = useRef(null);

    useEffect(() => {
        let percent;
        percent = (17 / 18) * 100;
        percent = percent.toFixed(2);
        setPage(17);
        setProgress(percent);
        setShowProgress(true);
        if (emailInputRef.current) {
            emailInputRef.current.focus();
        }
    }, []);

    const formattedDateTime = dateTimeFormate();

    function replaceEmptyValuesWithUnderscore(obj) {
        const newObj = {};
        for (const key in obj) {
          if (obj[key] === '') {
            newObj[key] = '_';
          } else {
            newObj[key] = obj[key];
          }
        }
      
        return newObj;
      }

    const submitData = async () => {
        try {
            // sheet url
            const updatedObject = replaceEmptyValuesWithUnderscore(values);
            updatedObject.timeStamp = formattedDateTime;
            updatedObject.experienceAny = values.experienceAny.join(', ');
            const res = await fetch('https://sheet.best/api/sheets/9e6935fe-dcf8-4c5e-bb16-2d23bfbc2300/tabs/QuizAnswers-Shake2', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(updatedObject)
            });
        } catch (error) {
            console.log(error)
        }
    }

    const clickHandler = () => {
        values.heightIncm = heightIncm;
        submitData();
        localStorage.setItem('magicPills', JSON.stringify(values));
        setTimeout(() => {
            history('/shake-new/summary1');
        }, 300);
    };

    return (
        <>
            <section className="text-white email-page-content main">
                <div className="body-content">
                    <div className='email-main-content d-flex align-center'>
                        <img src={img1} className='img-fluid img1' alt='Email page' width="221" height="434" />
                        <div className='form-content mx-auto'>
                            <div className="heading mb-20 title-font mx-auto"> Best contact email </div>
                            <input type="email" ref={emailInputRef} className='form-field text-center' placeholder='Enter your email address' value={values.email} name="email" onChange={handleChange} onBlur={handleBlur} />
                            {errors?.email && touched?.email ? <div className='error mt-10 fs-13'>{errors?.email}</div> : <></>}
                            <div className='text-center  fs-13 mt-40 mb-40'>
                                We don’t send spam or share email addresses.
                                We respect your privacy.
                            </div>
                            <button
                                onClick={clickHandler}
                                disabled={errors.email}
                                className='button bg-secondary text-white w-48 mx-auto d-flex align-center mt-30'
                            >
                                <span>
                                    Continue
                                </span>
                            </button>
                        </div>
                        <img src={img2} className='img-fluid img2' alt='Email page' width="221" height="434" />
                    </div>
                </div>
            </section>
        </>
    )
}
