import React from "react";
import { Footer } from '../common/components/Footer';

const Tos = () => (
<div>
    <div class="cms container terms-page text-primary pb-50 body-padding">
    <div class="fs-28 fw-bold mb-50 mt-50"> Terms of Service</div>
    <h2 class="fs-18 fw-bold">1. Introduction</h2>
        <p>Welcome to Biome Secret, an e-commerce store that offers personalized meal supplements based on users' body types and demographics information. By accessing and using our website, you agree to be bound by these Terms of Service. If you do not agree with any of these terms, please do not use our website.</p>
        <p>Please note that Biome Secret may update these Terms of Service from time to time. Any changes will be effective immediately upon posting on the website. Your continued use of the website after any such changes constitutes your acceptance of the new Terms of Service. Please check this page periodically for updates.</p>
        <p>If you have any questions about these Terms of Service, please contact us at support@biomesecret.com.</p>
    
    <div>
        <h2 class="fs-18 fw-bold pt-20">2. Use of the Website</h2>
        <p>By using our website, you agree to the following terms:</p>
        <ol>
            <li>You may not use the website for any illegal or unauthorized purpose.</li>
            <li>The information you provide on the website must be accurate and complete.</li>
            <li>We reserve the right to modify, suspend, or discontinue any part of the website at any time without notice.</li>
        </ol>
    </div>
    <div>
        <h2 class="fs-18 fw-bold pt-20">3. Purchases and Payments</h2>
        <h3 class="fs-14">3.1 Product Descriptions</h3>
        <p>We strive to provide accurate descriptions of our meal supplements on the website. However, we do not guarantee that the descriptions are completely accurate, current, or error-free. If you find that a product is not as described, please contact us immediately.</p>
        <h3 class="fs-14">3.2 Pricing and Payment Terms</h3>
        <p>All prices are in United States Dollars (USD) and are subject to change without notice. We accept payments through PayPal and Stripe for credit card transactions. By making a purchase through our website, you agree to be bound by the payment provider's terms and conditions. You agree to pay the full price of the product, including any applicable taxes and shipping fees. We reserve the right to cancel any order if we are unable to verify payment information or if we suspect fraud.</p>
        <h3 class="fs-14">3.3 Refund Policy</h3>
        <p>We want you to be completely satisfied with your purchase. If you are not satisfied with your meal supplement, you may request a refund within 30 days of purchase. To be eligible for a refund, you must have followed the instructions for the dietary supplement and be able to provide evidence that you did not notice any difference. Refunds will be issued to the original payment method. You can read our full money back policy <a class="theme-color" href="money-back">here</a>.</p>
    </div>
    <div>
        <h2 class="fs-18 fw-bold pt-20">4. Intellectual Property</h2>
        <p>All content on the website, including but not limited to text, graphics, logos, images, and software, is the property of Biome Secret or its content suppliers and is protected by copyright and other intellectual property laws. You may not use any content on the website without our prior written consent.</p>
        <h3 class="fs-14">4.1 Trademarks</h3>
        <p>The Biome Secret name and logo are trademarks of Biome Secret. You may not use our trademarks without our prior written consent.</p>
        <h3 class="fs-14">4.2 User-Generated Content</h3>
        <p>There is no user-generated content on the website.</p>
        <h3 class="fs-14">4.3 DMCA Policy</h3>
        <p>If you believe that any content on the website infringes your copyright, you may submit a notice of claimed infringement to our designated agent in accordance with the Digital Millennium Copyright Act. Please see our DMCA policy for more information.</p>
    </div>
    <div>
        <h2 class="fs-18 fw-bold pt-20">5. Liability</h2>
        <p>The information and content on the website are provided "as is" and without warranty of any kind, whether express or implied. We make no representations or warranties of any kind, whether express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.</p>
        <p>In no event will Biome Secret be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of the website.</p>
        <p>You agree to indemnify and hold Biome Secret harmless from any claims, damages, losses, liabilities, and all costs and expenses of defense, including but not limited to attorneys' fees, resulting directly or indirectly from a claim by a third party that arises in connection with your use of the website.</p>
    </div>
    <div>
        <h2 class="fs-18 fw-bold pt-20">6. Refunds</h2>
        <p>We want you to be completely satisfied with your purchase. If for any reason you are not satisfied with your purchase, we will consider a refund if you contact us within 30 days of your purchase date and provide proof that you followed the correct instructions for the dietary supplement. We reserve the right to deny a refund request if we determine, in our sole discretion, that the request is fraudulent or excessive.</p>
        <p>Refunds will be issued in the same manner in which the payment was made. If the payment was made through PayPal, the refund will be issued to the PayPal account used to make the payment. If the payment was made by credit card, the refund will be issued to the same credit card used to make the payment.</p>
        <p>We do not provide refunds for any shipping and handling charges or for any other charges related to the original purchase.</p>
    </div>
    <div>
        <h2 class="fs-18 fw-bold pt-20">7. Termination</h2>
        <p>Biome Secret reserves the right to terminate your access to the website at any time without notice, for any reason, including without limitation, breach of these Terms of Service or any other policies or guidelines that we have posted on the website. The provisions of these Terms of Service that by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.</p>
    </div>
    <div>
        <h2 class="fs-18 fw-bold pt-20">8. Governing Law</h2>
        <p>These Terms of Service shall be governed by and construed in accordance with the laws of the United Arab Emirates, without giving effect to any principles of conflicts of law.</p>
        <p>Any legal action or proceeding arising under these Terms of Service shall be brought exclusively in the federal or state courts located in the United Arab Emirates and you consent to the jurisdiction of such courts.</p>
    </div>    
    </div>
    <Footer />
</div>

);
export default React.memo(Tos);