import React, { useState, useEffect } from 'react';
import landingBgDesktop from '../../assets/img/updated/landingBgDesktop.webp';
import landingBgMobile from '../../assets/img/updated/landingBgMobile.webp';
import landingAppImg from '../../assets/img/updated/q0-main-image.webp';
import starReviews from '../../assets/img/updated/reviews.png';
import brands from '../../assets/img/updated/brands.png';
import logo from '../../assets/img/logo.svg';


export const Question0 = ({ history, handleChange, values, setFieldValue, setShowProgress }) => {
  const [alert, setAlert] = useState(false);
  useEffect(() => {
    setShowProgress(false);
  }, []);

  const handleChangeHandler = (e) => {
    if (values.termsAndCondition) {
      setTimeout(() => {
        history('/app/q1');
      }, 300);
    } else {
      setAlert(true);
    }
    handleChange(e);
  };
  const checkboxHandler = (e) => {
    setAlert(false);
    handleChange(e);
    if (values.gender && e.target.checked) {
      setTimeout(() => {
        history('/app/q1');
      }, 300);
    }
  };

  return (
    <>
      <section className="step-goal full-container">
        <div className="mx-auto d-flex full-container-content">
          <div className="left-content bg-light-grey">
            <div class="nav-logo"> <a href="/" aria-label="" ><img src={logo} alt="logo" width={192} /></a></div>
            <div className="gender-content">
              <h1 className="heading-1 secondary-font">Biome Secret App: Your 24/7 Personal Nutritionist for Effective Weight Loss</h1>
              {/* <p>Biome Secret is a personalized weight management solution, delivered right to your door.</p> */}
              <div className='tagline'>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" fill="#5F815C" />
                  </svg>
                  Keep Your Favorite Foods
                </div>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" fill="#5F815C" />
                  </svg>
                  Adjust to Your Habits
                </div>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" fill="#5F815C" />
                  </svg>
                  Tailor to Your Daily Routine
                </div>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" fill="#5F815C" />
                  </svg>
                  Long-Lasting Results
                </div>
              </div>
              <div className="heading-2 secondary-font">Select Your Gender</div>
              <div className="single-select-items d-flex">
                <div className="item male">
                  <input type="radio" value="male" name="gender" id="gender_1" className={values.gender === 'male' && 'checked'} onChange={(e) => handleChangeHandler(e)} />
                  <label className="d-flex align-center" for="gender_1">
                    <div > <span className="fw-bold"> Male </span> </div>
                    {/* <div className="goal-img-frame"> <img src={male} /> </div> */}
                  </label>
                </div>
                <div className="item female">
                  <input type="radio" value="female" name="gender" id="gender_2" className={values.gender === 'female' && 'checked'} onChange={(e) => handleChangeHandler(e)} />
                  <label className="d-flex align-center" for="gender_2">
                    <div > <span className="fw-bold"> Female </span> </div>
                    {/* <div className="goal-img-frame"> <img src={female} /> </div> */}
                  </label>
                </div>
              </div>
              <div className="terms-condition">
                <div className="checkbox-content mb-20 fs-12 color2">
                  <input type="checkbox" value={values.termsAndCondition} checked={values.termsAndCondition} name="termsAndCondition" id="control_04" onChange={(e) => checkboxHandler(e)} />
                  <label for="control_04"> By continuing, I agree with <a target="_blank" href="" className="color2">Terms of service</a>, <a target="_blank" href="" className="color2">Privacy policy</a>, <a target="_blank" href="" className="color2">Refund policy</a>, <a target="_blank" href="" className="color2">Subscription Terms</a>, <a target="_blank" href="" className="color2">Cookie policy</a> </label>
                </div>
                {alert &&
                  <div className="alert-box mb-20"> <div className="info-icon white me-10"></div> To continue, please accept our terms and policies </div>
                }
                {/* <div className="checkbox-content mb-20 fs-12 color2 d-none">
                    <input type="checkbox" value="3" name="terms" id="control_05" />
                    <label for="control_05"> I would like to receive updates about products, services, and special offers from Biome Secret via email </label>
                  </div> */}
                {/* <div className="fs-12 color2"> We recommend that you consult with your physician before beginning any treatment. </div> */}
                <div className='text-center pt-40'>
                  <div className='fs-16 fw-600 mb-30'>The Only Weight Loss App Using Clinically Backed Studies</div>
                  <img src={starReviews} className='img-fluid mb-10' alt='Biomesecret' />
                  <img src={brands} className='img-fluid' alt='Biomesecret' />

                </div>

              </div>
            </div>
          </div>

          <div className="right-content text-center">
            <div class="nav-logo"> <a href="/" aria-label="" ><img src={logo} alt="logo" width={192} /></a></div>
            <div className='ticker-content'>
              <div className=''>
                <img src={landingBgDesktop} className='img-fluid desktop' alt='Biomesecret' />
                <img src={landingBgDesktop} className='img-fluid desktop' alt='Biomesecret' />
              </div>
              <div className=''>
                <img src={landingBgMobile} className='img-fluid mobile' alt='Biomesecret' />
                <img src={landingBgMobile} className='img-fluid mobile' alt='Biomesecret' />
              </div>
              <img src={landingAppImg} className='img-fluid appImg' alt='Biomesecret' />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
