import React, { useEffect } from 'react';
import summary9b from '../../assets/img/updated/shake-new/q9.webp';



export const Summary9 = ({ history, values, handleChange, setProgress, setShowProgress, setPage }) => {

    useEffect(() => {
        let percent;
        percent = (18 / 18) * 100;
        percent = percent.toFixed(2);
        setPage(18);
        setProgress(percent);
        setShowProgress(true);
    }, []);

    const clickHandler = () => {
        setTimeout(() => {
            history('/shake-new/summary10');
        }, 300);
    };

    return (
        <section className="text-primary main summary-page-content">
            <div className='shake-summary-page'>
                <div className='shake-summary-page-steps text-white'>
                    <span className='active'></span>
                    <span className='active'></span>
                    <span className='active'></span>
                    <span className='active'></span>
                    <span className='active'></span>
                    <span className='active'></span>
                    <span className='active'></span>
                    <span className='active'></span>
                    <span className='active'></span>                    
                    <span></span>
                    <span></span>
                </div>
                <div className='shake-summary-page-content pt-0' style={{'background-color': '#EFEDED'}}>   
                    <div className='weight-loss-journey-new'>
                        <div className='summary-5bg'>
                            <img src={summary9b} className='img-fluid br-4t' />                            
                        </div>                        
                        <div className="mt-30">
                            <button
                                type='button'
                                className="button bg-secondary text-white w-100 mx-auto text-center mb-16 w-100"
                                title="Continue"
                                // disabled={values.targetWeightInKg ? errors.targetWeightKg : errors.targetWeightLb}
                                onClick={clickHandler}
                            > Continue
                            </button>
                            <div className='f-italic fs-10 color2'>
                                * Results may vary due personal features
                            </div>
                        </div>
                    </div>                 
                    
                    

                </div>
            </div>
        </section>
    )  
};
