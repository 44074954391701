import React from 'react';
import { Slick } from "../../common/components/Slick";


export const Reviews = ({Name}) => {
  return (
    <>
      <div class="reivews-section">
        <section class="text-white container3 mx-auto">
          <div class="heading-1 text-center mb-30">And Even More</div>
          <p class="text-center rating">
            <span>Weight-management complete meal shake</span> 
            <span>|</span>
            <span class="d-inline-flex">
              <div class="star-icon"></div>
              <div class="star-icon"></div>
              <div class="star-icon"></div>
              <div class="star-icon"></div>
              <div class="star-icon"></div>
            </span>                        
            <span class="me-5">62k</span>
            <span class="user2-icon me-0"></span>              
          </p>
          <div class="reivews-slider text-white">
            <Slick />
          </div>
        </section>
      </div>
        
    </>
  )
}
