import React, { useEffect, useState } from 'react';
import poor from '../../assets/img/updated/app-quiz/physical-health/poor.webp';
import couldBetter from '../../assets/img/updated/app-quiz/physical-health/better.webp';
import average from '../../assets/img/updated/app-quiz/physical-health/average.webp';
import prettyGood from '../../assets/img/updated/app-quiz/physical-health/pretty-good.webp';
import athlete from '../../assets/img/updated/app-quiz/physical-health/athlete.webp';



export const Question2 = ({ history, values, setFieldValue, errors, handleChange, setProgress, setShowProgress, setPage }) => {

    const [event, setEvent] = useState(false);

    useEffect(() => {
        let percent;
        percent = (2 / 18) * 100;
        percent = percent.toFixed(2);
        setPage(2);
        setProgress(percent);
        setShowProgress(true);
    }, []);

    const handleChangeHandler = (value) => {
        setFieldValue('physicalHealth', value);
        setTimeout(() => {
            history('/shake/q3');
        }, 300);
    };
    return (
        <>
            <section className="text-primary physical-health-content main">
                <div className="body-content">
                    <div className="heading text-primary mb-20 title-font mx-auto"> How would you rate your overall physical health? </div>
                    <div className='single-choose-question max-308 mx-auto'>
                        <button type='button' className='button bg-theme text-white w-100 d-flex fw-bold align-center mb-16' onClick={() => handleChangeHandler('poor')}>
                            {/* <img src={poor} className='question-icon' width='23' height='27' /> */}
                            <span>
                                Poor
                            </span>
                            {/* <img src={poor} className='question-icon' width='23' height='27' /> */}
                        </button>
                        <button type='button' className='button bg-theme text-white w-100 d-flex fw-bold align-center mb-16' onClick={() => handleChangeHandler('could be better')}>
                            {/* <img src={couldBetter} className='question-icon' width='23' height='27' /> */}
                            <span>
                                Could be better
                            </span>
                            {/* <img src={couldBetter} className='question-icon' width='23' height='27' /> */}
                        </button>
                        <button type='button' className='button bg-theme text-white w-100 d-flex fw-bold align-center mb-16' onClick={() => handleChangeHandler('average')}>
                            {/* <img src={average} className='question-icon' width='23' height='33' /> */}
                            <span>
                                Average
                            </span>
                            {/* <img src={average} className='question-icon' width='23' height='33' /> */}
                        </button>
                        <button type='button' className='button bg-theme text-white w-100 d-flex fw-bold align-center mb-16' onClick={() => handleChangeHandler('pretty good')}>
                            {/* <img src={prettyGood} className='question-icon' width='27' height='27' /> */}
                            <span>
                                Pretty good
                            </span>
                            {/* <img src={prettyGood} className='question-icon' width='27' height='27' /> */}
                        </button>
                        <button type='button' className='button bg-theme text-white w-100 d-flex fw-bold align-center mb-16' onClick={() => handleChangeHandler('olympic athlete')}>
                            {/* <img src={athlete} className='question-icon' width='23' height='27' /> */}
                            <span>
                                Olympic Athlete
                            </span>
                            {/* <img src={athlete} className='question-icon' width='23' height='27' /> */}
                        </button>
                    </div>
                </div>
            </section>
        </>
    )
}
