import React, { useEffect } from 'react';

export const Question9 = ({ history, handleChange, values, setFieldValue, setProgress, setShowProgress, setPage }) => {

  useEffect(() => {
    let percent;
    percent = (9 / 18) * 100;
    percent = percent.toFixed(2);
    setPage(9);
    setProgress(percent);
    setShowProgress(true);
  }, []);

  const handleChangeHandler = (value) => {
    setFieldValue('jobLike', value);
    setTimeout(() => {
      history('/shake/q10');
    }, 300);
  };

  return (
    <>
      <section className="text-primary job-content main">
        <div className="body-content" >
          <div className="heading text-primary mb-20 title-font mx-auto"> What’s your job like?</div>
          <div className='single-choose-question max-308 mx-auto'>
            <button type='button' className='button bg-theme text-white w-100 d-flex fw-bold align-center mb-16' onClick={() => handleChangeHandler('sitting')}>
              <span>
                Mostly Sitting
              </span>
            </button>
            <button type='button' className='button bg-theme text-white w-100 d-flex fw-bold align-center mb-16' onClick={() => handleChangeHandler('active')}>
              <span>
                Active
              </span>
            </button>
            <button type='button' className='button bg-theme text-white w-100 d-flex fw-bold align-center mb-16' onClick={() => handleChangeHandler('mixed')}>
              <span>
                Mixed
              </span>
            </button>
            <button type='button' className='button bg-theme text-white w-100 d-flex fw-bold align-center mb-16' onClick={() => handleChangeHandler('retired')}>
              <span>
                I’m retired
              </span>
            </button>
          </div>
        </div>
      </section>
    </>
  )
}
