import React from 'react';

export const Footer = () => {
  return (
    <footer className="text-center">                
        <p className="pb-20">
            <a href="">Contacts</a><br />
            <a href="">Privacy Policy</a><br />
            <a href="">Terms & Conditions</a>
            {/* <a target="_blank" href="mailto:support@biomesecret.com">Support</a> */}
        </p>
        <p className="text-white pb-10">© 2024 Biome.Secret All rights reserved.</p>
        
    </footer>
  )
}