import React, { useEffect } from 'react';
import loseWeight from '../../assets/img/updated/app-quiz/goal/losing-weight.webp';
import healthy from '../../assets/img/updated/app-quiz/goal/healthy.webp';
import improveHealth from '../../assets/img/updated/app-quiz/goal/improve-healthy.webp';
import guteHealth from '../../assets/img/updated/app-quiz/goal/improve-gut-health.webp';
import imgBg from '../../assets/img/updated/bg/q1-bg.webp';


export const Question1 = ({ history, setFieldValue, values, handleChange, setProgress, setShowProgress, setPage }) => {

    useEffect(() => {
        let percent;
        percent = (1 / 18) * 100;
        percent = percent.toFixed(2);
        setPage(1);
        setProgress(percent);
        setShowProgress(true);
    }, []);

    const handleChangeHandler = (value) => {
        setFieldValue('goal', value);
        setTimeout(() => {
            history(`/app/q2`);
        }, 300);
    };
    return (
        <>
            <img src={imgBg} className='img-fluid bg-img' />
            <section className="text-primary goal-content main">
                <div className="body-content">
                    <div className="heading text-primary mb-20 title-font"> What is your goal? </div>
                    <div className='single-choose-question max-308 mx-auto'>
                        <button type='button' className='button bg-theme text-white w-100 d-flex fw-bold align-center mb-16' onClick={() => handleChangeHandler('lose weight')}>
                            <img src={loseWeight} className='question-icon' width='24' height='20' />
                            <span>
                                Losing Weight
                            </span>
                        </button>
                        <button type='button' className='button bg-theme text-white w-100 d-flex fw-bold align-center mb-16' onClick={() => handleChangeHandler('keep healthy')}>
                            <img src={healthy} className='question-icon' width='24' height='20' />
                            <span>
                                Keeping body healthy
                            </span>
                        </button>
                        <button type='button' className='button bg-theme text-white w-100 d-flex fw-bold align-center mb-16' onClick={() => handleChangeHandler('improve overall health')}>
                            <img src={improveHealth} className='question-icon' width='24' height='20' />
                            <span>
                                Improve my overall health
                            </span>
                        </button>
                        <button type='button' className='button bg-theme text-white w-100 d-flex fw-bold align-center mb-16' onClick={() => handleChangeHandler('improve gut health')}>
                            <img src={guteHealth} className='question-icon' width='24' height='20' />
                            <span>
                                Improving my gut health
                            </span>
                        </button>
                    </div>
                </div>
            </section>
        </>
    )
}
