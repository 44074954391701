import React from "react";

const ChoosePlan = ({selectedOption, handleOptionChange, paymentOptionsRender}) => {
    return(
        <>
            <div class="rgt-content mx-a">
            <div class="choose-your-plan">
            <div class="fs-28 fw-bolder mb-20 ta-l-c">Choose the best plan for you</div>
                <p class="fs-14"><b>In Stock:</b> <i>Chocolate, Vanilla, Caramel</i><br></br><br></br><span class="fs-12"><i>Flavors are chosen on the order confirmation screen.</i></span></p>
                <div class="item">
                    <input type="radio" value="1month" name="selectPayment" id="1" checked={selectedOption === '1month'} onChange={handleOptionChange}/>
                    <label class="d-flex align-center" for="1">
                        <div> <span class="fs-18 fw-bold"> 1-MONTH SUPPLY </span>
                            <div class="color2 mt-5"> 100 USD </div>
                        </div>
                        <div class="ms-auto">
                            <div class="d-flex"> <span class="fs-20 fw-bold me-5">3.23</span> <span>USD</span> </div>
                            <span class="color2"> per meal </span>
                        </div>
                    </label>
                </div>
                <div class="item">
                    <input type="radio" value="3month" name="selectPayment" id="2" checked={selectedOption === '3month'} onChange={handleOptionChange} />
                    <label class="d-flex align-center" for="2">
                        <span class="most-popular">Most Popular</span>
                        <div> <span class="fs-18 fw-bold"> 2-MONTH SUPPLY </span>
                            <div class="color2 mt-5"> 150 USD</div>
                        </div>
                        <div class="ms-auto">
                            <div class="d-flex"> <span class="fs-20 fw-bold me-5">2.42</span> <span>USD</span> </div>
                            <span class="color2"> per meal </span>
                        </div>
                    </label>
                </div>
                <div class="item">
                    <input type="radio" value="6month" name="selectPayment" id="3" checked={selectedOption === '6month'} onChange={handleOptionChange} />
                    <label class="d-flex align-center" for="3">
                        <span class="monster-plan">Maximum Results Typical</span>
                        <div> <span class="fs-18 fw-bold"> 3-MONTH SUPPLY </span>
                            <div class="color2 mt-5"> 200 USD</div>
                        </div>
                        <div class="ms-auto">
                            <div class="d-flex"> <span class="fs-20 fw-bold me-5">2.15</span> <span>USD</span> </div>
                            <span class="color2"> per meal </span>
                        </div>
                    </label>
                </div>
                <div class="d-flex align-start mb-50">
                    <div class="heart-icon me-15 mt-5"></div>
                    <div class="ms-10">
                        <div class="text-primary mb-10 lh-1"> People using plan for 3 months achieve twice as many results as for 1 month </div>
                        <div class="fs-12 color2"> *According to a study by Biome Secret, 2023 </div>
                    </div>
                </div>
                <div class="choose-plan-btn">
                    <button 
                        class="theme-btn 
                        text-darkbg" 
                        id="paypal-button" 
                        title="Proceed"
                        onClick={paymentOptionsRender}
                    > Proceed
                        <div class="right-arrow"></div>
                    </button>
                </div>
                <div class="fs-12 color2 mt-50 mb-20"> By continuing, you agree that if you don't cancel at least 24 hours prior to the end of the current introductory offer period, the subscription will be automatically extended for a successive renewal period of one-month and at the full price of 100 USD. To learn more, visit our <a target="_blank" href="../subscription-policy" class="color2">Subscription Terms</a>.<br/>
                    By continuing, I agree with the <a target="_blank" href="../terms" class="color2">Terms of service</a>, <a target="_blank" href="../privacy-policy" class="color2">Privacy policy</a>, <a target="_blank" href="../refund-policy" class="color2">Refund policy</a>, <a target="_blank" href="../subscription-policy" class="color2">Subscription terms</a>, and <a target="_blank" href="../cookie-policy" class="color2">Cookie policy</a>. 
                </div>
            </div>
            </div>
        </>
    );
}
export default React.memo(ChoosePlan);