import React, { useEffect } from 'react';


export const Question11 = ({ history, values, handleChange, setFieldValue, setProgress, setShowProgress, setPage }) => {

    useEffect(() => {
        let percent;
        percent = (11 / 18) * 100;
        percent = percent.toFixed(2);
        setPage(11);
        setProgress(percent);
        setShowProgress(true);
    }, []);

    const handleChangeHandler = (value) => {
        setFieldValue('dailyWater', value);
        setTimeout(() => {
            history('/shake-new/q12');
        }, 300);
    };

    return (
        <>
            <section className="text-white water-content main">
                <div className="body-content">
                    <div className="heading mb-20 title-font h-wrap"><span className="h-before">How much water do you drink daily?</span></div>

                    <div className='single-choose-question max-308 mx-auto'>
                        <button type='button' className={`button bg-theme text-white w-100 d-flex fw-bold align-center mb-16 ${Boolean(values.dailyWater === 'less than 32 oz') ? 'checked' : ''}`} onClick={() => handleChangeHandler('less than 32 oz')}>
                            {/* <img src={oneLiter} className='question-icon' width='24' height='20' /> */}
                            <span>
                                Less than 32 oz.
                            </span>
                        </button>
                        <button type='button' className={`button bg-theme text-white w-100 d-flex fw-bold align-center mb-16 ${Boolean(values.dailyWater === '32-64 oz') ? 'checked' : ''}`} onClick={() => handleChangeHandler('32-64 oz')}>
                            {/* <img src={twoLiter} className='question-icon' width='24' height='20' /> */}
                            <span>
                                32-64 oz.
                            </span>
                        </button>
                        <button type='button' className={`button bg-theme text-white w-100 d-flex fw-bold align-center mb-16 ${Boolean(values.dailyWater === '64-96 oz') ? 'checked' : ''}`} onClick={() => handleChangeHandler('64-96 oz')}>
                            {/* <img src={threeLiters} className='question-icon' width='24' height='20' /> */}
                            <span>
                                64-96 oz.
                            </span>
                        </button>
                        <button type='button' className={`button bg-theme text-white w-100 d-flex fw-bold align-center mb-16 ${Boolean(values.dailyWater === 'more than 96 oz') ? 'checked' : ''}`} onClick={() => handleChangeHandler('more than 96 oz')}>
                            {/* <img src={fourLiters} className='question-icon' width='24' height='20' /> */}
                            <span>
                                More than 96 oz.
                            </span>
                        </button>
                    </div>
                </div>
            </section>
        </>
    )
}
