import React from 'react';
import container from '../../assets/img/Bag_NSCR.png';
import ship from '../../assets/img/icons/icon-shipping.svg';
import nl from '../../assets/img/icons/uk-icon.svg';
import haccp from '../../assets/img/icons/icon-haccp.svg';
import cgmp from '../../assets/img/icons/icon-cgmp.svg';
import chocshake from '../../assets/img/food-cutouts/chocolate.jpeg';
import piechart from '../../assets/img/pie-chart.png';
import carmshake from '../../assets/img/food-cutouts/caramel.jpeg';
import scale from '../../assets/img/icons/updown.svg';
import fast from '../../assets/img/icons/fast.svg';
import thumbsdown from '../../assets/img/icons/thumbs-down.svg';
import heartcheck from '../../assets/img/icons/heart-check.svg';
import completenutrition from '../../assets/img/icons/complete-nutrition.svg';
import health173 from '../../assets/img/icons/173-health.svg';

export const FullWorkout = ({ Name }) => {    
    return ( 
        <>

<div class="pt-30 ptm-0 pb-30">
    <div class="checkout-container">
        <div class="fs-28 mxm-24">
            <div class="fw-bolder">
                <div class="align-center my-25 fs-34 mfs-24 text-center">Nutritional Profile of <span class="non-break">Biome Secret</span></div>
            </div>
            <div class="piechart mt-10 border3 bg4 mb-30">
                <p class="change-text mt-10 text-center">Designed specifically for weight loss. <br></br>Each meal is packed with 29 vitamins and minerals.</p>
                <img class="mw-100p br-10 pic-rh mt-10" src={piechart} />
                <p class="change-text mt-10 text-center">Proudly made in the United Kingdom.</p>
                <hr>
                </hr>
                <div class="d-inline-flex fs-16 text-center my-a align-center w-100">
                    <span class="px-10 mx-a">No Soy</span>
                    <svg width="6px" height="6px" fill="var(--accentColor)" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="50" cy="50" r="50" /></svg>
                    <span class="px-10 mx-a">No Gluten</span>
                    <svg width="6px" height="6px" fill="var(--accentColor)" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="50" cy="50" r="50" /></svg>
                    <span class="px-10 mx-a">No GMO's</span>
                    <svg class="mob-d-none" width="6px" height="6px" fill="var(--accentColor)" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="50" cy="50" r="50" /></svg>
                    <span class="px-10 mx-a mob-d-none">No Hormones</span>
                    <svg width="6px" height="6px" fill="var(--accentColor)" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="50" cy="50" r="50" /></svg>
                    <span class="px-10 mx-a">No Lactose</span>
                </div>
            </div>
            <div class="fw-bolder pt-30">
                <div class="align-center my-25 fs-34 mfs-24 text-center">Natural Flavor <span class="non-break">Without Additives</span></div>
            </div>
            {/*<marquee class="my-20" behavior="scroll" direction="left" scrollamount="6">
                <div class="d-flex align-center fs-20">
                    <div class="me-20 a-self-center d-flex">
                        <span class="me-20">No Gluten</span>
                        <span class="me-20 a-self-center d-flex"> <svg width="6px" height="6px" fill="#a4a5a1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="50" cy="50" r="50" /></svg> </span>
                    </div>
                    <div class="me-20 a-self-center d-flex">
                        <span class="me-20">No Lactose</span>
                        <span class="me-20 a-self-center d-flex"> <svg width="6px" height="6px" fill="#a4a5a1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="50" cy="50" r="50" /></svg> </span>
                    </div>
                    <div class="me-20 a-self-center d-flex">
                        <span class="me-20">No GMO's</span>
                        <span class="me-20 a-self-center d-flex"> <svg width="6px" height="6px" fill="#a4a5a1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="50" cy="50" r="50" /></svg> </span>
                    </div>
                    <div class="me-20 a-self-center d-flex">
                        <span class="me-20">No Hormones</span>
                        <span class="me-20 a-self-center d-flex"> <svg width="6px" height="6px" fill="#a4a5a1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="50" cy="50" r="50" /></svg> </span>
                    </div>
                    <div class="me-20 a-self-center d-flex">
                        <span class="me-20 valign-m my-a">No Soy</span>
                    </div>
                </div>
            </marquee>*/}
            <div class="flavors fs-34 mt-10">
                <div class="plan-list fs-14 pb-30">
                    <ul>
                        <li class="d-flex align-center border3 br-10 bg4 text-center">
                            <div class="w-100">
                                <div class="color1 heading-1">Vanilla Caramel</div>
                                {/*<div class="d-flex align-center mt-10">
                                    <div class="circle-check green me-10"></div>401 calories per meal
                                </div>
                                <div class="d-flex align-center mt-10">
                                    <div class="circle-check green me-10"></div>Organic vanilla & natural caramel
                                </div>*/}
                                <img class="mw-100p br-10 pic-rh mt-10" src={carmshake} />
                            </div>
                        </li>
                        <li class="d-flex align-center border3 br-10 bg4 text-center">
                            <div class="w-100">
                                <div class="color1 heading-1">Chocolate</div>
                                {/*<div class="d-flex align-center mt-10">
                                    <div class="circle-check green me-10"></div>399 calories per meal
                                </div>
                                <div class="d-flex align-center mt-10">
                                    <div class="circle-check green me-10"></div>With 100% natural cacao
                                </div>*/}
                                <img class="mw-100p br-10 pic-rh mt-10" src={chocshake} />
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="align-center my-25 fs-34 mfs-24 text-center">
                    <div class="fw-bolder">
                        <div class="align-center mt-30 fs-34 mfs-24 text-center">Your Weight Loss Tool</div>
                    </div>
                    <p class="change-text mt-10 text-center">Fast &amp; convenient nutrition for any lifestyle.</p>
                </div>
                <div class="plan-list fs-14 mt-20">
                    <ul>
                        <li class="d-flex align-center border3 br-10 bg1">
                            <div class="w-100 d-flex">
                                <i class="checkout-icon my-a desk-d-none"><img class="p-10" src={scale} /></i>
                                <div class="my-a">
                                    <div class="fs-24 fw-bolder mb-5">Helps Manage Weight</div>
                                    <div class="mb-5">You can use it for weight loss or weight gain</div>
                                </div>
                            </div>
                        </li>
                        <li class="d-flex align-center border3 br-10 bg1">
                            <div class="w-100 d-flex">
                                <i class="checkout-icon my-a desk-d-none"><img class="p-10" src={fast} /></i>
                                <div class="my-a">
                                    <div class="fs-24 fw-bolder mb-5">Easy To Make</div>
                                    <div class="mb-5">Ready in 30 seconds</div>
                                </div>
                            </div>
                        </li>
                        <li class="d-flex align-center border3 br-10 bg1">
                            <div class="w-100 d-flex">
                                <i class="checkout-icon my-a desk-d-none"><img class="p-10" src={thumbsdown} /></i>
                                <div class="my-a">
                                    <div class="fs-24 fw-bolder mb-5">Free From</div>
                                    <div class="mb-5">Gluten, GMO's, Lactose, Hormones, Soy, Nuts, Dairy, Added Sugars</div>
                                </div>
                            </div>
                        </li>
                        <li class="d-flex align-center border3 br-10 bg1">
                            <div class="w-100 d-flex">
                                <i class="checkout-icon my-a desk-d-none"><img class="p-10" src={heartcheck} /></i>
                                <div class="my-a">
                                    <div class="fs-24 fw-bolder mb-5">100% Plant Based</div>
                                    <div class="mb-5">Peas, Avocados, Coconut, Flaxseed, Seaweed, Essential Oils</div>
                                </div>
                            </div>
                        </li>
                        <li class="d-flex align-center border3 br-10 bg1">
                            <div class="w-100 d-flex">
                                <i class="checkout-icon my-a desk-d-none"><img class="p-10" src={completenutrition} /></i>
                                <div class="my-a">
                                    <div class="fs-24 fw-bolder mb-5">Complete Nutrition</div>
                                    <div class="mb-5">42g of Fiber, High in Protein, 29 Vitamins &amp; Minerals, Superfoods, Digestive Enzymes, Pre &amp; Probiotics</div>
                                </div>
                            </div>
                        </li>
                        <li class="d-flex align-center border3 br-10 bg1">
                            <div class="w-100 d-flex">
                                <i class="checkout-icon my-a desk-d-none"><img class="p-10" src={health173} /></i>
                                <div class="my-a">
                                    <div class="fs-24 fw-bolder mb-5">173 Health Benefits</div>
                                    <div class="mb-5">Brain Function, Muscles, Energy, Immunity, Hair, Skin, Nails</div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                {/*<div class="justify-content flavors-intro mt-30 bg1 border3">
                    <div class="valign-m my-a me-10">
                        <div class="header-1 fw-bolder fs-22 mb-5">All Our Flavors</div>
                        <div class="fs-20 fw-bold color3"> Fast &amp; convenient nutrition</div>
                        <ul class="centered-list pl-0">
                            <li class="d-flex align-start">
                                <div class="circle-check green me-15"></div>
                                <div><span class="fw-bolder">Helps manage weight</span></div>
                            </li>
                            <li class="d-flex align-start mt-10">
                                <div class="circle-check green me-15"></div>
                                <div><span class="fw-bolder">Free from:</span> Gluten / GMOs / Lactose / Hormones / Soy / Nuts / Dairy / Added Sugars</div>
                            </li>
                            <li class="d-flex align-start mt-10">
                                <div class="circle-check green me-15"></div>
                                <div><span class="fw-bolder">Packed with the stuff that matters:</span> 42g of Fiber / High in Protein / 29 Vitamins &amp; Minerals / Superfoods / Digestive Enzymes / Pre &amp; Probiotics</div>
                            </li>
                            <li class="d-flex align-start mt-10">
                                <div class="circle-check green me-15"></div>
                                <div><span class="fw-bolder">Easy to make nutritionally complete meal:</span> Ready in 30 seconds</div>
                            </li>
                            <li class="d-flex align-start mt-10">
                                <div class="circle-check green me-15"></div>
                                <div><span class="fw-bolder">Plant based:</span> Peas / Avocados / Brown Rice / Flaxseed / Seaweed / Essential Oils</div>
                            </li>
                            <li class="d-flex align-start mt-10">
                                <div class="circle-check green me-15"></div>
                                <div><span class="fw-bolder">173 health benefits:</span> Brain Function / Muscles / Energy / Immunity / Hair / Skin / Nails</div>
                            </li>
                        </ul>
                    </div>
                </div>*/}
            </div>
            <div class="stamp-icon text-center">
                <a> <img src={ship} /> </a>
                <a> <img src={nl} /> </a>
                <a> <img src={haccp} /> </a>
                <a> <img src={cgmp} /> </a>
            </div>
        </div>
    </div>
</div>



         </>
    )
}