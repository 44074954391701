import React, {useState} from 'react';
import ChoosePlan from './ChoosePlanWeekly';
import PaymentOptions from './PaymentOptions';
import { monthlyPlan } from '../../common/utils/utils';

export const GetPlan = ({dreamFatImage, data, age, mAge, history}) => {
    const [selectedOption, setSelectedOption] = useState('1month');
    const [showPayment, setShowPayment] = useState(false);

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value); // Update the selected option state
    };
    const paymentOptionsRender = () => {
        setShowPayment(!showPayment);
    };
    const plan = monthlyPlan(selectedOption);

    return (
        <>
            <section class="bg4" id="get-plan">
                <div class="choose-plan d-flex">
                    <div class="choose-plan-img"> <img src={dreamFatImage}  alt/> </div>
                    {showPayment ?
                        <PaymentOptions {...{paymentOptionsRender, plan, data, age, mAge, history}} /> :
                        <ChoosePlan {...{selectedOption, handleOptionChange, paymentOptionsRender}} />
                    }
                </div>
            </section>
        </>
    )
}
