import React, { useState} from 'react';
import { Header } from './common/components/Header';
import Quiz0 from './pages/quiz0/Quiz0';
import ShakeQuiz from './pages/shake/ShakeQuiz';
import ShakeNewQuiz from './pages/shake-new/ShakeNewQuiz';
import { Routes, Route, useNavigate, useParams, useLocation } from "react-router-dom";

// import './index.css';
import './assets/css/fonts.css';
import './assets/css/common.css';
import './style.css';
import { ProgressBar } from './common/components/ProgressBar';
import Quiz0Checkout from './pages/quiz0/Quiz0Checkout';
import Home from './pages/Home';
import About from './pages/About';
import Tos from './pages/Tos';
import Privacy from './pages/Privacy';
import Cookies from './pages/Cookies';
import Success from './pages/quiz0/Success';
import ErrorPage from './pages/quiz0/Error';
import SubscribedPage from './pages/quiz0/Subscribed';
import Refunds from './pages/Refunds';
import Subscriptions from './pages/Subscriptions';

function App() {
  const location = useLocation();
  const history = useNavigate();
  const [showProgress, setShowProgress] = useState(false);
  const [progress, setProgress] = useState(0);
  const [page, setPage] = useState(0);
  const { id } = useParams();

  return (
    <div className="App">
      {/* <Header /> */}
      {showProgress && <ProgressBar {...{progress, page}} />}
      <Routes>
        {/* <Route path="/" element={<Home {...{history}} />} /> */}
        <Route exact path="/app/*" element={<Quiz0 {...{setShowProgress, history, setProgress, setPage}} />} />
        <Route exact path="/shake/*" element={<ShakeQuiz {...{setShowProgress, history, setProgress, setPage}} />} />
        <Route path="/" element={<ShakeNewQuiz {...{ setShowProgress, setProgress, setPage, history }} />} />
        <Route exact path="/shake-new/*" element={<ShakeNewQuiz {...{setShowProgress, history, setProgress, setPage}} />} />
        <Route path="/quiz/checkout" element={<Quiz0Checkout {...{history, setShowProgress}}/>} />
        <Route path="/about" element={<About />} />
        <Route path="/terms" element={<Tos />} />
        <Route path="/cookies" element={<Cookies />} />
        <Route path="/quiz/success" element={<Success />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/cookie-policy" element={<Cookies />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/waitlist" element={<SubscribedPage />} />
        <Route path="/refund-policy" element={<Refunds />} />
        <Route path="/subscription-policy" element={<Subscriptions />} />
      </Routes>
    </div>
  );
}
export default App;