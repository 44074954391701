import React, { useEffect } from 'react';
import oneLiter from '../../assets/img/updated/app-quiz/water/1litter.webp';
import twoLiter from '../../assets/img/updated/app-quiz/water/2litters.webp';
import threeLiters from '../../assets/img/updated/app-quiz/water/31litters.webp';
import fourLiters from '../../assets/img/updated/app-quiz/water/4litter.webp';
import q11Bg from '../../assets/img/updated/bg/q11-bg.webp';

export const Question11 = ({ history, values, handleChange, setFieldValue, setProgress, setShowProgress, setPage }) => {

    useEffect(() => {
        let percent;
        percent = (11 / 18) * 100;
        percent = percent.toFixed(2);
        setPage(11);
        setProgress(percent);
        setShowProgress(true);
    }, []);

    const handleChangeHandler = (value) => {
        setFieldValue('dailyWater', value);
        setTimeout(() => {
            history('/app/q12');
        }, 300);
    };

    return (
        <>
            <img src={q11Bg} className='img-fluid bg-img' alt=''/>
            <section className="text-primary water-content main">
                <div className="body-content">
                    <div className="heading text-primary mb-20 title-font mx-auto"> How much water do you drink daily? </div>

                    <div className='single-choose-question max-308 mx-auto'>
                        <button type='button' className='button bg-theme text-white w-100 d-flex fw-bold align-center mb-16' onClick={() => handleChangeHandler('less than 1 liter')}>
                            <img src={oneLiter} className='question-icon' width='24' height='20' />
                            <span>
                                Less Than 1 Liter
                            </span>
                        </button>
                        <button type='button' className='button bg-theme text-white w-100 d-flex fw-bold align-center mb-16' onClick={() => handleChangeHandler('1-2 liters')}>
                            <img src={twoLiter} className='question-icon' width='24' height='20' />
                            <span>
                                1-2 Liters
                            </span>
                        </button>
                        <button type='button' className='button bg-theme text-white w-100 d-flex fw-bold align-center mb-16' onClick={() => handleChangeHandler('2-3 liters')}>
                            <img src={threeLiters} className='question-icon' width='24' height='20' />
                            <span>
                                2-3 Liters
                            </span>
                        </button>
                        <button type='button' className='button bg-theme text-white w-100 d-flex fw-bold align-center mb-16' onClick={() => handleChangeHandler('more than 3 liters')}>
                            <img src={fourLiters} className='question-icon' width='24' height='20' />
                            <span>
                                More than 3 Liters
                            </span>
                        </button>
                    </div>
                </div>
            </section>
        </>
    )
}
