import React from "react";
import { Footer } from '../common/components/Footer';

const Refunds = () => (
    <div>
    <div class="cms container terms-page text-primary pb-50 body-padding">
    <div class="fs-28 fw-bold mb-50 mt-50"> Money Back Guarantee</div>
    <div>
      <h2 class="fs-18 fw-bold">1. REFUND POLICY</h2>
      <p>In addition to refund rights available under applicable laws, if you made a purchase directly on our website and the money-back option was presented to you during the checkout, you are eligible to receive a refund provided that all of the following conditions are met:</p>
      <ol class="pl-15">
      <li class="pl-5">You contact us via email at <a href="mailto:support@biomesecret.com">support@biomesecret.com</a> within 30 days after receiving your product and before the end of your subscription period; and</li>
      <li class="pt-5 pl-5">You provide proof of purchase, such as an order confirmation or receipt; and</li>
      <li class="pt-5 pl-5">The original product packaging, including the outer box, is in its original undamaged condition; and</li>
      <li class="pt-5 pl-5">Provide us with dated video evidence demonstrating that you have taken the supplement twice a day for a minimum of 14 consecutive days.</li>            
      </ol>
      <p>Upon reviewing your request, we will notify you by email whether your application is approved.</p>
      <h3 class="fs-14 pt-10">IMPORTANT STATEMENT</h3>
      <p>Please note that only fulfillment of all the above requirements allows you to receive a full refund under the "Money-back guarantee." For the sake of clarity, this "Money-back guarantee" does not apply to the following cases:</p>
      <ol class="pl-15">
      <li class="pl-5">Personal reasons (you don't like the product, or it did not meet your expectations).</li>
      <li class="pt-5 pl-5">Financial reasons (you did not expect to be charged, received an unexpected subscription purchase, processed an automatic renewal etc).</li>
      </ol>
      <p>While we do not charge shipping fees on our orders, shipping costs incurred by our company for each order vary depending on the order size, and are non-refundable. Any return shipping costs for the product are sole responsibility of the customer.</p>
      <p>Approved refunds will not be processed until we receive your returned product.</p>
      <h2 class="fs-18 fw-bold pt-20">2. GENERAL REFUND RULES</h2>
      <p>Generally, if you do not meet the conditions set out above, the fees you have paid are non-refundable and/or non-exchangeable, unless otherwise stated herein or required by applicable law.</p>
      <p><strong>Note for residents of certain US states:</strong> If you reside in California or Connecticut and wish to cancel the purchase at any time prior to midnight of the third business day after the date of such purchase, we will refund the payment you have made. Orders in these states may incurr a shipping delay in order to accomodate this cancellation period.</p>
      <p><strong>Note for EU residents:</strong> If you are an EU resident, you have the right to withdraw from the purchase agreement of physical products without charge and without giving any reason within fourteen (14) days from the date of such agreement conclusion. The withdrawal right does not apply if the performance of the agreement has begun with your prior express consent and your acknowledgment that you thereby lose your right of withdrawal. By making the purchase, you expressly consent to the immediate performance of the agreement and acknowledge that you will lose your right of withdrawal once the product is successfully delivered to you. Therefore, you will not be eligible for a refund unless the product is defective.</p>      
      <p class="pt-10">Note: This refund policy is updated periodically and is subject to change without notice. Any updates or modifications will be visible directly on our website.</p>
    </div>    
    </div>
    <Footer />
</div>

);
export default React.memo(Refunds);