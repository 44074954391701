import React from 'react';
import {renderImage} from '../../common/utils/utils';

export const PlanSection = ({ Name, currentFatImage, dreamFatImage, caloriesDetails }) => {    
    return ( 
        <>
    <div class="checkout-container pt-30 ptm-0 pb-30">    
    <div class="fw-bolder mxm-24"><div class="align-center my-25 fs-34 mfs-24 text-center">Losing Weight <span class="non-break">is Easy</span></div></div>

    <p class="color1 change-text mxm-24 text-center">See how {caloriesDetails?.label} can impact your body.</p>    
    <div class="body-transformation mt-40">

        <div class="d-flex">
            <div class="mw-50p w-100">
                <div class="body-transformation">
                    <div class="body-trans-header text-center text-darkbg d-flex">
                        <div class="mw-100p w-50 d-flex justify-content-center intro"><span class="valign-m my-a">Do Nothing</span></div>
                    </div>
                </div>
                <div class="body-trans-body d-flex bg1">
                    <div class="w-100 text-center">
                        <div class="body-trans-frame mb-10">
                          <img class="mh-350" src={currentFatImage} />
                        </div>
                        <div class="fw-bold mb-5">Body weight</div>
                        <div class="change-text fw-bold color2">No Change</div>  
                        
                    </div>
                </div>
            </div>
            <div class="mw-50p highlighted bg4 w-100">
                <div class="body-trans-header text-center text-darkbg d-flex">
                    <div class="w-50 d-flex justify-content-center mw-100p intro"><span class="valign-m my-a"><span class="fw-bolder">{caloriesDetails?.value}</span></span></div>
                </div>
                <div class="body-trans-body d-flex">
                    <div class="w-100 text-center">
                        <div class="body-trans-frame mb-10">
                          <img class="mh-350" src={dreamFatImage} />
                        </div>
                        <div class="fw-bold mb-5">Body weight</div>
                        <div class="theme-color change-text fw-bold">{caloriesDetails?.quantity}</div>  
                        
                    </div>
                </div>
            </div>
        </div>                        
    </div>
</div>
        </>
    )
}