import React, { useEffect } from 'react';

import reviews1 from '../../assets/img/updated/shake-new/summary/customer_2b.mp4';
import reviews2 from '../../assets/img/updated/shake-new/summary/customer_3b.mp4';
import reviews3 from '../../assets/img/updated/shake-new/summary/customer_4b.mp4';
import reviews4 from '../../assets/img/updated/shake-new/summary/customer_5b.mp4';
import verified from '../../assets/img/updated/shake-new/verified.svg';



export const Summary11 = ({ history, values, handleChange, setProgress, setShowProgress, setPage }) => {

    useEffect(() => {
        let percent;
        percent = (18 / 18) * 100;
        percent = percent.toFixed(2);
        setPage(18);
        setProgress(percent);
        setShowProgress(true);
    }, []);

    const clickHandler = () => {
        setTimeout(() => {
            window.location.href= 'https://biomesecret.com/products/weight-loss-meal-replacement?h=1';
        }, 300);
    };

    return (
        <section className="text-primary main summary-page-content">
            <div className='shake-summary-page'>
                <div className='shake-summary-page-steps text-white'>
                    <span className='active'></span>
                    <span className='active'></span>
                    <span className='active'></span>
                    <span className='active'></span>
                    <span className='active'></span>
                    <span className='active'></span>
                    <span className='active'></span>
                    <span className='active'></span>
                    <span className='active'></span>
                    <span className='active'></span>
                    <span className='active'></span>
                </div>
                <div className='shake-summary-page-content'>
                    <div class="heading">
                        <div class="text-primary title-font mx-auto">
                            See what real users have to say about <span className='theme-color'>Biome's</span> results. 
                        </div>
                    </div>
                    <div className='weight-loss-journey-new'>
                        <div className='d-flex shake-reviews'>
                            
                            <video
                                    aria-label="Video"
                                    autoPlay
                                    loop
                                    playsInline
                                    preload="auto"
                                    webkit-playsinline="true"
                                    x-webkit-airplay="allow"
                                    width="76"
                                    height="86"
                                    className="review-img"
                                    muted
                                    onCanPlayThrough={e => e.target.play()}>
                                    <source src={reviews1} type="video/mp4" />
                                </video>
                            <div>
                                <p className='mt-0 s-025 f-italic'>“I’ve already lost weight effortlessly, and the shake keeps me full and satisfied!”</p>
                               <div className='d-flex'>
                                    <span className='fw-bolder s-025'>
                                        Eloise Bates
                                    </span>

                                    <img src={verified} className='ms-auto' />                                    
                                </div> 
                            </div>
                        </div>
                        <div className='d-flex shake-reviews'>
                             <video
                                    aria-label="Video"
                                    autoPlay
                                    loop
                                    playsInline
                                    preload="auto"
                                    webkit-playsinline="true"
                                    x-webkit-airplay="allow"
                                    width="76"
                                    height="86"
                                    className="review-img"
                                    muted
                                    onCanPlayThrough={e => e.target.play()}>
                                    <source src={reviews2} type="video/mp4" />
                                </video>
                            <div>
                                <p className='mt-0 s-025 f-italic'>"Biome Secret has completely transformed my energy levels and helped me curb my cravings!"</p>
                               <div className='d-flex'>
                                    <span className='fw-bolder s-025'>
                                        Lily Carter
                                    </span>

                                    <img src={verified} className='ms-auto' />
                                </div> 
                            </div>
                        </div>
                        <div className='d-flex shake-reviews'>
                             <video
                                    aria-label="Video"
                                    autoPlay
                                    loop
                                    playsInline
                                    preload="auto"
                                    webkit-playsinline="true"
                                    x-webkit-airplay="allow"
                                    width="76"
                                    height="86"
                                    className="review-img"
                                    muted
                                    onCanPlayThrough={e => e.target.play()}>
                                    <source src={reviews3} type="video/mp4" />
                                </video>
                            <div>
                                <p className='mt-0 s-025 f-italic'>"After using Biome Secret for just a month, my digestion improved, and I feel more energized than ever!"</p>
                          <div className='d-flex'>
                                    <span className='fw-bolder s-025'>
                                        Sophie Reed
                                    </span>

                                    <img src={verified} className='ms-auto' />
                                </div> 
                            </div>
                        </div>

                        <div className='d-flex shake-reviews'>
                            {/*<img src={reviews4} className='review-img' width='76' height='86' />*/}
                             <video
                                    aria-label="Video"
                                    autoPlay
                                    loop
                                    playsInline
                                    preload="auto"
                                    webkit-playsinline="true"
                                    x-webkit-airplay="allow"
                                    width="76"
                                    height="86"
                                    className="review-img"
                                    muted
                                    onCanPlayThrough={e => e.target.play()}>
                                    <source src={reviews4} type="video/mp4" />
                                </video>
                            <div>
                                <p className='mt-0 s-025 f-italic'>"Within weeks, I felt lighter, less bloated, and my energy levels skyrocketed!"</p>
                                <div className='d-flex'>
                                    <span className='fw-bolder s-025'>
                                        Grace Thompson
                                    </span>
                                    <img src={verified} className='ms-auto' />
                                </div> 
                            </div>
                        </div>

                        <div className="mt-30">
                            <button
                                type='button'
                                className="button bg-secondary text-white w-100 mx-auto text-center mb-16 w-100"
                                title="Continue"
                                // disabled={values.targetWeightInKg ? errors.targetWeightKg : errors.targetWeightLb}
                                onClick={clickHandler}
                            > Continue
                            </button>
                            {/* <div className='fw-bold fs-10 color2'>
                                * Results may vary due personal features
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )  
};