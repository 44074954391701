import React, { useEffect } from 'react';
import carbMainly from '../../assets/img/updated/shake/q7-Carbs-Mainly.png';
import proteinbMainly from '../../assets/img/updated/shake/q7-Protein-Mainly.png';
import fatMainly from '../../assets/img/updated/shake/q7-Fat-Mainly.png';
import everything from '../../assets/img/updated/shake/q7-Bit-of-Everything.png';

export const Question7 = ({ history, handleChange, setFieldError, setFieldValue, values, errors, heightIncm, setHeightIncm, setProgress, setShowProgress, setPage }) => {

  useEffect(() => {
    let percent;
    percent = (7 / 18) * 100;
    percent = percent.toFixed(2);
    setPage(7);
    setProgress(percent);
    setShowProgress(true);
  }, []);

  const handleChangeHandler = (value) => {
    setFieldValue('foodLike', value);
    setTimeout(() => {
      history('/shake-new/q8');
    }, 300);
  };

  return (
    <>
      <section className="text-white breakfast-content main">
        <div className="body-content" >
          <div className="heading mb-20 title-font h-wrap"><span className="h-before"> How does your food usually look like? </span></div>
          <div className='single-choose-question max-308 mx-auto question-icons'>
            <button type='button' className={`button bg-theme text-white w-100 d-flex fw-bold align-center mb-16 ${Boolean(values.foodLike === 'carbs mainly') ? 'checked' : ''}`} onClick={() => handleChangeHandler('carbs mainly')}>
              <img src={carbMainly} className='question-icon' width='65' height='39' />
              <span>
                Carbs Mainly
              </span>
            </button>
            <button type='button' className={`button bg-theme text-white w-100 d-flex fw-bold align-center mb-16 ${Boolean(values.foodLike === 'protein mainly') ? 'checked' : ''}`} onClick={() => handleChangeHandler('protein mainly')}>
              <img src={proteinbMainly} className='question-icon' width='65' height='37' />
              <span>
                Protein Mainly
              </span>
            </button>
            <button type='button' className={`button bg-theme text-white w-100 d-flex fw-bold align-center mb-16 ${Boolean(values.foodLike === 'fat mainly') ? 'checked' : ''}`} onClick={() => handleChangeHandler('fat mainly')}>
              <img src={fatMainly} className='question-icon' width='51' height='47' />
              <span>
                Fat Mainly
              </span>
            </button>
            <button type='button' className={`button bg-theme text-white w-100 d-flex fw-bold align-center mb-16 ${Boolean(values.foodLike === 'bit of everything') ? 'checked' : ''}`} onClick={() => handleChangeHandler('bit of everything')}>
              <img src={everything} className='question-icon' width='75' height='22' />
              <span>
                Bit of Everything
              </span>
            </button>
          </div>
        </div>
      </section>
    </>
  )
}
