import React, { useEffect } from 'react';
import summary2 from '../../assets/img/updated/shake-new/summary/woman.png';
import check from '../../assets/img/updated/shake-new/summary/check-circle-broken.svg';
import summary7b from '../../assets/img/updated/shake-new/q7.webp';



export const Summary7 = ({ history, values, handleChange, setProgress, setShowProgress, setPage }) => {

    useEffect(() => {
        let percent;
        percent = (18 / 18) * 100;
        percent = percent.toFixed(2);
        setPage(18);
        setProgress(percent);
        setShowProgress(true);
    }, []);

    const clickHandler = () => {
        setTimeout(() => {
            history('/shake-new/summary8');
        }, 300);
    };

    return (
        <section className="text-primary main summary-page-content">
            <div className='shake-summary-page'>
                <div className='shake-summary-page-steps text-white'>
                    <span className='active'></span>
                    <span className='active'></span>
                    <span className='active'></span>
                    <span className='active'></span>
                    <span className='active'></span>
                    <span className='active'></span>
                    <span className='active'></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div className='shake-summary-page-content pt-0' style={{'background-color': '#F2EBE7'}}>   
                    <div className='weight-loss-journey-new'>
                        <div className='summary-5bg'>
                            <img src={summary7b} className='img-fluid br-4t' />                            
                        </div>                        
                        <div className="mt-30">
                            <button
                                type='button'
                                className="button bg-secondary text-white w-100 mx-auto text-center mb-16 w-100"
                                title="Continue"
                                // disabled={values.targetWeightInKg ? errors.targetWeightKg : errors.targetWeightLb}
                                onClick={clickHandler}
                            > Continue
                            </button>
                            <div className='f-italic fs-10 color2'>
                                * Results may vary due personal features
                            </div>
                        </div>
                    </div>                 
                    
                    {/*<div className='weight-loss-journey-new'>
                        <div className='fs-28  mb-10 mw-350'>
                                Your Complete Everyday Nutrition Made Simple
                            </div>
                        <img src={summary2} className='img-fluid bubbles mw-350' />
                        <div className='bubble-list d-flex'>
                            <span>
                                <img src={check} className='img-fluid w-16p' />
                                Get Complete Nutrition
                            </span>
                            <span>
                                <img src={check} className='img-fluid w-16p' />
                                Lose Weight
                            </span>
                            <span>
                                <img src={check} className='img-fluid w-16p' />
                                Detox Your Body
                            </span>
                            <span>
                                <img src={check} className='img-fluid w-16p' />
                                Make Your Gut Healthy
                            </span>
                            <span>
                                <img src={check} className='img-fluid w-16p' />
                                Balance Your Hormones
                            </span>
                            <span>
                                <img src={check} className='img-fluid w-16p' />
                                 40g Protein
                            </span>
                            <span>
                                <img src={check} className='img-fluid w-16p' />
                                12g Fiber
                            </span>
                            <span>
                                <img src={check} className='img-fluid w-16p' />
                                27 Vitamins & Minerals
                            </span>
                            <span>
                                <img src={check} className='img-fluid w-16p' />
                                Prebiotics & Probiotics
                            </span>
                            <span>
                                <img src={check} className='img-fluid w-16p' />
                                Digestive Enzymes
                            </span>
                            <span>
                                <img src={check} className='img-fluid w-16p' />
                                Whole-Food ingredients
                            </span>
                        </div>
                        <div className="p-neg50">
                            <button
                                type='button'
                                className="button bg-secondary text-white w-100 mx-auto text-center mb-16 w-100"
                                title="Continue"
                                // disabled={values.targetWeightInKg ? errors.targetWeightKg : errors.targetWeightLb}
                                onClick={clickHandler}
                            > Continue
                            </button>
                            <div className='f-italic fs-10 color2'>
                                * Results may vary due personal features
                            </div>
                        </div>
                    </div>*/}

                </div>
            </div>
        </section>
    )  
};
