import React, { useEffect } from 'react';
import FeetAndInchesField from '../../common/components/FeetAndInchesField';
import CentimeterField from '../../common/components/CentimeterField';

export const Question14 = ({ history,
    handleChange,
    setFieldValue,
    values,
    errors,
    handleBlur,
    touched,
    setProgress,
    setShowProgress,
    setPage,
    heightIncm,
    setHeightIncm
}) => {

    useEffect(() => {
        let percent;
        percent = (14 / 18) * 100;
        percent = percent.toFixed(2);
        setPage(14);
        setProgress(percent);
        setShowProgress(true);
    }, []);

    const heightInCmHandler = () => {
        let feetToCm, inchesToCm, totalCm;
        feetToCm = parseInt(values.feet) * 30.48; // 1 foot = 30.48 cm
        inchesToCm = parseInt(values.inches) * 2.54; // 1 inch = 2.54 cm
        totalCm = feetToCm + inchesToCm;
        totalCm = Math.round(totalCm);
        setHeightIncm(true);
        setFieldValue('cm', totalCm);
    };

    const feetHandler = () => {
        const inchesPerCm = 1 / 2.54; // Conversion factor from cm to inches
        const inchesPerFoot = 12;    // Number of inches in a foot
        
        // Convert cm to total inches
        let totalInches = parseInt(values.cm) * inchesPerCm;
        
        // Calculate feet and remaining inches
        let feet = Math.floor(totalInches / inchesPerFoot);
        let remainingInches = totalInches % inchesPerFoot;
        
        remainingInches = Math.round(remainingInches);
    
        if (remainingInches >= inchesPerFoot) {
            feet += 1;
            remainingInches -= inchesPerFoot;
        }
        
        setHeightIncm(false);
        setFieldValue('feet', feet);
        setFieldValue('inches', remainingInches);
    };

    const clickHandler = () => {
        const inches = values.inches === '' ? '0' : values.inches;
        setFieldValue('inches', inches);
        const height = heightIncm 
            ? `${values.cm} cm` 
            : `${values.feet} ft ${inches} in`;
        setFieldValue('height', height);
        
        setTimeout(() => {
            history('/shake/q15');
        }, 300);
    };
    const handleFieldChange = (e, length) => {
        if(e.target.value.length > length) {
            return;
        }
        handleChange(e);
    };
    return (
        <>
            <section className="text-primary form-main-content main">
                <div className="body-content">
                    <div className="heading text-primary mb-20 title-font mx-auto"> What’s your height? </div>
                    <div className='form-content mx-auto'>
                        <div className="switch-unit-btn mb-30">
                            <button
                                type='button'
                                className={!heightIncm && "active"}
                                disabled={!heightIncm && true}
                                onClick={feetHandler}
                            > Imperial </button>
                            <button
                                type='button'
                                className={heightIncm && "active"}
                                disabled={heightIncm && true}
                                onClick={heightInCmHandler}
                            > Metric </button>
                        </div>
                        {heightIncm ?
                            <CentimeterField
                                {...{
                                    handleFieldChange,
                                    handleBlur,
                                    values,
                                    errors,
                                    touched
                                }}
                            /> :
                            <FeetAndInchesField
                                {...{
                                    handleFieldChange,
                                    handleBlur,
                                    values,
                                    errors,
                                    touched
                                }}
                            />
                        }
                        <div className="mt-30">
                            <button
                                type='button'
                                className="button bg-theme text-white w-100 mx-auto  fw-bold text-center mb-16 w-100"
                                title="Continue"
                                disabled={errors.feet}
                                onClick={clickHandler}
                            >
                                Continue
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
