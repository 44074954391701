import React, { useEffect } from 'react';
import img1 from '../../assets/img/updated/shake/q18-main-picture.webp';
import img1Mobile from '../../assets/img/updated/shake/q18-main-picture-MOBILE.png';


import summaryWeight from '../../assets/img/updated/q18-dynamic-graphic-your-weight-loss-journey.png';
import summaryWeightDesktop from '../../assets/img/updated/q18-dynamic-graphic-(your-weight-loss-journey).png';

import summaryBloating from '../../assets/img/updated/shake/vitamins.webp';
import summaryBloatingMobile from '../../assets/img/updated/shake/vitamins-mobile.webp';

import summaryHealth from '../../assets/img/updated/shake/reduced-bloating.webp';
import summaryHealthMobile from '../../assets/img/updated/shake/reduced-bloating-mobile.webp';

import summaryMetabolish from '../../assets/img/updated/shake/meta.webp';
import summaryMetabolishMobile from '../../assets/img/updated/shake/meta-mobile.webp';

import { weightConvert, weightconvertToPounds, getSlotValue } from '../../common/utils/formulas';
// import { ResultSummarySlick } from './components/ResultSummarySlick';
import { ResultSummarySlick } from '../../common/components/ResultSummarySlick';
import { ShakeSlick } from '../../common/components/ShakeSlick';
// import followers from '../../assets/img/updated/q18-review-stars-before-user-reviews-section.webp';
import rating from '../../assets/img/updated/q18-review-stars-before-user-reviews-section.webp';

export const Question18 = ({ history, values, handleChange, setProgress, setShowProgress, setPage }) => {

    const data = JSON.parse(window.localStorage.getItem('magicPills') || JSON.stringify({}));
    const currentWeightInKg = weightConvert(data.currentWeight, data.weightInKg);
    const targetWeightInKg = weightConvert(data.targetWeight, data.targetWeightInKg);
    const currentWeightInLb = weightconvertToPounds(data.currentWeight, data.weightInKg);
    const targetWeightInLb = weightconvertToPounds(data.targetWeight, data.targetWeightInKg);
    const diffInWeight = data.weightInKg && data.targetWeightInKg ? currentWeightInKg - targetWeightInKg
        : currentWeightInLb - targetWeightInLb;
    const slots = getSlotValue(diffInWeight);
    const weightLabel = data.weightInKg && data.targetWeightInKg ? 'kg' : 'lbs';

    const monthNames = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    const futureMonthIndex = (currentMonth + 3) % 12;
    const futureYear = currentYear + Math.floor((currentMonth + 3) / 12);
    
    const currentMonthName = monthNames[currentMonth];
    const futureMonthName = monthNames[futureMonthIndex];

    useEffect(() => {
        let percent;
        percent = (18 / 18) * 100;
        percent = percent.toFixed(2);
        setPage(18);
        setProgress(percent);
        setShowProgress(true);
    }, []);

    return (
        <section className="text-primary main summary-page-content">
            <div className='first-summary-section'>
                <div className='d-grid  container-1200 mx-auto align-center justify-between'>
                    <div className='title-font fs-32 mb-30'>
                        See how <span className='theme-color'>Biome Secret</span> will change your life
                    </div>
                    <div className='d-flex mb-30 align-center content-97'>
                        <span className='fw-bold fs-50'>
                            97%
                        </span>
                        <span className='fs-20 fw-bold'>
                            Of users already felt better after just<br /> the first month.
                        </span>
                    </div>
                    <div className="continue-btn">
                        <button
                            type='button'
                            className="bg-theme button text-white fw-bold fs-16"
                            title="Weight loss Meal Replacement"
                            onClick={() => window.location.href = 'https://biomesecret.com/products/weight-loss-meal-replacement'}
                        > See the product
                        </button>
                    </div>
                    <div className='text-center img-frame'>
                        {/* <img src={img1} className='img-fluid desktop' alt='' width="440" height="276" />
                        <img src={img1Mobile} className='img-fluid' alt='' width="440" height="276" /> */}
                        <picture>
                            <source
                            media="(min-width: 768px)"
                            srcSet={img1}
                            sizes="768px"
                            />
                            <source
                            srcSet={img1Mobile}
                            sizes="1280px"
                            />
                            <img src={img1Mobile} alt="{}" height='276' width='440' loading='lazy'/>
                        </picture>
                    </div>

                </div>
            </div>
            {/*  */}
            <div className='second-summary-section'>
                <div className='text-center  fs-32 mb-40'>
                    Your personal summary
                </div>
                <div className='container-1200 mx-auto'>
                    <div className='summary-grid'>
                        <div className='summary-graph'>
                            <div className='summary-graph-main'>
                                <div className='weight-loss-content weight-loss-journey'>
                                <div className='fs-24 fw-bold text-center mb-20'>
                                    Your Weight Loss Journey
                                </div>
                                <div className='d-flex mb-20 align-center justify-content-between text-center current-last'>
                                    <span>
                                        Now<br />
                                        {currentMonthName} {currentYear}
                                    </span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="18" viewBox="0 0 100 18" fill="none">
                                        <path d="M100 8.99909L84.9999 0.338972L85.0001 17.6595L100 8.99909ZM1.36485e-05 10.5L86.5 10.4992L86.5 7.49921L-1.36485e-05 7.5L1.36485e-05 10.5Z" fill="#5F815C" />
                                    </svg>
                                    <span>
                                        {futureMonthName} {futureYear}
                                        <br />
                                        <b>-{Math.round(diffInWeight)} {weightLabel}</b>
                                        {/* -35 lbs */}
                                    </span>
                                </div>
                                    <div className='weight-loss-values'>
                                        <div><span></span></div>
                                        <div>
                                            <span>-{Math.round(slots?.slot1)} {weightLabel}</span>
                                        </div>
                                        <div>
                                            <span>-{Math.round(slots?.slot2)} {weightLabel}</span>
                                        </div>
                                        <div>
                                            <span>-{Math.round(slots?.slot3)} {weightLabel}</span>
                                        </div>
                                        <div>
                                            <span>-{Math.round(slots?.slot4)} {weightLabel}</span>
                                        </div>
                                        <div>
                                            <span></span>
                                        </div>
                                    </div>
                                    {/* <img src={summaryWeight} alt='' className='img-fluid mobile' />
                                    <img src={summaryWeightDesktop} alt='' className='img-fluid desktop' /> */}
                                    <picture>
                                        <source
                                        media="(min-width: 768px)"
                                        srcSet={summaryWeightDesktop}
                                        sizes="768px"
                                        />
                                        <source
                                        srcSet={summaryWeight}
                                        sizes="1280px"
                                        />
                                        <img src={summaryWeight} alt="{}" height='' width='440' className='img-fluid w-100' loading='lazy'/>
                                    </picture>
                                </div>
                                </div>                           
                            <div className="text-center continue-btn  mt-30 mb-30">
                                <button
                                    type='button'
                                    className="bg-theme button text-white fw-bold fs-16"
                                    title="Weight loss Meal Replacement"
                                    onClick={() => window.location.href = 'https://biomesecret.com/products/weight-loss-meal-replacement'}
                                > See the product
                                </button>
                            </div>
                        </div>
                        <div className='summary-graph personalized-guide'>
                            <div className='summary-graph-main'>
                                <div className='fs-24 fw-bold text-center mb-20'>
                                    Reduced Bloating
                                </div>
                                <div className='d-flex' style={{ marginLeft: "0" }}>
                                    <div className='summary-bloatin '>
                                        <picture>
                                            <source
                                            media="(min-width: 768px)"
                                            srcSet={summaryHealth}
                                            sizes="768px"
                                            />
                                            <source
                                            srcSet={summaryHealthMobile}
                                            sizes="1280px"
                                            />
                                            <img src={summaryHealthMobile} alt="{}" height='' width='440' className='img-fluid ' loading='lazy'/>
                                        </picture>
                                    </div>
                                
                                </div>
                            </div>
                            <div className="text-center continue-btn mt-30 mb-30">
                                    <button
                                        type='button'
                                        className="bg-theme button text-white fw-bold fs-16"
                                        title="Weight loss Meal Replacement"
                                        onClick={() => window.location.href = 'https://biomesecret.com/products/weight-loss-meal-replacement'}
                                    > See the product
                                    </button>
                                </div>
                        </div>
                        <div className='summary-graph'>
                        <div className='summary-graph-main'>
                            <div class="fs-24 fw-bold text-center mb-20">Vitamins and Minerals</div>
                            <div className='d-flex justify-content-center' >
                                <div className='summary-bloatin text-center'>
                                    <picture>
                                        <source
                                        media="(min-width: 768px)"
                                        srcSet={summaryBloating}
                                        sizes="768px"
                                        />
                                        <source
                                        srcSet={summaryBloatingMobile}
                                        sizes="1280px"
                                        />
                                        <img src={summaryHealthMobile} alt="{}" height='' width='440' className='img-fluid w-100' loading='lazy'/>
                                    </picture>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className='summary-graph'>
                        <div className='summary-graph-main'>
                            <div className='d-flex justify-content-center'>
                                <div className='summary-metabolism text-center'>
                                    {/* <img src={summaryMetabolish} className='img-fluid' /> */}
                                    <div class="fs-24 fw-bold text-center mb-20">Boost Your Metabolism</div>
                                    <picture>
                                        <source
                                        media="(min-width: 768px)"
                                        srcSet={summaryMetabolish}
                                        sizes="768px"
                                        />
                                        <source
                                        srcSet={summaryMetabolishMobile}
                                        sizes="1280px"
                                        />
                                        <img src={summaryHealthMobile} alt="{}" height='' width='440' className='img-fluid w-100' loading='lazy'/>
                                    </picture>
                                    {/* <span>90%</span> */}
                                </div>
                            </div>
                            </div>
                            <div className="text-center continue-btn  mt-30 mb-30">
                        <button
                            type='button'
                            className="bg-theme button text-white fw-bold fs-16"
                            title="Weight loss Meal Replacement"
                            onClick={() => window.location.href = 'https://biomesecret.com/products/weight-loss-meal-replacement'}
                        > See the product
                        </button>
                    </div>
                        </div>
                    </div>
                    <div className='fs-11 text-center mt-40 mb-40 text-lgt-grey'>*Results may vary due to personal features</div>
                </div>
            </div>
            <div className='third-summary-section text-center'>
                <div className='container-1200 mx-auto'>
                    <div className='fs-24 mb-40 title-font'>
                    Biome Secret: Your Path to Weight Loss and Well-Being
                    </div>
                    <p>
                    Based on your quiz answers, our product is designed to help you lose weight, stay energized, and improve your overall health. We’re confident that Biome Secret can be an essential part of your journey to feeling your best.
                    </p>
                    <div className="text-center continue-btn mt-50">
                        <button
                            type='button'
                            className="bg-theme button text-white fw-bold fs-16"
                            title="Weight loss Meal Replacement"
                            onClick={() => window.location.href = 'https://biomesecret.com/products/weight-loss-meal-replacement'}
                        > See the product
                        </button>
                    </div>
                </div>
            </div>
            <div className='fourth-summary-section'>
                <div className=''>
                    <div className='fs-44  text-center mb-50'>
                        Real Results: See How <span className='theme-color'>Biome Secret</span><br /> Transforms Health and Wellness
                    </div>
                </div>
                <div className='container-1200 mx-auto'>
                    <div className='results-page d-flex align-center'>
                        <div>
                            <ul>
                                <li>
                                    <div className='title-font fs-20'>82% improved digestion and reduced bloating.</div>
                                    <div className='text-lgt-grey fs-16'>Feel lighter within 30 days.</div>
                                </li>
                                <li>
                                    <div className='title-font fs-20'>88% gained more energy and vitality.</div>
                                    <div className='text-lgt-grey fs-16'>Boost your daily well-being.</div>
                                </li>
                                <li>
                                    <div className='title-font fs-20'>Nutritionist-approved formula for weight loss and gut health.</div>
                                    <div className='text-lgt-grey fs-16'>Probiotics and prebiotics optimize results.</div>
                                </li>
                                <li>
                                    <div className='title-font fs-20'>75% curbed cravings and controlled their diet.</div>
                                    <div className='text-lgt-grey fs-16'>Stay on track with ease.</div>
                                </li>
                                <li>
                                    <div className='title-font fs-20'>Feel lighter and more energized.</div>
                                    <div className='text-lgt-grey fs-16'>Experience lasting vitality with Biome Secret.</div>
                                </li>
                            </ul>
                        </div>
                        <div className='results-slider'>
                            <ResultSummarySlick />
                        </div>
                    </div>
                    <div className="text-center continue-btn mt-50">
                        <button
                            type='button'
                            className="bg-theme button text-white fw-bold fs-16"
                            title="Weight loss Meal Replacement"
                            onClick={() => window.location.href = 'https://biomesecret.com/products/weight-loss-meal-replacement'}
                        > See the product
                        </button>
                    </div>
                </div>
            </div>
            <div className='fifth-summary-section'>
                <div className='text-center'>
                    {/* <img src={followers} className='img-fluid' alt='Biomesecret' /> */}
                    <div>
                        <img src={rating} className='img-fluid' alt='Biomesecret' width="400" style={{ maxWidth: "250px", height: "auto"}}/>
                    </div>
                    <div className='fs-40 mt-40 mb-40'>
                        Users see big changes in under a month
                    </div>
                </div>
                <div className='container-1200 mx-auto'>
                    <ShakeSlick />
                    <div className="text-center continue-btn mt-50">
                        <button
                            type='button'
                            className="bg-theme button text-white fw-bold fs-16"
                            title="Weight loss Meal Replacement"
                            onClick={() => window.location.href = 'https://biomesecret.com/products/weight-loss-meal-replacement'}
                        > See the product
                        </button>
                    </div>
                </div>
            </div>
            <div className='sixth-summary-section'>
                <div className='container-1200 text-center mx-auto'>
                    <div className='fs-40 mt-40 mb-40'>
                        OUR COMMITMENT
                    </div>
                    <p className='mb-30'>
                    At Biome Secret, we believe everyone deserves to live confidently. Traditional diets are often too restrictive and hard to follow, hindering your weight loss journey. We understand that your path to a healthier life should be enjoyable and sustainable. 
                    </p>
                    <p >
                        Don’t blame yourself—it's challenging. That's why our team of nutritionists works tirelessly to make weight loss easier and support your progress every step of the way.
                    </p>
                    <div className='fw-bold fs-20 mt-30'>Biome Secret Team</div>
                    <div className="text-center continue-btn mt-50">
                        <button
                            type='button'
                            className="bg-theme button text-white fw-bold fs-16"
                            title="Continue"
                            onClick={() => window.location.href = 'https://biomesecret.com/products/weight-loss-meal-replacement'}
                        > 
                            See the product
                        </button>
                    </div>
                </div>
            </div>
        </section>
    )
}
