import React from "react";
import { Footer } from '../common/components/Footer';

const Subscriptions = () => (
    <div>
    <div class="cms container terms-page text-primary pb-50 body-padding">
    <div class="fs-28 fw-bold mb-50 mt-50"> Subscription Policy</div>
    <div>
      <h2 class="fs-18 fw-bold">1. Subscription Terms</h2>
      <p>At our website, we offer flexible subscription options to cater to your needs. During the checkout process, you can choose the duration of your subscription, which includes weekly, monthly, or a variety of semi-monthly plans. This allows you to select the subscription frequency that works best for you.</p>
      <p>By offering different subscription durations, we aim to provide you with the flexibility to align your subscription with your preferences and requirements. Whether you prefer a shorter-term commitment or a longer-term subscription, we have options to suit your needs.</p>
      <p>Please note that the subscription terms and renewal frequency are determined by your selection during the checkout process. It's important to review and confirm your chosen subscription duration before finalizing your purchase.</p>      
    </div>
    <div>
      <h2 class="fs-18 fw-bold pt-20">2. Subscription Pricing and Trial Period</h2>
      <p>At our website, we offer subscription plans without any additional fees. However, please be aware that when you start a subscription, there will be an introductory trial price for a specified duration. This allows you to experience the benefits of our subscription service at a discounted rate during the trial period.</p>
      <p>During the trial period, you will have access to all the features and benefits of the subscription. It's a great opportunity to explore the offerings and evaluate whether the subscription meets your expectations and needs.</p>
      <p>After the trial period ends, your subscription will automatically renew at the regular price billed on a monthly basis. This ensures uninterrupted access to our products.</p>
      <p>It's important to note that the trial price and subsequent subscription renewal fees will be charged according to your selected payment method. We accept payment via PayPal, credit cards, Apple Pay, and Google Pay, providing you with a range of convenient options.</p>
      <p>Should you decide to cancel your subscription, please refer to our <a href="../refund-policy">money back guarantee</a> for detailed instructions on the cancellation process. We appreciate your understanding that cancellation requests should be made by contacting our customer support team via email.</p>
    </div>
    <div>
      <h2 class="fs-18 fw-bold pt-20">3. Subscription Renewal and Termination</h2>
      <h3 class="fs-14">3.1 Subscription Renewal</h3>
      <p>Your subscription will automatically renew at the full monthly price unless you cancel it at least 24 hours prior to the next subscription billing date. This ensures continuous access to our subscription services without any interruptions.</p>
      <h3 class="fs-14">3.2 Termination</h3>
      <p>If you wish to terminate your subscription, please refer to our refund policy for detailed information on the termination process. It is important to note that terminating your subscription does not entitle you to a refund for any unused portion of the subscription term. Please review our refund policy for more details on our refund eligibility and procedures.</p>
      <h3 class="fs-14">3.3 Communication and Support</h3>
      <p>If you have any questions or need assistance regarding your subscription, please feel free to reach out to our customer support team. You can contact us via email, and our dedicated support staff will be happy to assist you.</p>
      <p>We value your subscription and strive to provide a seamless and satisfactory experience throughout your subscription period.</p>      
    </div>
    <div>
      <h2 class="fs-18 fw-bold pt-20">4. Payment Methods</h2>
          <h3 class="fs-14">4.1 Available Payment Methods</h3>
          <p>We offer multiple convenient payment methods to facilitate your subscription purchase and renewal. By making a purchase, you authorize us to charge the applicable subscription fees to the payment method that you submit. You can choose from the following payment options during the checkout process:</p>
          <ul>
            <li>PayPal: Use your PayPal account to securely make payments.</li>
            <li>Credit Cards: We accept major credit cards for hassle-free transactions.</li>
            <li>Apple Pay: Conveniently make payments using Apple Pay on compatible devices.</li>
            <li>Google Pay: Make quick and secure payments using Google Pay on compatible devices.</li>
          </ul>
          <h3 class="fs-14">4.2 Introductory Trial Price</h3>
          <p>When you start a subscription, you will be billed at an introductory trial price. This allows you to experience our products at a discounted rate for a specified duration. After the trial period ends, your subscription will automatically renew at the full monthly price.</p>
          <h3 class="fs-14">4.3 Fees and Charges</h3>
          <p>There are no fees associated with having a subscription. However, please note that any incurred shipping costs related to physical products are non-refundable. Additionally, if you choose to cancel your subscription, any return shipping costs will be the responsibility of the customer.</p>
          <p>We strive to provide flexible and secure payment options to enhance your subscription experience. Choose the payment method that suits you best and enjoy the benefits of your subscription.</p>
    </div>
    <div>
      <h2 class="fs-18 fw-bold pt-20">5. Cancellation and Renewal</h2>
      <h3 class="fs-14">5.1 Cancellation Process:</h3>
          <p>If you wish to cancel your subscription, please reach out to our customer support team via email at [support email]. They will provide you with the necessary steps to cancel your subscription. Please note that cancellation requests must be received prior to the next subscription billing date.</p>
        <h3 class="fs-14">5.2 Refund Policy</h3>
          <p>For information regarding refunds and our refund policy, please refer to our <a href="../refund-policy">Refunds Policy</a>. It outlines the terms and conditions for requesting refunds and any applicable restrictions.</p>
        <h3 class="fs-14">5.3 Automatic Renewal:</h3>
          <p>Your subscription will automatically renew at the full monthly price, unless you cancel your subscription at least 24 hours prior to the next subscription billing date. We recommend reviewing your subscription status and considering any changes before the renewal date to avoid unwanted charges.</p>
        <h3 class="fs-14">5.4 Termination</h3>
          <p>In cases of violation of our terms of service or other relevant policies, we reserve the right to terminate your subscription without prior notice. Such termination may occur if there is evidence of misuse, fraudulent activities, or any other breach of our policies.</p>    
    </div>
    <div>
      <h2 class="fs-18 fw-bold pt-20">6. Pricing Changes</h2>
      <p>We reserve the right to modify subscription fees at any time, to the extent permitted by applicable laws. In the event of any changes to the pricing of your subscription, we will provide you with reasonable notice. This may include posting the updated prices on our website, sending you an email notification, or using any other prominent method of communication. If you do not wish to continue with the subscription under the new pricing, you have the option to cancel your subscription prior to the changes taking effect.</p>
    </div>
    <div>
      <h2 class="fs-18 fw-bold pt-20">7. Contact Information</h2>
      <p>If you have any questions, concerns, or need further assistance regarding your subscription, please feel free to reach out to our customer support team. You can contact us by email at <a href="mailto:support@biomesecret.com">support@biomesecret.com</a>.</p>
    </div>
    </div>
    <Footer />
</div>

);
export default React.memo(Subscriptions);