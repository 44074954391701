import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import * as Yup from 'yup';
import { Formik } from "formik";
import { Question0 } from '../../components/shake-new/Question0';
import { Question1 } from '../../components/shake-new/Question1';
import { Question2 } from '../../components/shake-new/Question2';
import { Question3 } from '../../components/shake-new/Question3';
import { Question4 } from '../../components/shake-new/Question4';
import { Question5 } from '../../components/shake-new/Question5';
import { Question6 } from '../../components/shake-new/Question6';
import { Question7 } from '../../components/shake-new/Question7';
import { Question8 } from '../../components/shake-new/Question8';
import { Question9 } from '../../components/shake-new/Question9';
import { Question10 } from '../../components/shake-new/Question10';
import { Question11 } from '../../components/shake-new/Question11';
import { Question12 } from '../../components/shake-new/Question12';
import { Question13 } from '../../components/shake-new/Question13';
import { Question14 } from '../../components/shake-new/Question14';
import { Question15 } from '../../components/shake-new/Question15';
import { Question16 } from '../../components/shake-new/Question16';
import { EmailField } from '../../components/shake-new/EmailField';
import { Summary1 } from '../../components/shake-new/Summary1';
import { Summary2 } from '../../components/shake-new/Summary2';
import { Summary3 } from '../../components/shake-new/Summary3';
import { Summary4 } from '../../components/shake-new/Summary4';
import { Summary5 } from '../../components/shake-new/Summary5';
import { Summary6 } from '../../components/shake-new/Summary6';
import { Summary7 } from '../../components/shake-new/Summary7';
import { Summary8 } from '../../components/shake-new/Summary8';
import { Summary9 } from '../../components/shake-new/Summary9';
import { Summary10 } from '../../components/shake-new/Summary10';
import { Summary11 } from '../../components/shake-new/Summary11';
import { Transition } from '../../components/shake-new/Transition';
import imgBg from '../../assets/img/updated/shake-new/vid-dna22.mp4';


const ShakeQuiz = ({ history, setShowProgress, setProgress, setPage }) => {

    const [heightIncm, setHeightIncm] = useState(false);

    const formikProps = {
        validateOnMount: true,
        validateOnChange: true,
        // validateOnBlur: true,
        enableReinitialize: true,
        initialValues: {
            gender: '',
            termsAndCondition: false,
            goal: '',
            physicalHealth: '',
            feelAfterEat: '',
            breakfastTaken: '',
            lunchTaken: '',
            dinnerTaken: '',
            foodLike: '',
            exercise: '',
            jobLike: '',
            experienceAny: [],
            dailyWater: '',
            age: '',
            feet: '',
            inches: '',
            cm: '',
            height: '',
            currentWeight: '',
            currentWeightKg: '',
            currentWeightLb: '',
            targetWeight: '',
            targetWeightKg: '',
            targetWeightLb: '',
            name: '',
            dob: '',
            email: '',
            weightInKg: false,
            targetWeightInKg: false
        },
        onSubmit: (values) => {
            console.log('values', values);
        }
    };

    const validate = (values) => {
        const errors = {};

        // Define schema rules
        const schemaRules = {
            email: Yup.string()
                .required('Email is required!')
                .email('Invalid email address')
                .matches(/^(?!.*[.]con$).*$/, {
                    excludeEmptyString: true,
                    message: 'Must be a valid email address.',
                }),
            age: Yup.string()
                .required('Age is required')
                .matches(/^\d+$/, 'Age must be a valid number')
                .test('is-valid-age', 'Age must be greater than or equal to 18', value => {
                    const ageNumber = parseInt(value, 10);
                    return !isNaN(ageNumber) && ageNumber >= 18;
                }),
            feet: Yup.string()
                .required('Add feet')
                .matches(/^\d+$/, 'Feet must be a valid number')
                .test('is-valid-feet', 'Feet should be 8 max', value => {
                    const feetNumber = parseInt(value, 10);
                    return !isNaN(feetNumber) && feetNumber <= 8;
                }),
            inches: Yup.string()
                .test('is-valid-number', 'Inches should be 11 max', value => {
                    if (!value) return true; // Allow empty field
                    const numberValue = parseFloat(value);
                    return !isNaN(numberValue) && numberValue <= 11;
                }),
            currentWeightKg: Yup.string()
                .test('current-weight-kg', 'Weight must be between 40 and 300 kg', (value) => {
                    const numberValue = parseInt(value, 10);
                    return (numberValue >= 40 && numberValue <= 300);
                })
                .required('Weight input is required'),
            currentWeightLb: Yup.string()
                .test('current-weight-lb', 'Weight must be between 88 and 660 lbs', (value) => {
                    const numberValue = parseInt(value, 10);
                    return (numberValue >= 88 && numberValue <= 660);
                })
                .required('Weight input is required'),
            targetWeightKg: Yup.string()
                .test('target-weight-kg', 'Weight must be between 40 and 300 kg', (value) => {
                    const numberValue = parseInt(value, 10);
                    return (numberValue >= 40 && numberValue <= 300);
                })
                .test('target-weight-kg-comparison', 'Goal weight must be less than current weight', function (value) {
                    const numberValue = parseInt(value, 10);
                    const currentWeightKg = parseInt(this.parent.currentWeightKg, 10);
                    return !isNaN(numberValue) && numberValue < currentWeightKg;
                })
                .required('Weight input is required'),
            targetWeightLb: Yup.string()
                .test('target-weight-lb', 'Weight must be between 88 and 660 lbs', (value) => {
                    const numberValue = parseInt(value, 10);
                    return (numberValue >= 88 && numberValue <= 660);
                })
                .test('target-weight-lb-comparison', 'Goal weight must be less than current weight', function (value) {
                    const numberValue = parseInt(value, 10);
                    const currentWeightLb = parseInt(this.parent.currentWeightLb, 10);
                    return !isNaN(numberValue) && numberValue < currentWeightLb;
                })
                .required('Weight input is required'),
            weightInKg: Yup.boolean(),
            targetWeightInKg: Yup.boolean(),
        };

        const schema = Yup.object().shape(schemaRules);

        try {
            // Validate the values against the schema
            schema.validateSync(values, { abortEarly: false });
        } catch (err) {
            if (err.inner && Array.isArray(err.inner)) {
                err.inner.forEach(error => {
                    if (error.path) {
                        errors[error.path] = error.message;
                    }
                });
            } else {
                console.error('Unexpected error format:', err);
            }
        }

        return errors;
    };
    useEffect(() => {
        const videoElement = document.querySelector('.bg-vid');

        const playVideo = () => {
            if (videoElement) {
                videoElement.play();
            }
        };

        // Add event listeners for user interactions
        document.addEventListener('scroll', playVideo, { once: true });
        document.addEventListener('click', playVideo, { once: true });

        return () => {
            // Clean up event listeners
            document.removeEventListener('scroll', playVideo);
            document.removeEventListener('click', playVideo);
        };
    }, []);
    return (
        <Formik {...formikProps} validate={validate}>
            {({ values, handleChange, handleSubmit, errors, setFieldValue, setFieldError, touched, handleBlur }) => (
                <form onSubmit={handleSubmit} className="shake-quiz shake-new-quiz">
                    <div className='bg-vid-container'>
                        <video
                            aria-label="Video"
                            autoPlay
                            loop
                            playsInline
                            preload="auto"
                            webkit-playsinline="true"
                            x-webkit-airplay="allow"
                            width="100%"
                            height="100%"
                            className="bg-vid"
                            muted
                            onCanPlayThrough={e => e.target.play()}>
                            <source src={imgBg} type="video/mp4" />
                        </video>
                    </div>
                    <Routes>
                        <Route path='/' element={<Question0 {...{ history, handleChange, values, setFieldValue, setShowProgress }} />} />
                        <Route path='/q0' element={<Question0 {...{ history, handleChange, values, setFieldValue, setShowProgress }} />} />
                        <Route path='/q1' element={<Question1 {...{ history, handleChange, values, setFieldValue, setShowProgress, setProgress, setPage }} />} />
                        <Route path='/q2' element={<Question2 {...{ history, handleChange, values, setFieldValue, errors, setShowProgress, setProgress, setPage }} />} />
                        <Route path='/q3' element={<Question3 {...{ history, handleChange, values, setFieldValue, setShowProgress, setProgress, setPage }} />} />
                        <Route path='/q4' element={<Question4 {...{ history, handleChange, values, setFieldValue, setShowProgress, setProgress, setPage }} />} />
                        <Route path='/q5' element={<Question5 {...{ history, handleChange, values, setFieldValue, setShowProgress, setProgress, setPage }} />} />
                        <Route path='/q6' element={<Question6 {...{ history, handleChange, values, setFieldValue, setShowProgress, setProgress, setPage }} />} />
                        <Route path='/q7' element={<Question7 {...{ history, handleChange, values, setFieldValue, errors, setFieldError, heightIncm, setHeightIncm, setShowProgress, setProgress, setPage }} />} />
                        <Route path='/q8' element={<Question8 {...{ history, handleChange, values, setFieldValue, setShowProgress, setProgress, setPage }} />} />
                        <Route path='/q9' element={<Question9 {...{ history, handleChange, values, setFieldValue, setShowProgress, setProgress, setPage }} />} />
                        <Route path='/q10' element={<Question10 {...{ history, handleChange, values, setFieldValue, setShowProgress, setProgress, setPage }} />} />
                        <Route path='/q11' element={<Question11 {...{ history, handleChange, values, setFieldValue, setShowProgress, setProgress, setPage }} />} />
                        <Route path='/q12' element={<Question12 {...{ history, setShowProgress, values, setProgress, setPage }} />} />
                        <Route path='/q13' element={<Question13 {...{ history, handleChange, values, errors, handleBlur, touched, setShowProgress, setProgress, setPage }} />} />
                        <Route path='/q14' element={<Question14 {...{ history, handleChange, values, setFieldValue, errors, handleBlur, touched, setShowProgress, setProgress, setPage, heightIncm, setHeightIncm }} />} />
                        <Route path='/q15' element={<Question15 {...{ history, handleChange, values, setFieldValue, errors, handleBlur, touched, setShowProgress, setProgress, setPage }} />} />
                        <Route path='/q16' element={<Question16 {...{ history, handleChange, values, setFieldValue, errors, handleBlur, touched, setShowProgress, setProgress, setPage, setFieldError }} />} />
                        <Route path='/email' element={<EmailField {...{ history, handleChange, values, errors, handleBlur, touched, setShowProgress, setProgress, setPage, heightIncm, }} />} />
                        <Route path='/transition' element={<Transition {...{ history, values, setProgress, setShowProgress, setPage }} />} />
                        <Route path='/summary1' element={<Summary1 {...{ history, handleChange, values, setShowProgress, setProgress, setPage }} />} />
                        <Route path='/summary2' element={<Summary2 {...{ history, handleChange, values, setShowProgress, setProgress, setPage }} />} />
                        <Route path='/summary3' element={<Summary3 {...{ history, handleChange, values, setShowProgress, setProgress, setPage }} />} />
                        <Route path='/summary4' element={<Summary4 {...{ history, handleChange, values, setShowProgress, setProgress, setPage }} />} />
                        <Route path='/summary5' element={<Summary5 {...{ history, handleChange, values, setShowProgress, setProgress, setPage }} />} />
                        <Route path='/summary6' element={<Summary6 {...{ history, handleChange, values, setShowProgress, setProgress, setPage }} />} />
                        <Route path='/summary7' element={<Summary7 {...{ history, handleChange, values, setShowProgress, setProgress, setPage }} />} />
                        <Route path='/summary8' element={<Summary8 {...{ history, handleChange, values, setShowProgress, setProgress, setPage }} />} />
                        <Route path='/summary9' element={<Summary9 {...{ history, handleChange, values, setShowProgress, setProgress, setPage }} />} />
                        <Route path='/summary10' element={<Summary10 {...{ history, handleChange, values, setShowProgress, setProgress, setPage }} />} />
                        <Route path='/summary11' element={<Summary11 {...{ history, handleChange, values, setShowProgress, setProgress, setPage }} />} />
                    </Routes>
                </form>
            )}
        </Formik>
    );
};
export default React.memo(ShakeQuiz);