import React, { useEffect } from 'react';
import InputMask from "react-input-mask";

export const Question13 = ({ history, values, handleChange, errors, handleBlur, touched, setProgress, setShowProgress, setPage }) => {

    useEffect(() => {
        let percent;
        percent = (13 / 18) * 100;
        percent = percent.toFixed(2);
        setPage(13);
        setProgress(percent);
        setShowProgress(true);
    }, []);

    const clickHandler = () => {
        if (!errors?.age) {
            history('/shake-new/q14');
        }
    }
    const handleFieldChange = (e) => {
        if(e.target.value.length > 3) {
            return;
        }
        handleChange(e);
    };

    return (
        <>
            <section className="text-white form-main-content main">
                <div className="body-content">
                    <div className="heading mb-20 title-font h-wrap"><span className="h-before">How old are you?</span></div>
                    <div className='form-content mx-auto'>
                        <input type="number" placeholder="Age" name="age" className='form-field' value={values.age} onChange={handleChange} onBlur={handleBlur} />
                        {errors?.age && touched?.age ?
                            <div className='error mt-10'>{errors?.age}</div> : <></>
                        }
                        <button type='button' className='button bg-secondary text-white w-100 mx-auto d-flex fw-bold align-center mt-30' onClick={clickHandler}>
                            <span>
                                Continue
                            </span>
                        </button>
                    </div>
                </div>
            </section>
        </>
    );
}
