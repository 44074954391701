import React from "react";

const SubscribedPage = () => (
  <div>
    <div className="cms container terms-page text-primary pb-50 body-padding">
      <div className="fs-28 fw-bold mb-50 mt-50"> Added To Priority Waitlist</div>
      <div>
        <h2 className="fs-18 fw-bold">We will reach out as soon as stock is available again.</h2>
        <p>Thank you for your interest in Biome Secret meal replacement shakes. All flavors are currently on backorder, and we will reach out to you as soon as we are back with more fresh stock.</p>        
        <p>We appreciate your patience and understanding.</p>
        <p className="mt-20"><i>- The Biome Secret Team</i></p>
      </div>
    </div>
  </div>
);
export default React.memo(SubscribedPage);