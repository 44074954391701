import React, { useEffect, useState } from 'react';
import summary5 from '../../assets/img/updated/shake-new/summary5img.webp';
import summary5a from '../../assets/img/updated/shake-new/summary5.png';
import summary5b from '../../assets/img/updated/shake-new/q5.webp';




export const Summary5 = ({ history, values, handleChange, setProgress, setShowProgress, setPage }) => {

    useEffect(() => {
        let percent;
        percent = (18 / 18) * 100;
        percent = percent.toFixed(2);
        setPage(18);
        setProgress(percent);
        setShowProgress(true);
    }, []);

    const clickHandler = () => {
        setTimeout(() => {
            history('/shake-new/summary6');
        }, 300);
    };


    const [progress, setProgress1] = useState(0); // Progress percentage
    const [step, setStep] = useState(0); // To track which step is active
  
    const steps = [
      "Analysing your quiz answers..",
      "Calculating your quiz results..",
      "Creating your summary.."
    ];
    useEffect(() => {
        const interval = setInterval(() => {
         if (progress < 100) {
            setProgress1((prevProgress) => Math.min(prevProgress + 33, 100)); // Increment progress by 33%, but don't exceed 100%
            setStep((prevStep) => Math.min(prevStep + 1, steps.length)); // Ensure that steps don't go beyond the length of the array
        }
        }, 2000); // Change every 2 seconds
    
        return () => clearInterval(interval); // Clean up the interval
      }, [progress]);
    return (
        <section className="text-primary main summary-page-content">
            <div className='shake-summary-page'>
                <div className='shake-summary-page-steps text-white'>
                    <span className='active'></span>
                    <span className='active'></span>
                    <span className='active'></span>
                    <span className='active'></span>
                    <span className='active'></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div className='shake-summary-page-content'>
                    <div class="heading">
                        {/*<div class="text-primary title-font mx-auto">
                            Get your essential nutrition without any strict diets. <span className='theme-color'>Replace 1 meal per day with 1 Biome Secret shake.</span>
                        </div>*/}
                        <div class="text-primary title-font mx-auto">
                           Replace one meal a day with <span className='theme-color'>Biome Secret</span> Shake for all essential nutrients, no strict diets needed.
                        </div>
                    </div>
                    <div className='weight-loss-journey-new'>
                        <div className='summary-5bg zoomed'>
                            <img src={summary5b} className='img-fluid mt-20 zoomed' />
                            {/* <img src={summary5a} className='img-fluid summary5img' /> */}
                        </div>
                        {/*<ul>
                            <li>
                                <p className='theme-color fw-bold m-0'>1 – 80% of Daily Protein</p>
                                <div>98% of users burned fat, not muscles.</div>
                            </li>
                            <li>
                                <p className='theme-color fw-bold m-0'>2 – 27 Vitamins & Minerals</p>
                                <div>
                                    91% of your daily vitamins in one serving, providing essential nutrients that support energy, immune function, and overall well-being.
                                </div>
                            </li>
                            <li>
                                <p className='theme-color fw-bold m-0'>3 – Digestive Enzymes</p>
                                <div>92% of users reported bloating relief and improved digestion.</div>
                            </li>
                            <li>
                                <p className='theme-color fw-bold m-0'>4 – Great Taste with Fiber-rich Ingredients.</p>
                                <div>All the vitamins & nutrition you need for weight loss, without tasting like (gr)ass.</div>
                            </li>
                        </ul>*/}
                        <div className="mt-30">
                            <button
                                type='button'
                                className="button bg-secondary text-white w-100 mx-auto text-center mb-16 w-100"
                                title="Continue"
                                // disabled={values.targetWeightInKg ? errors.targetWeightKg : errors.targetWeightLb}
                                onClick={clickHandler}
                            > Continue
                            </button>
                            <div className='f-italic fs-10 color2'>
                                * Results may vary due personal features
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </section>
    )   
};