import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import reviews1 from '../../assets/img/updated/shake/q18-reviews-1-mobile.webp';
import reviews2 from '../../assets/img/updated/shake/q18-reviews-2-mobile.webp';
import reviews3 from '../../assets/img/updated/shake/q18-reviews-3-mobile.webp';
import reviews1Mobile from '../../assets/img/updated/shake/q18-reviews-1-mobile.webp';
import reviews2Mobile from '../../assets/img/updated/shake/q18-reviews-2-mobile.webp';
import reviews3Mobile from '../../assets/img/updated/shake/q18-reviews-3-mobile.webp';




export const ShakeSlick = () => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay:true, 
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    };
    return (
      <Slider {...settings}>
          <div>
            <div class="reivews-item">
               <picture>
                    <source
                    media="(min-width: 768px)"
                    srcSet={reviews1}
                    sizes="768px"
                    />
                    <source
                    srcSet={reviews1Mobile}
                    sizes="1280px"
                    />
                    <img src={reviews1Mobile} alt="{}" height='' width='440' className='img-fluid w-100' loading='lazy'/>
                </picture>     
            </div>
          </div>
          <div>
            <div class="reivews-item">
            <picture>
                    <source
                    media="(min-width: 768px)"
                    srcSet={reviews2}
                    sizes="768px"
                    />
                    <source
                    srcSet={reviews2Mobile}
                    sizes="1280px"
                    />
                    <img src={reviews2Mobile} alt="{}" height='' width='440' className='img-fluid w-100' loading='lazy'/>
                </picture>        
            </div>
          </div>
          <div>
            <div class="reivews-item">
              <picture>
                    <source
                    media="(min-width: 768px)"
                    srcSet={reviews3}
                    sizes="768px"
                    />
                    <source
                    srcSet={reviews3Mobile}
                    sizes="1280px"
                    />
                    <img src={reviews3Mobile} alt="{}" height='' width='440' className='img-fluid w-100' loading='lazy'/>
                </picture>          
            </div>
          </div>         
      </Slider>
    )
}