import React, { useEffect } from 'react';
import WeightInPoundField from '../../common/components/WeightInPoundField';
import { poundsToKilograms, kgToLb } from '../../common/utils/formulas';
import q16Bg from '../../assets/img/updated/bg/q16-bg.webp';

export const Question16 = ({ history, values, handleChange, setFieldValue, errors, handleBlur, touched, setProgress, setShowProgress, setPage, setFieldError }) => {

    useEffect(() => {
        let percent;
        percent = (16 / 18) * 100;
        percent = percent.toFixed(2);
        setPage(16);
        setProgress(percent);
        setShowProgress(true);
    }, []);

    const poundToKg = () => {
        let targetKg;
        targetKg = poundsToKilograms(parseInt(values.targetWeightLb));
        targetKg = Math.round(targetKg);
        // setFieldValue('targetWeight',  String(targetKg));
        setFieldValue('targetWeightInKg', true);
        setFieldValue('targetWeightKg', String(targetKg));
    };

    const kilogramToPound = () => {
        let targetLb;
        targetLb = kgToLb(parseInt(values?.targetWeightKg));
        targetLb = Math.round(targetLb);
        // setFieldValue('targetWeight', String(targetLb));
        setFieldValue('targetWeightInKg', false);
        setFieldValue('targetWeightLb', String(targetLb));
    };

    const clickHandler = () => {
        const weight = values.targetWeightInKg ? values.targetWeightKg : values.targetWeightLb;
        setFieldValue('targetWeight', weight);
        setTimeout(() => {
            history('/app/q17');
        }, 300);
    };

    const handleFieldChange = (e, length) => {
        if(e.target.value.length > length) {
            return;
        }
        handleChange(e);
    };

    return (
        <>
            <img src={q16Bg} className='img-fluid bg-img' alt='' />
            <section className="text-primary form-main-content main">
                <div className="body-content">
                    <div className="heading text-primary mb-20 title-font mx-auto"> What’s your goal weight?</div>
                    <div className='form-content mx-auto'>
                        <div className="switch-unit-btn mb-30">
                            <button
                                type='button'
                                className={!values.targetWeightInKg && "active"}
                                disabled={!values.targetWeightInKg && true}
                                onClick={kilogramToPound}
                            > Imperial </button>
                            <button
                                type='button'
                                className={values.targetWeightInKg && "active"}
                                disabled={values.targetWeightInKg && true}
                                onClick={poundToKg}
                            > Metric </button>
                        </div>
                        {values.targetWeightInKg ?
                            <WeightInPoundField
                                {...{
                                    field: 'targetWeightKg',
                                    handleFieldChange,
                                    handleBlur,
                                    values,
                                    errors,
                                    touched,
                                    placeholder: 'Kilograms'
                                }}
                            /> :
                            <WeightInPoundField
                                {...{
                                    field: 'targetWeightLb',
                                    handleFieldChange,
                                    handleBlur,
                                    values,
                                    errors,
                                    touched,
                                    placeholder: 'Pounds'
                                }}
                            />
                        }
                        <div className="mt-30">
                            <button
                                type='button'
                                className="button bg-theme text-white w-100 mx-auto  fw-bold text-center mb-16 w-100"
                                title="Continue"
                                disabled={values.targetWeightInKg ? errors.targetWeightKg : errors.targetWeightLb}
                                onClick={clickHandler}
                            > Continue
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
