import React from "react";
import { Footer } from '../common/components/Footer';

const Cookies = () => (
    <div>
    <div class="cms container terms-page text-primary pb-50 body-padding">
    <div class="fs-28 fw-bold mb-50 mt-50"> Cookie Policy</div>
    <div>
      <h2 class="fs-18 fw-bold">1. Introduction</h2>
      <p>A. Cookies are small text files that are stored on a user's device when they visit a website. They are widely used to enhance website functionality and provide a personalized browsing experience.</p>
      <p>B. The purpose of this Cookie Policy is to inform users about the use of cookies on our website and to explain how we collect, use, and manage cookies to enhance user experience and improve our services.</p>
      <p>C. This Cookie Policy should be read in conjunction with our <a href="../privacy">Privacy Policy</a>, which provides detailed information about how we handle personal data.</p>
    </div>
    <div>
      <h2 class="fs-18 fw-bold pt-20">2. Types of Cookies Used</h2>
      <p>A. Google Analytics Cookies</p>
      <p>Google Analytics cookies are used to collect information about how visitors use our website. These cookies help us analyze website traffic, track user interactions, and gather statistical data to improve our website performance and user experience. The information collected by Google Analytics cookies is aggregated and anonymous.</p>
      <p>B. Facebook Cookies</p>
      <p>Facebook cookies are used for social media integration and marketing purposes. These cookies enable us to track the effectiveness of our advertising campaigns, optimize ad targeting, and provide personalized content based on user interests and interactions with our website.</p>
      <p>C. TikTok Cookies</p>
      <p>TikTok cookies are utilized for social media integration and marketing activities. These cookies enable us to measure the performance of our TikTok advertising campaigns, deliver targeted content, and analyze user engagement with our TikTok-related features.</p>
    </div>
    <div>
      <h2 class="fs-18 fw-bold pt-20">3. Purpose of Using Cookies</h2>
      <p>A. Functionality of Cookies in Making the Website Work</p>
      <p>Certain cookies are essential for the proper functioning of our website. These cookies enable core functionalities such as enabling user authentication, maintaining user sessions, and providing secure browsing experiences. Without these cookies, the website may not function properly.</p>
      <p>B. Tracking and Analytics Purposes</p>
      <p>We use cookies for tracking and analytics purposes to understand how visitors interact with our website. These cookies help us gather information such as the number of visitors, the pages visited, and the time spent on the website. This data helps us improve our website performance, optimize content, and enhance user experience.</p>
    </div>
    <div>
      <h2 class="fs-18 fw-bold pt-20">4. Cookie Duration</h2>
      <p>The cookies used on our website have different expiration timeframes, which are determined by each tracking technology. The default expiration timeframes for the cookies are as follows:</p>
      <ul>
        <li>Google Analytics Cookies: Typically, Google Analytics cookies expire after a certain period, which may vary based on the specific cookie. Please refer to Google's documentation or their website for more information.</li>
        <li>Facebook Cookies: The expiration timeframes for Facebook cookies may vary depending on the purpose of the cookie. For more details, we recommend reviewing Facebook's cookie policy.</li>
        <li>TikTok Cookies: TikTok cookies may have their own expiration timeframes depending on the specific cookie. For specific details, we suggest referring to TikTok's cookie policy or documentation.</li>
      </ul>
      <p>Please note that these timeframes are subject to change and may be modified by the respective third-party providers.</p>
    </div>
    <div>
      <h2 class="fs-18 fw-bold pt-20">5. Third-Party Cookies</h2>
      <p>Our website utilizes third-party cookies from Google Analytics, Facebook, and TikTok. These cookies are set by external providers and are subject to the respective third-party privacy policies.</p>
      <p>To learn more about how these third-party providers handle your personal information and the cookies they set, we recommend reviewing their privacy policies:</p>
      <ul>
        <li>Google Analytics: Please refer to Google's privacy policy for more information on how they handle data collected through their cookies.</li>
        <li>Facebook: For details on Facebook's data practices and cookie usage, we encourage you to review Facebook's privacy policy.</li>
        <li>TikTok: To understand how TikTok handles your data and their cookie policies, we recommend referring to TikTok's privacy policy.</li>
      </ul>
    </div>
    <div>
      <h2 class="fs-18 fw-bold pt-20">6. Cookie Management and Consent</h2>
      <p>At present, our website does not provide an opt-out option for cookies. By continuing to browse and use our website, you consent to the use of cookies as described in this Cookie Policy.</p>
      <p>You have the ability to manage and control cookies through the settings of your web browser. Most web browsers allow you to block or delete cookies and provide options for cookie management. Please refer to your browser's settings or help documentation for instructions on how to manage cookies.</p>
      <p>Keep in mind that restricting or disabling cookies may impact the functionality and features of our website, and certain parts may not function as intended.</p>
    </div>
    <div>
      <h2 class="fs-18 fw-bold pt-20">7. Changes to the Cookie Policy</h2>
      <p>We reserve the right to make changes and updates to this Cookie Policy from time to time. Any modifications will be effective immediately upon posting the revised policy on our website. It is recommended that you review this policy periodically to stay informed about how we use cookies.</p>
      <p>In the event of significant changes to this Cookie Policy, we will provide a prominent notice on our website or send a direct notification to you, if reasonably possible, to ensure that you are aware of the updated policy.</p>
    </div>
    <div>
      <h2 class="fs-18 fw-bold pt-20">8. Contact Information</h2>
      <p>If you have any questions, concerns, or inquiries regarding this Cookie Policy, please feel free to contact us using the following contact information:</p>
      <p>Email: support@biomesecret.com</p>
      <p>We will do our best to address any questions or concerns you may have in a timely manner.</p>
    </div>
    </div>
    <Footer />
</div>

);
export default React.memo(Cookies);