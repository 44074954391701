import React from 'react';


export const Footer = ({Name}) => {
  return (
    <>
     
        
        <footer>        
        <div class="w-100 bg1"><div class="fqa-bar text-center color2 mx-a"> Any questions? We're here to help. </div></div>
        <div class="text-center"> <a href="mailto:support@biomesecret.com" class="text-primary d-flex-center fs-14">
        <div class="email-icon me-10"></div>support@biomesecret.com</a> </div>
        <p class="footer-container pt-20 color2">*Actor portrayal. Along with a balanced diet and regular physical activity, a common guideline for healthy weight loss is 1 to 2 pounds per week, which roughly translates to about 0.5% to 1% of your total body weight if you weigh 200 pounds or more. This rate of weight loss is considered safe and achievable for most people. This advice is widely recommended by health organizations, including the Centers for Disease Control and Prevention (CDC). According to the CDC: "Evidence shows that people who lose weight gradually and steadily (about 1 to 2 pounds per week) are more successful at keeping weight off.</p>             
        </footer>
    </>
  )
}
