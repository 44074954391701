import React, {useEffect, useLayoutEffect, useState} from "react";
import { Footer } from '../../common/components/Footer';
import sleep from '../../assets/img/why-is-sleep-important.jpg';
import weightloss from '../../assets/img/m-weightloss-2-rc.jpg';
import right from '../../assets/img/right-up.png';

const Success = ({}) => {
  const [showPopup, setShowPopup] = useState(true);

  useEffect(() => {
    setShowPopup(true);
    return () => {
      setShowPopup(false);
    };
  }, []);

  // function closeModal() {
  //   document.querySelector('.overlay').style.display = 'none';
  // }
  
  // function handleClickOutsideModal(event) {
  //   const overlay = document.querySelector('.overlay');
  //   if (event.target === overlay) {
  //     closeModal();
  //   }
  // }
  
  // function addClickListeners() {
  //   const overlay = document.querySelector('.overlay');
  //   const closeButton = document.querySelector('.close-button');
  //   overlay.addEventListener('click', handleClickOutsideModal);
  //   closeButton.addEventListener('click', closeModal);
  // }
  
  // window.addEventListener('load', function() {
  //   const overlay = document.querySelector('.overlay');
  //   overlay.style.display = 'flex';
  //   addClickListeners();
  // });
  return (
    <div>
      <div class="cms container terms-page text-primary pb-50">
        <section class="choose-body-fat-section text-primary custom-spacing success-page">
          <div class="body-content" >
            <h1 class="fs-20 fw-bold  text-center">Lifetime premium pack per 
              <span style={{textDecoration: "line-through"}}>29.97 USD</span> <br/>
              9.99 USD for lifetime
            </h1>
            <div class="plan-list">
              <ul>
                <li class="bg2 d-flex align-center" style={{flex: 'auto', padding: '0'}}> 
                  <img src={sleep} style={{width: "120px",  marginRight: "15px"}} alt=""/>
                  <div>
                    <div class="fs-14 fw-bold mb-5"> Priority support assistance </div>
                    <div class="fs-12">Get Personal support at <br/>support@magicPill.com</div>
                  </div>
                </li>
                <li class="bg2 d-flex align-center" style={{flex: 'auto', padding: '0'}}> 
                  <img src={sleep} style={{width: "120px",  marginRight: "15px"}} alt=""/>
                  <div>
                    <div class="fs-14 fw-bold mb-5"> Add Sex Drive Tips </div>
                    <div class="fs-12">Increase sexual attraction</div>
                  </div>
                </li>
                <li class="bg2 d-flex align-center" style={{flex: 'auto', padding: '0'}}> 
                  <img src={sleep} style={{width: "120px",  marginRight: "15px"}} alt=""/>
                  <div>
                    <div class="fs-14 fw-bold mb-5"> Better Sleep Guide </div>
                    <div class="fs-12">Optimize your sleep schedule</div>
                  </div>
                </li>  
              </ul>
            </div>
            <p class="text-center fs-12 mb-20"> One time special offer to improve your program </p>
          </div>
        </section>
        {/* Modal Start */}
        {showPopup &&
          <div class="overlay">
            <div class="popup">
              <div class="success-img" >
                <img src={weightloss} style={{width: "100%"}} alt=""/>
                <div class="fs-20 text-primary fw-bold" > Get  Meal Plan <br/> for only <span class="theme-color">0.99USD</span> 
                  <small class="theme-color fs-16">per week</small> 
                </div>
              </div>
              <div class="" style={{padding: "1rem"}}>
                <div class="pa-bullet-list-1">
                  <ul style={{padding:"15px"}}>
                    <li>Created by a professional <b>nutritionist</b> to gain muscle mass faster</li>
                    <li><b>100+ easy recipes</b> based on your preferences</li>
                    <li><b>Daily calorie</b>need: 2056 kcal</li>
                    <li>Boosts your <b>metabolism</b></li>
                  </ul>
                </div>
                <button onClick={() => setShowPopup(false)} class="theme-btn d-flex  text-white text-docoration-none mb-20" style={{width: "100%"}} title="Continue">
                  Add to my program <img src={right} class="ms-auto" alt="" />
                </button>
                <span   onclick="closeModal()" class="d-flex  text-primary text-docoration-none mb-20 fw-bold">
                  No, thank you
                </span>     
              </div>
            </div>
          </div>
        }
      </div>
      <Footer />
    </div>
  );
}
export default React.memo(Success);